import React, { useState } from "react";
import { Button } from "react-bootstrap";
import UntrackedZoomMeeting from "./UntrackedZoomMeeting";
import useLocalization from "../../hooks/useLocalization";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
} from "react-bootstrap-icons";
import UnassignedAppointments from "./UnassignedAppointments";
import useContacts from "../../hooks/useContacts";

const tabs = [
  { label: "Untracked Zoom Meetings", key: "untracked_zoom_meets" },
  { label: "Unassigned App Meetings", key: "unassigned_app_meets" },
];

const Tab = ({ tabs, selectedTab, onTabChange }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      {tabs?.map(({ label, key }) => (
        <Button
          key={key}
          size="sm"
          variant={key === selectedTab ? "dark" : "outline-dark"}
          className="d-flex align-items-center rounded-0"
          onClick={() => onTabChange(key)}
        >
          <h6 className="mb-0 smallFont">{label}</h6>
        </Button>
      ))}
    </div>
  );
};

const UntrackedAppointments = ({
  closeImportMode,
  importUntrackedMeetSearchQuery,
  importUntrackedMeetCheckedParticipants,
  onImportUntrackedZoomMeet,
  onAssignContactToAppointment,
  onNavigateToDate,
  onDeleteApointment,
}) => {
  const { contacts, loadingContacts, setContactQuery } = useContacts();
  const [activeTab, setActiveTab] = useState("untracked_zoom_meets");
  return (
    <div
      className="border border-top-0 overflow-auto"
      style={{ height: `calc(100vh - 35px)` }}
    >
      <div
        className="d-flex align-items-center gap-1 border-bottom"
        style={{ padding: "7px 6px" }}
      >
        {/* {isRTL ? (
          <ArrowRightCircleFill
            size={20}
            className="hover"
            onClick={closeImportMode}
          />
        ) : (
          <ArrowLeftCircleFill
            size={20}
            className="hover"
            onClick={closeImportMode}
          />
        )}{" "} */}
        <Tab selectedTab={activeTab} tabs={tabs} onTabChange={setActiveTab} />
      </div>

      <div className="p-2">
        {activeTab === "untracked_zoom_meets" && (
          <>
            <h6 className="fw-bold smallFont">
              You are viewing untracked zoom meetings.
            </h6>{" "}
            <UntrackedZoomMeeting
              contacts={contacts}
              loadingContacts={loadingContacts}
              setContactQuery={setContactQuery}
              searchQuery={importUntrackedMeetSearchQuery}
              checkedParticipants={importUntrackedMeetCheckedParticipants}
              onImportUntrackedZoomMeet={onImportUntrackedZoomMeet}
            />
          </>
        )}{" "}
        {activeTab === "unassigned_app_meets" && (
          <>
            <UnassignedAppointments
              contacts={contacts}
              loadingContacts={loadingContacts}
              setContactQuery={setContactQuery}
              searchQuery={importUntrackedMeetSearchQuery}
              checkedParticipants={importUntrackedMeetCheckedParticipants}
              onAssignContactToAppointment={onAssignContactToAppointment}
              onNavigateToDate={onNavigateToDate}
              onDeleteApointment={onDeleteApointment}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UntrackedAppointments;
