import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateItemsInArray } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import { inboundSourceDefaultsStatusService } from "../../../services/inboundSourceDefaultsStatusService";
import EditDefaultStatusForm from "../../forms/EditDefaultStatusForm";

const InboundSourceDefaultStatus = ({
  campaigns,
  defaultStatus,
  setDefaultStatus,
}) => {
  const { translate } = useLocalization();
  const [editDefaultStatus, setEditDefaultStatus] = useState(null);

  const onDefaultStatusSubmit = async (formData) => {
    setEditDefaultStatus({
      ...formData,
    });
    try {
      const { response, error } =
        await inboundSourceDefaultsStatusService.updateInboundSourceDefaultsStatus(
          formData
        );
      if (error) {
        return toast.error(error);
      }
      setDefaultStatus((prevAppointment) => {
        const updatedAppointments = updateItemsInArray(prevAppointment, [
          response,
        ]);
        return updatedAppointments;
      });
      toast.success("Updated successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setEditDefaultStatus(null);
    }
  };

  return (
    <Card className="m-2 my-4 pb-4">
      <Card.Header>{translate("inbound_source_default_status")}</Card.Header>
      <Card.Body className="py-1">
        <Row>
          {defaultStatus?.map((inboundSource) => {
            return (
              <Col
                key={inboundSource?.key}
                className="p-1"
                sm={12}
                md={6}
                lg={6}
                style={{ height: "100%" }}
              >
                <div className="border">
                  <EditDefaultStatusForm
                    initialValues={inboundSource}
                    showProgress={editDefaultStatus?._id === inboundSource?._id}
                    onSubmit={onDefaultStatusSubmit}
                    campaigns={campaigns}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InboundSourceDefaultStatus;
