import moment from "moment";
import React, { useState } from "react";
import useLocalization from "../../hooks/useLocalization";
import { CustomEvent } from "../common/CustomEvent";
import DraggableModal from "../common/DraggableModal";
import GoogleEventBadge from "../common/GoogleEventBadge";
import AddEditAppointmentForm from "../forms/AddEditAppointmentForm";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { ArrowLeft, ChevronLeft } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";

const AllAppointments = ({ appointments = [], onAppointmentClick }) => (
  <div className="overflow-auto px-2" style={{ height: 200 }}>
    {appointments?.map((appointment, index) => {
      return (
        <div
          onClick={() => onAppointmentClick(appointment)}
          className="mb-1 hover"
          key={index}
        >
          <CustomEvent markPastEvents event={appointment} />
        </div>
      );
    })}
  </div>
);

const AddEditAppointmentModal = ({
  show,
  onHide,
  onBackToShowMore,
  showMoreMode,
  allAppointments,
  onAppointmentClick,
  formValues = {},
  showProgress,
  defaultInviteesEmail,
  appointmentMetaDataByType,
  editMode,
  disabled,
  onSubmit,
  onAppointmentDelete,
  contact,
  contacts,
  specificContactMode,
  setAppointmentMetadata,
  popupOffset = null,
  showBackToShowMoreButton,
}) => {
  const { translate, isRTL } = useLocalization();
  const { screenWidth } = useScreenWidth();
  return (
    <DraggableModal
      hasOverlay
      show={show}
      onHide={onHide}
      title={
        showMoreMode
          ? moment(allAppointments[allAppointments.length - 1].start).format(
              "MMMM Do YYYY"
            )
          : translate(editMode ? "edit_appointment" : "new_appointment")
      }
      width={{
        sm: screenWidth - 100,
        md: showMoreMode ? 250 : 480,
        lg: showMoreMode ? 250 : 480,
      }}
      height={showMoreMode ? 250 : 400}
      popupOffset={popupOffset}
      HeaderExtraComponent={
        !showMoreMode && editMode && !formValues?.isAppEvent
          ? GoogleEventBadge
          : null
      }
      LeftHeaderExtraComponent={
        showBackToShowMoreButton
          ? () => (
              <Button
                size="sm"
                variant="outline-dark"
                className="d-flex align-items-center p-1 border-0"
                onClick={onBackToShowMore}
              >
                <ChevronLeft />
              </Button>
            )
          : null
      }
    >
      {showMoreMode ? (
        <AllAppointments
          appointments={allAppointments}
          onAppointmentClick={onAppointmentClick}
        />
      ) : (
        <AddEditAppointmentForm
          contact={contact}
          contacts={contacts}
          formValues={formValues}
          disabled={disabled}
          defaultInviteesEmail={defaultInviteesEmail}
          appointmentMetaDataByType={appointmentMetaDataByType}
          editMode={editMode}
          onSubmit={onSubmit}
          onAppointmentDelete={onAppointmentDelete}
          showProgress={showProgress}
          specificContactMode={specificContactMode}
          setAppointmentMetadata={setAppointmentMetadata}
        />
      )}
    </DraggableModal>
  );
};

export default AddEditAppointmentModal;
