import { snakeCase } from "lodash";
import AsyncCreatableSelect from "../components/common/AsyncCreatableSelect";
import PersonSelectOption from "../components/common/PersonSelectOption";
import PersonSelectValue from "../components/common/PersonSelectValue";
import { GROUPPED_USER_BASED_ON_ROLE, sortUsersByRole } from "./global";
import { isAdminOrManager } from "./session";

export const APP_URL = process.env.PUBLIC_URL;

export const BRAND_NAME = "Ethica CRM";
export const RUJUM_APP_URL = process.env.REACT_APP_MAIN_APP_URL;
export const CRM_APP_URL = process.env.PUBLIC_URL;

export const CONTACT_QS = "contact";
export const DEAL_QS = "deal";
export const CALENDAR_QS = "calendar";
export const REFERER_QS = "referrer";
export const USER_RECORD_QS = "user-record";
export const ENDPOINTS = {
  //Users
  LOGIN: "/users/login",
  REGISTER: "/users/register",
  LOGOUT: "/users/logout",
  FORGOT_PASSWORD: "/users/send-password-reset-link",
  RESET_PASSWORD: "/users/reset-password",
  CHANGE_PASSWORD: "/users/change-password",
  GENERATE_REGISTRATION_OTP: "/users/generate-registration-otp",
  GET_ME: "/users/me",
  GET_USER_BY_ID: (id) => `/users/${id}`,
  GET_USER_BY_USER_ID: (id) => `/users/user/${id}`,
  UPDATE_USER_DETAILS: "/users/update-user-details",
  CREATE_USER: "/users",
  DELETE_USER: "/users",
  UPDATE_MULTIPLE_USERS: "/users",
  USERS_LIST: "/users/search",

  // google drive
  GET_FILES_OR_FOLDERS: ({ folder, query }) =>
    `/google-drive/list${folder && `?folder=${folder}`}${
      query && `?q=${query}`
    }`,
  CREATE_NEW_FOLDER: "/google-drive/create-folder",
  DELETE_FILE_OR_FOLDER: (id) => `/google-drive/delete/${id}`,
  UPLOAD_FILES: "/google-drive/upload",
  // user record
  GET_USER_TIME_LOG: "/users/time-logs/search",
  CREATE_USER_TIME_LOG: "/users/time-logs",
  USER_TIME_LOG_WITH_ID: (id) => `/users/time-logs/${id}`,
  GET_USER_RECORDS: (userId) => `/users/${userId}/record`,
  UPDATE_USER_RECORDS_COMMENTS: (userId) => `/users/${userId}/record/comments`,
  DOWNLOAD_USER_LOG_CSV: "/users/time-logs/csv",
  // user stats
  GET_USER_STATS: "/users/stats",

  //user files
  SEARCH_USER_FILES: "/users/files/search",
  CREATE_USER_FILES: "/users/files",
  USER_FILES_ID: (id) => `/users/files/${id}`,

  //raferral
  SEARCH_REFERRALS: "/referee/search",
  CREATE_REFERRALS: "/referee",
  REFARRAL_WITH_ID: (id) => `/referee/${id}`,

  //files
  FILE: "/files",
  FILE_WITH_ID: (id) => `/files/${id}`,
  FILE_STREAM_WITH_ID: (id) => `/files/stream/${id}`,

  // contacts
  CREATE_CONTACTS: "/contacts",
  SEARCH_CONTACTS: "/contacts/search",
  DELETE_SELECTED_CONTACTS: "/contacts",
  UPDATE_MANY_CONTACTS: "/contacts",
  CONTACT_LOGS: (id) => `/contacts/history/${id}`,
  CONTACT_WITH_ID: (id) => `/contacts/${id}`,
  CONTACT_COMMENT_WITH_ID: (id) => `/contacts/comments/${id}`,
  CONTACTS_DOWNLOAD_CSV: "/contacts/csv",
  MERGE_CONTACT: "/contacts/merge-multiple",
  CONTACT_FIND_DUPLICATE: "/contacts/duplicates",
  SEARCH_DUPLICATE_CONTACTS: "/contacts/search/duplicates",
  CONTACTS_AUTO_MERGE: "/contacts/auto-merge",

  //referrals
  REFER_CONTACT: "/referee/submit-referral",

  // dashboard
  GET_STATS: "/contacts/get-dashboard-data",

  // contracts
  CREATE_PRODUCTS: "/products",
  SEARCH_PRODUCTS: "/products/search",
  PRODUCT_WITH_ID: (id) => `/products/${id}`,
  CREATE_PRODUCTION_STAGE: "/product-production-stages",
  STEP_WITH_ID: (id) => `/product-production-stages/${id}`,
  UPDATE_MANY_PRODUCTS: "/products",

  // units
  CREATE_UNITS: "/unit",
  SEARCH_UNITS: "/unit/search",
  UNIT_WITH_ID: (id) => `/unit/${id}`,
  // events
  CREATE_EVENTS: "/events",
  SEARCH_EVENTS: "/events/search",
  EVENT_WITH_ID: (id) => `/events/${id}`,

  // automations
  CREATE_AUTOMATION: "/automations",
  SEARCH_AUTOMATIONS: "/automations/search",
  AUTOMATION_WITH_ID: (id) => `/automations/${id}`,

  // contracts
  CREATE_CONTRACTS: "/contracts",
  SEARCH_CONTRACTS: "/contracts/search",
  CONTRACT_WITH_ID: (contractId) => `/contracts/${contractId}`,
  SEND_CONTRACT_VIA_EMAIL: "/contracts/send-via-email",
  DELETE_SELECTED_CONTRACTS: "/contracts",
  CONTRACT_COMMENT_WITH_ID: (id) => `/contracts/comments/${id}`,

  //App Choices
  APP_CHOICES_BASE: "/app-choices",
  APP_CHOICES_LIST: "/app-choices/list",
  APP_CHOICES_WITH_ID: (id) => `/app-choices/${id}`,

  // logs
  SEARCH_LOGS: "/logs/search",
  LOG_ACTION: (action) => `/logs/${action}`,

  //misc
  GET_FB_CAMPAIGNS: "/misc/facebook-campaigns",
  GET_FB_ADS: "/misc/facebook-ads",
  GET_ACTIVE_TRAIL_LISTS: "/misc/active-trail-email-lists",
  DIAL_PHONE_NUMBER: "/misc/dial-phone-number",
  GET_CALL_RECORDING_STREAM: "/misc/get-call-recording-stream",

  //Appointment
  CREATE_AVAILABILITY: "/appointments",
  CREATE_HOLIDAY: "/appointments/holiday",
  SEARCH_AVAILABILITY: "/appointments/search",
  AVAILABILITY_WITH_ID: (id) => `/appointments/${id}`,
  BOOK_AVAILABILITY: "/appointments",
  GET_UNTRACKED_ZOOM_MEETINGS: "/appointments/get-untracked-zoom-meetings",
  GET_ZOOM_MEET_RECORDINGS: "/appointments/get-zoom-recordings",
  DELETE_UNTRACKED_ZOOM_MEET: (id) =>
    `/appointments/delete-untracked-zoom-meetings/${id}`,
  //Tags
  CREATE_TAG: "/tags",
  SEARCH_TAGS: "/tags/list",
  RENAME_TAG: "/tags/rename",
  DELETE_TAG: "/tags/delete",

  //filters
  CREATE_FILTER: "/filters",
  UPDATE_MANY: "/filters",
  SEARCH_FILTER: "/filters/search",
  UPDATE_FILTER: (id) => `/filters/${id}`,
  DELETE_FILTER: (id) => `/filters/${id}`,

  //app-configuration
  SEARCH_APPOINTMENT_DEFAULT: "/appointment-defaults/search",
  UPDATE_APPOINTMENT_DEFAULT: (id) => `/appointment-defaults/${id}`,
  SEARCH_APPOINTMENTS_DEFAULT_PAST: "/appointments-default/search/past",
  // inbound source default status
  GET_INBOUND_SOURCE_DEFAULT_STATUS: "/inbound-source-defaults/search",
  UPDATE_INBOUND_SOURCE_DEFAULT_STATUS: (id) =>
    `/inbound-source-defaults/${id}`,
};

export const USER_RECORD_FILE_TYPES = [
  "Pension",
  "Salary Slip",
  "Salary Invoice",
];

export const USER_STATUS = ["Enable", "Disable"];

export const DEFAULT_PAGE_SIZE = 50;

export const TIME_LOG_TYPE_WORK_LOG = "work-log";
export const TIME_LOG_TYPE_VACATION = "vacation";
export const TIME_LOG_TYPE_SICK_LEAVE = "sick-leave";

export const TIME_LOGS_TYPES_OPTIONS = [
  { label: "Work log", value: TIME_LOG_TYPE_WORK_LOG },
  { label: "Vacation", value: TIME_LOG_TYPE_VACATION },
  { label: "Sick leave", value: TIME_LOG_TYPE_SICK_LEAVE },
];

export const STATUS = [
  {
    label: "Webinar",
    key: "webinar",
    color: "primary",
    options: ["New - Unattended", "New - Didn't Answer"],
  },
  {
    label: "Sales",
    key: "sales",
    color: "info",
    options: [
      "Expressed interest",
      "Phone call was set",
      "Finished first phone call",
      "Zoom meeting was set",
      "Office meeting was set",
      "Was in meeting",
      "Contract sent",
    ],
  },
  {
    label: "Irrelevant",
    key: "irrelevant",
    color: "danger",
    options: [
      "Doesn’t meet our requirements",
      "Didn’t answer 5 times",
      "Asked to not be contacted",
      "Not interested now",
    ],
  },
  {
    label: "Clients",
    key: "clients",
    color: "success",
    options: [
      "Contract signed",
      "Client paid",
      "Client in process",
      "Client finished",
      "Awaiting upsale",
      "On hold",
    ],
  },
];

function getStatusColors(colorName) {
  let backgroundColor, textColor, hoverColor;

  switch (colorName) {
    case "primary":
      backgroundColor = "#d89e31";
      textColor = "#ffffff";
      hoverColor = "#c4882d";
      break;
    case "info":
      backgroundColor = "#3260a8";
      textColor = "#ffffff";
      hoverColor = "#2b5699";
      break;
    case "success":
      backgroundColor = "#28a745";
      textColor = "#ffffff";
      hoverColor = "#218838";
      break;
    case "danger":
      backgroundColor = "#e60707";
      textColor = "#ffffff";
      hoverColor = "#c10505";
      break;
    default:
      backgroundColor = "#ffffff";
      textColor = "#000000";
      hoverColor = "#cccccc";
  }

  return { backgroundColor, textColor, hoverColor };
}

export const GROUPPED_STATUS = (translate) => {
  return STATUS.map((group) => {
    let colors = getStatusColors(group?.color);

    return {
      ...group,
      options: group.options.map((option) => ({
        label: translate(snakeCase(option)),
        value: option,
        ...colors,
      })),
    };
  });
};

export const ALL_STATUSES = STATUS.flatMap((s) => s.options);

export const INBOUND_SOURCE = [
  "Manually Added",
  "Facebook Messenger",
  "Facebook Leadform",
  "Facebook Comments",
  "Website",
  "Instagram Messenger",
  "Google organic",
  "Instagram ads",
  "Youtube",
  "Referral",
];

//User Permissions
export const BLOCK = "Block";
export const VIEW_ASSIGNED_EDIT_NONE = "View Assigned | Edit None";
export const VIEW_ASSIGNED_EDIT_ASSIGNED = "View Assigned | Edit Assigned";
export const VIEW_ALL_EDIT_NONE = "View All | Edit None";
export const VIEW_ALL_EDIT_ASSIGNED = "View All | Edit Assigned";
export const VIEW_ALL_EDIT_ALL = "View All | Edit All";

export const specialRolesEnum = [
  BLOCK,
  VIEW_ALL_EDIT_NONE,
  VIEW_ALL_EDIT_ASSIGNED,
  VIEW_ALL_EDIT_ALL,
  VIEW_ASSIGNED_EDIT_NONE,
  VIEW_ASSIGNED_EDIT_ASSIGNED,
];

export const MANAGER_ROLE = "Manager";
export const ADMIN_ROLE = "Admin";
export const SALES_ROLE = "Salesperson";
export const FINANCIAL_STRATEGIST_ROLE = "Financial Strategist";
export const REAL_ESTATE_ANALYST_ROLE = "Real Estate Analyst";
export const ALL_ROLES = [
  ADMIN_ROLE,
  MANAGER_ROLE,
  SALES_ROLE,
  FINANCIAL_STRATEGIST_ROLE,
  REAL_ESTATE_ANALYST_ROLE,
];

export const LOGIN_MODE = "login";
export const REGISTER_MODE = "register";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY, HH:mm";
export const DEFAULT_DATE_TIME_FORMAT_NON_MOMENT = "dd/MM/yyyy, HH:mm";
export const DEFAULT_TIME_FORMAT = "HH:mm";
export const APP_OPTION = ["crm", "app"];
export const ACTION_OPTION = ["view", "edit"];
export const RULE_OPTION = ["restrict", "permit"];

export const LEAD_SOURCE_OPTIONS = [
  "direct",
  "website",
  "facebook",
  "webinar",
  "other",
];

export const updateMultipleContactField = ({
  users,
  user,
  tags,
  translate,
}) => {
  return [
    {
      key: "status",
      label: "Status",
      show: isAdminOrManager(user.role),
      options: GROUPPED_STATUS(translate),
      cellSizeMD: 3,
      type: "grouped-dropdown",
      placeholder: "Please select",
    },
    {
      key: "salesperson",
      label: "Sales person",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: [
        { label: "Unassign", value: "unassign" },
        ...sortUsersByRole(users, SALES_ROLE),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
    },
    {
      key: "financialStrategist",
      label: "Financial Strategist",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: [
        { label: "Unassign", value: "unassign" },
        ...sortUsersByRole(users, FINANCIAL_STRATEGIST_ROLE),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
    },
    {
      key: "realEstateAnalyst",
      label: "Real Estate Analyst",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: [
        { label: "Unassign", value: "unassign" },
        ...sortUsersByRole(users, REAL_ESTATE_ANALYST_ROLE),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
    },
    {
      key: "firstName",
      label: "firstName",
      type: "input_text",
      show: isAdminOrManager(user.role),
      cellSizeMD: 3,
      required: true,
    },
    {
      key: "lastName",
      label: "lastName",
      type: "input_text",
      show: isAdminOrManager(user.role),
      cellSizeMD: 3,
      required: true,
    },
    {
      key: "address",
      label: "address",
      type: "input_text",
      show: isAdminOrManager(user.role),
      cellSizeMD: 3,
      required: true,
    },
    {
      key: "tags",
      label: "tags",
      type: "multi-select",
      show: isAdminOrManager(user.role) || user.role === SALES_ROLE,
      options: [
        { label: "Unassign", value: "unassign" },
        ...tags?.map((t) => ({ label: t?.name, value: t?.name })),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
    },
    {
      key: "leadSource",
      label: "Lead Source",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
      cellSizeMD: 3,
      placeholder: "Please select",
      isMultiSelect: false,
    },
  ].filter(({ show }) => show);
};

export const newContactField = ({
  users = [],
  tags = [],
  translate,
  referrals = [],
  searchReferral,
  onCreateNewReferralClick,
  user,
}) => {
  return [
    {
      key: "status",
      label: "Status",
      options: GROUPPED_STATUS(translate),
      cellSizeMD: 2,
      type: "grouped-dropdown",
    },
    {
      key: "firstName",
      label: "First Name",
      cellSizeMD: 2,
    },
    {
      key: "lastName",
      label: "Last Name",
      cellSizeMD: 2,
    },
    {
      key: "spouseFirstName",
      label: "Spouse First Name",
      cellSizeMD: 2,
    },
    {
      key: "spouseLastName",
      label: "Spouse Last Name",
      cellSizeMD: 2,
    },
    { key: "address", label: "Address", cellSizeMD: 2 },
    {
      key: "phoneNumbers",
      label: "Phone Numbers",
      type: "phone",
      cellSizeMD: 4,
      isMultiple: true,
    },
    {
      key: "emails",
      label: "Emails",
      type: "email",
      cellSizeMD: 4,
      isMultiple: true,
    },
    {
      key: "spousePhoneNumbers",
      label: "Spouse phone numbers",
      type: "phone",
      cellSizeMD: 4,
      isMultiple: true,
    },

    {
      key: "tags",
      label: "Tags",
      type: "multi-select",
      options: tags.map((tag) => ({ label: tag.name, value: tag.name })),
      cellSizeMD: 3,
      isMultiSelect: true,
    },
    {
      key: "referredBy",
      label: "Referral",
      cellSizeMD: 3,
      customField: (values, onChange) => {
        return (
          <AsyncCreatableSelect
            CustomSingleValueComponent={PersonSelectValue}
            CustomOptionComponent={PersonSelectOption}
            defaultItems={referrals?.map((r) => ({
              label: r?.name,
              value: r?._id,
              ...r,
            }))}
            selectedItems={values?.referredBy}
            onChange={(v) => {
              onChange({ key: "referredBy" }, v);
            }}
            onCreateOption={onCreateNewReferralClick}
            loadOptions={searchReferral}
            isClearable
          />
        );
      },
    },
    {
      key: "leadSource",
      label: "Lead source",
      options: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
      cellSizeMD: 3,
      type: "multi-select",
      isMultiSelect: false,
      isDisabled: !isAdminOrManager(user.role),
    },
    {
      key: "followupDate",
      label: "Followup Date",
      type: "followUpDate",
      hideTitle: true,
      cellSizeMD: 3,
    },
    {
      key: "salesperson",
      label: "Sales person",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: SALES_ROLE,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 4,
      required: true,
    },
    {
      key: "financialStrategist",
      label: "Financial Strategist",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: FINANCIAL_STRATEGIST_ROLE,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 4,
      required: true,
    },
    {
      key: "realEstateAnalyst",
      label: "Real Estate Analyst",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: REAL_ESTATE_ANALYST_ROLE,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 4,
      required: true,
    },
    {
      key: "comment",
      label: "Comment",
      type: "text-area",
      cellSizeMD: 12,
      as: "textarea",
    },
  ];
};

export const editContactStatusFields = (translate, user) => {
  return [
    {
      key: "status",
      label: "Status",
      options: GROUPPED_STATUS(translate),
      cellSizeMD: 6,
      type: "grouped-dropdown",
    },
    {
      key: "leadSource",
      label: "Lead source",
      options: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
      cellSizeMD: 6,
      type: "multi-select",
      isDisabled: !isAdminOrManager(user.role),
      isMultiSelect: false,
    },
    {
      key: "followupDate",
      label: "Followup Date",
      type: "followUpDate",
      hideTitle: true,
      cellSizeMD: 12,
    },
    {
      key: "surveySent",
      label: "survey Sent",
      hideTitle: true,
      type: "boolean",
      cellSizeMD: 6,
      className: "mt-2",
    },
    {
      key: "surveyCompleted",
      label: "survey completed",
      type: "boolean",
      hideTitle: true,
      cellSizeMD: 6,
      className: "mt-2",
    },
  ];
};

export const editContactAssignedFields = (users) => {
  return [
    {
      key: "salesperson",
      label: "Sales person",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: SALES_ROLE,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 12,
    },
    {
      key: "financialStrategist",
      label: "Financial Strategist",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: FINANCIAL_STRATEGIST_ROLE,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 12,
    },
    {
      key: "realEstateAnalyst",
      label: "Real Estate Analyst",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: REAL_ESTATE_ANALYST_ROLE,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 12,
    },
  ];
};

export const editContactDetailsFields = (tags) => {
  return [
    {
      key: "firstName",
      label: "First Name",
      cellSizeMD: 12,
    },
    {
      key: "lastName",
      label: "Last Name",
      cellSizeMD: 12,
    },
    {
      key: "spouseFirstName",
      label: "Spouse First Name",
      cellSizeMD: 12,
    },
    {
      key: "spouseLastName",
      label: "Spouse Last Name",
      cellSizeMD: 12,
    },
    {
      key: "phoneNumbers",
      label: "Phone Numbers",
      type: "phone",
      cellSizeMD: 12,
      isMultiple: true,
    },
    {
      key: "spousePhoneNumbers",
      label: "Spouse phone numbers",
      type: "phone",
      cellSizeMD: 12,
      isMultiple: true,
    },
    {
      key: "emails",
      label: "Emails",
      type: "email",
      cellSizeMD: 12,
      isMultiple: true,
    },

    {
      key: "tags",
      label: "Tags",
      type: "multi-select",
      options: tags.map((tag) => ({ label: tag.name, value: tag.name })),
      cellSizeMD: 12,
      isMultiSelect: true,
    },
  ];
};

export const commonChartConfig = (isRtl) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      rtl: true,
      labels: {
        padding: 20,
        boxHeight: 6,
        pointStyle: "circle",
        usePointStyle: true,
      },
    },
    title: {
      display: false,
    },
    tooltip: {
      position: "nearest",
      mode: "index",
      titleAlign: isRtl ? "right" : "left",
      bodyAlign: isRtl ? "right" : "left",
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
});

export const SEARCH_BOX_VISIBLE_PATH = ["/contacts", "/deals", "/commissions"];

export const EVENT_STATUS = {
  Available: "primary",
  Booked: "success",
};

export const APPOINTMENT_TYPES = [
  "Office Meeting",
  "Zoom Meeting",
  "Phone Call",
];

export const DEAL_VIEWS = [
  {
    name: "board",
    label: "Board View",
  },
  {
    name: "table",
    label: "Table View",
  },
];

export const dealStatusToColor = {
  Published: "primary",
  Signed: "success",
  Opened: "info",
  Declined: "danger",
  "Contract Not Needed": "secondary",
  "Deal Finished": "success",
};

export const DEAL_STATUSES = [
  "Signed",
  "Deal Finished",
  "Published",
  "Sent",
  "Opened",
  "Declined",
  "Contract Not Needed",
];

export const LOG_ACTION_MULTI_UPDATE = "Multi Update Contacts";
export const LOG_ACTION_MULTI_DELETE = "Multi Delete Contacts";
export const LOG_ACTION_AUTO_MERGE = "Auto Merge Contacts";
export const LOG_ACTION_MULTI_TRASH = "Multi Trash Contacts";
export const LOG_ACTION_MULTI_UNTRASH = "Multi Untrash Contacts";

export const LOG_TYPE = [
  LOG_ACTION_MULTI_UPDATE,
  LOG_ACTION_MULTI_DELETE,
  LOG_ACTION_AUTO_MERGE,
  LOG_ACTION_MULTI_TRASH,
  LOG_ACTION_MULTI_UNTRASH,
];

export const LOG_COLLECTIONS = ["Contact", "Contract", "Appointment"];

export const videoMimeTypes = [
  "video/mp4",
  "video/webm",
  "video/ogg",
  "video/x-msvideo",
  "video/quicktime",
  "video/mpeg",
  "video/x-flv",
  "video/x-matroska",
  "video/3gpp",
];

export const commissionTypes = ["Sales", "Referral"];
