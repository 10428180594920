import { useState, useEffect } from "react";

export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener to update screenWidth on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that effect runs only once on mount

  return {
    screenWidth,
    screenSize:
      screenWidth < 600
        ? "sm"
        : screenWidth >= 600 && screenWidth < 1200
        ? "md"
        : "lg",
  };
}
