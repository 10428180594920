import React, { useEffect, useRef, useState } from "react";
import { availabilityService } from "../../services/availabilityService";
import { mergeAppAndGoogleAppointments } from "../../helpers/global";
import { ABORT_ERROR } from "../../helpers/api";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import ContainerWithScroll from "../common/ContainerWithScroll";
import MeetingCard from "../common/MeetingCard";
import Loader from "../common/Loader";
import { DEFAULT_PAGE_SIZE } from "../../helpers/constants";
import SelectContactModal from "../common/SelectContactModal";
import useContacts from "../../hooks/useContacts";
import { toast } from "react-toastify";
import CircularProgressBar from "../common/circular-progress";
import useLocalization from "../../hooks/useLocalization";
import AlertModal from "../common/AlertModal";
import DeleteAppointmentModal from "./DeleteAppointmentModal";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const UnassignedAppointments = ({
  searchQuery,
  checkedParticipants,
  onAssignContactToAppointment,
  onNavigateToDate,
  onDeleteApointment,
  contacts,
  loadingContacts,
  setContactQuery,
}) => {
  const { translate } = useLocalization();

  const abortControllerRef = useRef(null);
  const [fetchedAppointments, setFetchedAppointments] = useState([]);
  const [fetchingForTheFirstTime, setFetchingAppointmentsForTheFirstTime] =
    useState(true);
  const [fetchingMoreAppointments, setFetchingMoreAppointments] =
    useState(true);
  const [toFetchAppointmentInfo, setToFetchAppointmentInfo] = useState(null);
  const [fetchedAppointmentInfo, setFetchedAppointmentInfo] = useState(null);
  const [selectContactModalMeta, setSelectContactModalMeta] = useState(null);
  const [deleteAppointment, setDeleteAppointment] = useState(null);

  const onAssignContactClick = (appointment) => {
    setSelectContactModalMeta({ appointment });
  };

  const searchAppointments = async () => {
    if (!toFetchAppointmentInfo) return;
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;
    const { pageNumber: pageToFetch } = toFetchAppointmentInfo;
    const loadingMoreAppointments = pageToFetch > 1;

    if (loadingMoreAppointments) {
      setFetchingMoreAppointments(true);
    } else {
      setFetchingAppointmentsForTheFirstTime(true);
    }
    try {
      const reqBody = {
        pageSize: toFetchAppointmentInfo?.pageSize || DEFAULT_PAGE_SIZE,
        pageNumber: pageToFetch,
        returnGoogleEvents: false,
        query: searchQuery,
        filter: {
          participants: checkedParticipants,
          contact: null,
        },
      };
      const { response, error } = await availabilityService.search(
        reqBody,
        controller.signal
      );
      if (error === ABORT_ERROR) return;
      const {
        pageNumber: pgNumber,
        totalPages,
        resultCount,
        results,
      } = response;

      setFetchedAppointmentInfo({
        totalPages,
        pageNumber: pgNumber,
        resultCount,
      });
      setFetchedAppointments((prevApps) => {
        const newArray = loadingMoreAppointments
          ? [...prevApps, ...mergeAppAndGoogleAppointments(results)]
          : mergeAppAndGoogleAppointments(results);

        return newArray;
      });
    } catch (e) {
      console.log(e);
    } finally {
      if (controller === abortControllerRef.current) {
        setFetchingMoreAppointments(false);
        setFetchingAppointmentsForTheFirstTime(false);
        abortControllerRef.current = null;
      }
    }
  };

  const handleAssignContact = async () => {
    try {
      const { appointment, contact } = selectContactModalMeta;

      const updatedAppointment = {
        ...appointment,
        contact: contact?._id,
      };

      setSelectContactModalMeta((prevMeta) => ({
        ...prevMeta,
        showProgress: true,
      }));

      const { response, error } = await availabilityService.update(
        updatedAppointment
      );
      if (error) return toast.error(error);

      onAssignContactToAppointment({
        ...response,
        start: new Date(response?.startDateTime),
        end: new Date(response?.endDateTime),
        isAppEvent: true,
      });

      const remainingUnassignedAppointments = fetchedAppointments?.filter(
        (m) => m._id !== response?._id
      );
      setFetchedAppointments(remainingUnassignedAppointments);
      setSelectContactModalMeta(null);
      toast.success("Contact assigned succesfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAppointment = async (sendNotification) => {
    const toBeDeletedAppointment = { ...deleteAppointment };

    setDeleteAppointment((prevAppointment) => ({
      ...prevAppointment,
      showProgress: true,
    }));

    try {
      const { error } = await availabilityService.delete({
        ...toBeDeletedAppointment,
        sendNotification,
        optionalMessage: toBeDeletedAppointment.customMessage,
      });
      if (error) return toast.error(error);

      setFetchedAppointments((prevAppointments) =>
        prevAppointments.filter((a) => a._id !== toBeDeletedAppointment._id)
      );
      onDeleteApointment(toBeDeletedAppointment);
    } catch (e) {
      toast.error(e);
    } finally {
      setDeleteAppointment(null);
    }
  };

  const loadMoreData = () => {
    if (!fetchedAppointmentInfo) return;

    if (fetchedAppointmentInfo.totalPages < fetchedAppointmentInfo.pageNumber)
      return;

    setToFetchAppointmentInfo({
      ...fetchedAppointmentInfo,
      pageNumber: fetchedAppointmentInfo.pageNumber + 1,
      scrollTop: undefined,
    });
  };

  useEffect(() => {
    searchAppointments({});
  }, [toFetchAppointmentInfo]);

  useDebouncedEffect(
    () => {
      setToFetchAppointmentInfo({ ...initialPageInfo });
    },
    [searchQuery, checkedParticipants],
    300
  );

  return (
    <>
      {" "}
      <h6 className="fw-bold smallFont">
        You are viewing meetings with no any contact assigned to it.{" "}
      </h6>
      <ContainerWithScroll
        loadingMoreData={fetchingMoreAppointments}
        height={`calc(100vh - 120px)`}
        onBottomReached={loadMoreData}
      >
        <div className="overflow-auto h-100">
          {fetchingForTheFirstTime ? (
            <div className="px-2">
              <Loader />
            </div>
          ) : fetchedAppointments?.length === 0 ? (
            <div className="p-2">
              <h6 className="mid">0 results found</h6>
            </div>
          ) : (
            <div>
              {fetchedAppointments?.map((appointment) => (
                <MeetingCard
                  key={appointment?.uuid}
                  appointment={appointment}
                  onNavigateToDate={onNavigateToDate}
                  onAssignContactClick={onAssignContactClick}
                  onDeleteMeetClick={(appointment) => {
                    setDeleteAppointment(appointment);
                  }}
                />
              ))}
              {fetchingMoreAppointments && (
                <div className="d-flex justify-content-center align-items-center">
                  <CircularProgressBar size={16} />
                  <h6 className="mx-2 mb-0 smallFont text-muted fw-bold">
                    {translate("please_wait")}
                  </h6>
                </div>
              )}
            </div>
          )}

          <SelectContactModal
            show={selectContactModalMeta}
            onHide={() => setSelectContactModalMeta(null)}
            contacts={contacts}
            showProgress={
              loadingContacts || selectContactModalMeta?.showProgress
            }
            setContactQuery={setContactQuery}
            onContactSelect={(contact) =>
              setSelectContactModalMeta({
                ...selectContactModalMeta,
                contact,
              })
            }
            selectedContact={selectContactModalMeta?.contact}
            showFooter
            onContactSelectClick={handleAssignContact}
          />

          <DeleteAppointmentModal
            appointmentToDelete={deleteAppointment}
            onHide={() => setDeleteAppointment(null)}
            onDeleteClick={handleDeleteAppointment}
            customMessage={deleteAppointment?.customMessage || ""}
            onCustomMessageChange={(customMessage) =>
              setDeleteAppointment((prevAppointment) => ({
                ...prevAppointment,
                customMessage,
              }))
            }
            showProgress={deleteAppointment?.showProgress}
          />
        </div>
      </ContainerWithScroll>
    </>
  );
};

export default UnassignedAppointments;
