import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";

const FacebookCampaignDropdown = ({
  campaigns = [],
  value,
  onChange,
  isMulti = true,
}) => {
  return (
    <CustomMultiSelect
      items={campaigns}
      selectedItems={value}
      isMulti={isMulti}
      height="30px"
      onChange={onChange}
      placeholder="Select"
    />
  );
};

export default FacebookCampaignDropdown;
