import moment from "moment";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { DEFAULT_DATE_FORMAT } from "../../../helpers/constants";
import { getTextColor } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import MeetLink from "../../common/MeetLink";

export const Participants = ({ participants = [] }) => {
  return (
    <div className="d-flex align-items-center gap-1 flex-wrap">
      {participants?.map((p) => {
        let userTextColor = getTextColor(p?.color) || "white";
        return (
          <div
            key={p?._id}
            style={{
              background: p?.color,
              color: userTextColor,
              padding: "1px 4px",
              fontSize: 11,
            }}
            className="rounded"
          >
            {p?.name}
          </div>
        );
      })}
    </div>
  );
};

const AppointmentDetails = ({ appointment }) => {
  const { translate, isRTL } = useLocalization();

  const fieldToShow = useMemo(() => {
    const isAllDayEvent = appointment?.allDay;
    return [
      {
        key: "zoomLink",
        label: "Zoom Link",
        hide:
          appointment?.appointmentType !== "Zoom Meeting" ||
          !appointment?.zoomMeetLink,
        fieldRender: (app) => <MeetLink appointment={app} />,
      },
      {
        key: "appointmentType",
        label: translate("appointment_type"),
        md: 6,
        lg: 6,
      },
      {
        key: "title",
        label: "Title",
        md: 6,
        lg: 6,
      },
      {
        key: "description",
        label: "Description",
      },
      {
        key: "date",
        label: "Date",
        valueAccessor: (app) =>
          moment(app?.startDateTime).format(DEFAULT_DATE_FORMAT),
        md: isAllDayEvent ? 6 : 4,
        lg: isAllDayEvent ? 6 : 4,
      },

      ...(isAllDayEvent
        ? [
            {
              label: "Time",
              fieldRender: () => (
                <Badge bg="dark" className="">
                  All day event
                </Badge>
              ),
              md: 6,
              lg: 6,
            },
          ]
        : []),

      {
        key: "startTime",
        label: "Start Time",
        valueAccessor: (app) => moment(app?.startDateTime).format("HH:mm"),
        md: 4,
        lg: 4,
        hide: isAllDayEvent,
      },
      {
        key: "endTime",
        label: "End Time",
        valueAccessor: (app) => moment(app?.endDateTime).format("HH:mm"),
        md: 4,
        lg: 4,
        hide: isAllDayEvent,
      },
      {
        key: "invitees",
        label: "Invitees",
        valueAccessor: (app) =>
          app?.invitees?.length > 0 ? app?.invitees?.join(", ") : "No invitees",
      },
      {
        key: "participants",
        label: "Participants",
        fieldRender: (app) => <Participants participants={app?.participants} />,
      },
    ].filter((f) => !f?.hide);
  }, [appointment]);
  return (
    <div>
      <Row className="m-0 p-2 overflow-auto" style={{ height: 350 }}>
        {fieldToShow?.map(
          ({ label, key, xs, md, lg, valueAccessor, fieldRender }) => (
            <Col
              key={label}
              xs={xs || 12}
              md={md || 12}
              lg={lg || 12}
              className="m-0 pb-1 px-1"
            >
              <h6 className="mid mb-1">{label}</h6>
              {fieldRender ? (
                fieldRender(appointment)
              ) : (
                <p className="border smallFont bg-primary-light rounded px-1 py-1 mb-0">
                  {valueAccessor
                    ? valueAccessor(appointment)
                    : appointment[key] || "Nothing to show"}
                </p>
              )}
            </Col>
          )
        )}
      </Row>
    </div>
  );
};

export default AppointmentDetails;
