import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import { CustomEvent } from "../../common/CustomEvent";
import DraggableModal from "../../common/DraggableModal";
import AppointmentDetails from "./AppointmentDetails";

const ShowMoreAppointmentHeader = ({
  handleMouseDown,
  onHide,
  onCloseAppointmentDetail,
  detailMode,
  title,
}) => {
  return (
    <div
      className="d-flex justify-content-between bg-gray align-items-center p-2 border-bottom mb-2"
      onMouseDown={handleMouseDown}
      style={{ cursor: "grab", height: 40 }}
    >
      {detailMode ? (
        <Button
          size="sm"
          className="px-1 py-0"
          variant="outline-dark"
          onClick={onCloseAppointmentDetail}
        >
          {" "}
          <ArrowLeft />
          <span className="mx-1 smallFont">Back</span>
        </Button>
      ) : (
        <span className="mid mx-1 fw-bold">{title}</span>
      )}

      <div className="d-flex align-items-center">
        <XLg onClick={onHide} className="hover" />
      </div>
    </div>
  );
};

const AppointmentList = ({ appointments = [], onAppointmentClick }) => (
  <div className="overflow-auto p-2" style={{ height: 350 }}>
    {appointments?.map((appointment, index) => {
      return (
        <div
          onClick={() => onAppointmentClick && onAppointmentClick(appointment)}
          className="mb-2 mx-1 hover"
          key={index}
        >
          <CustomEvent event={appointment} showFullDate />
        </div>
      );
    })}
  </div>
);

const ShowAppointmentModal = ({
  show,
  onHide,
  deal,
  showDealAppointmentMeta,
  onCloseAppointmentDetail,
  onAppointmentClick,
}) => {
  const { translate, isRTL } = useLocalization();

  const appointmentToShowDetail = useMemo(
    () => showDealAppointmentMeta?.activeAppointment,
    [showDealAppointmentMeta]
  );

  const appointments = useMemo(
    () => showDealAppointmentMeta?.appointments || [],
    [showDealAppointmentMeta]
  );

  return (
    <DraggableModal
      show={show}
      onHide={onHide}
      width={{
        sm: "90%",
        md: 450,
        lg: 450,
      }}
      height={400}
      renderHeader={(props) => (
        <ShowMoreAppointmentHeader
          {...props}
          onCloseAppointmentDetail={onCloseAppointmentDetail}
          detailMode={Boolean(appointmentToShowDetail)}
          title={translate("viewing_appointment_of", {
            name: `${deal?.contact?.firstName || ""} ${
              deal?.contact?.lastName || ""
            }`,
          })}
        />
      )}
    >
      {!appointmentToShowDetail ? (
        <AppointmentList
          appointments={appointments}
          onAppointmentClick={onAppointmentClick}
        />
      ) : (
        <AppointmentDetails appointment={appointmentToShowDetail} />
      )}
    </DraggableModal>
  );
};

export default ShowAppointmentModal;
