import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";

// Yup validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please provide your email address"),
});

const ForgotPasswordForm = ({ showProgress, errorMessage, onSubmit }) => {
  const { translate } = useLocalization();
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <Form noValidate className="p-2">
          <FormGroup className="mb-2">
            <FormLabel className="mid mb-1">{translate("email")} <span className="text-danger">*</span> </FormLabel>
            <Field name={"email"} type={"email"} as={FormControl} size="sm" />
            <ErrorMessage
              name={"email"}
              component="div"
              className="text-danger mid"
            />
          </FormGroup>

          {showProgress && (
            <HorizontalProgress text={`${translate("please_wait")}...`} />
          )}
          {errorMessage && (
            <Alert className="p-2 mt-3 mid" variant="danger">
              {errorMessage}
            </Alert>
          )}

          <Button
            className="mt-2"
            variant="primary"
            onClick={submitForm}
            size="sm"
            disabled={showProgress}
          >
            {translate("get_reset_email")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
