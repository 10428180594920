import { Input } from "antd";
import { snakeCase } from "lodash";
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { updateMultipleContactField } from "../../../helpers/constants";
import useAppChoices from "../../../hooks/useAppChoices";
import useAuth from "../../../hooks/useAuth";
import useLocalization from "../../../hooks/useLocalization";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import CircularProgressBar from "../../common/circular-progress";

let defaultValues = {
  status: undefined,
  salesperson: undefined,
  financialStrategist: undefined,
  realEstateAnalyst: undefined,
};

const UpdateMultipleContactCard = ({ onSaveFieldValue, updatingField }) => {
  const users = useAppChoices("users");
  const tags = useAppChoices("tags");
  const { translate } = useLocalization();
  const { user } = useAuth();
  const [contactUpdates, setContactUpdates] = useState(defaultValues);

  const updateMultipleConactField = useMemo(
    () => updateMultipleContactField({ users, user, tags, translate }),
    [users, user, tags, translate]
  );

  return (
    <div>
      <div className="mid m-0 p-2">
        {updateMultipleConactField?.map(
          ({
            label,
            valueModifierForServer,
            key,
            options,
            placeholder,
            isMultiSelect = true,
            type,
          }) => {
            const [disabled, setDisabled] = useState(true);
            return (
              <div className="px-1 d-flex flex-column mb-3">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <span className="text-dark smallFont">
                    {translate(snakeCase(label)) || label}
                  </span>
                  <Button
                    className="px-1 py-0 text-white"
                    variant="success"
                    size="sm"
                    onClick={() => {
                      setDisabled(true);
                      onSaveFieldValue({
                        [key]: valueModifierForServer
                          ? valueModifierForServer(contactUpdates?.[key])
                          : contactUpdates?.[key],
                      });
                    }}
                    disabled={disabled}
                  >
                    {updatingField === key ? (
                      <span className="mx-1 px-1 text-white">
                        <CircularProgressBar size={10} />
                        <span className="mx-1 tiny">
                          {translate("please_wait")}
                        </span>
                      </span>
                    ) : (
                      <span className="text-white tiny">
                        {translate("save")}
                      </span>
                    )}
                  </Button>
                </div>{" "}
                {type === "grouped-dropdown" && (
                  <CustomMultiSelect
                    isClearable
                    items={options}
                    selectedItems={contactUpdates?.[key]}
                    onChange={(option) => {
                      if (disabled) {
                        setDisabled(false);
                      }
                      setContactUpdates({
                        ...contactUpdates,
                        [key]: option,
                      });
                    }}
                    isMulti={false}
                    isGroupped
                    placeholder={placeholder || "Select"}
                    closeMenuOnSelect
                    fieldColors={options
                      .flatMap((o) => o?.options)
                      ?.find(
                        (option) => option?.value === contactUpdates?.[key]
                      )}
                  />
                )}
                {type === "multi-select" && (
                  <CustomMultiSelect
                    isClearable
                    items={options}
                    isMulti={isMultiSelect}
                    selectedItems={contactUpdates?.[key]}
                    onChange={(option) => {
                      if (disabled) {
                        setDisabled(false);
                      }

                      setContactUpdates({
                        ...contactUpdates,
                        [key]: option?.includes("unassign")
                          ? ["unassign"]
                          : option,
                      });
                    }}
                  />
                )}
                {type === "input_text" && (
                  <Input
                    size="sm"
                    style={{
                      fontSize: "12px",
                    }}
                    value={contactUpdates?.[key]}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (disabled) {
                        setDisabled(false);
                      }
                      setContactUpdates({
                        ...contactUpdates,
                        [key]: value,
                      });
                    }}
                  ></Input>
                )}
              </div>
            );
          }
        )}
      </div>

      {/* <div className="d-flex justify-content-end align-items-center bg-gray border-top border-secondary-dark px-1 py-2">
        <div className="d-flex">
          <Button
            variant="danger"
            className="text-white px-2 py-0 mx-1"
            size="sm"
            disabled={editingMultipleContact}
            onClick={onHideEditMultipleContact}
          >
            <span className="">
              {translate("cancel")} <XLg size={12} />
            </span>
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default UpdateMultipleContactCard;
