import _ from "lodash";
import moment from "moment";
import {
  CameraReelsFill,
  FileEarmarkImageFill,
  FileEarmarkMusicFill,
  FileEarmarkPdfFill,
  FileEarmarkSpreadsheetFill,
  FileEarmarkTextFill,
  FileEarmarkWordFill,
} from "react-bootstrap-icons";
import {
  ADMIN_ROLE,
  ALL_ROLES,
  APP_URL,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  FINANCIAL_STRATEGIST_ROLE,
  MANAGER_ROLE,
  REAL_ESTATE_ANALYST_ROLE,
  SALES_ROLE,
  STATUS,
  videoMimeTypes,
} from "./constants";

export const dateMinusToday = (date) => {
  if (!date) return "N/A";

  const closingDate = new Date(date);
  closingDate.setHours(0);
  closingDate.setMinutes(0);
  closingDate.setSeconds(0);
  closingDate.setMilliseconds(0);

  const diffTime = closingDate.getTime() - new Date().getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const dateMinusDate = (date1, date2) => {
  if (!date1 || !date2) return "N/A";

  const date1Date = new Date(date1);
  date1Date.setHours(0);
  date1Date.setMinutes(0);
  date1Date.setSeconds(0);
  date1Date.setMilliseconds(0);

  const date2Date = new Date(date2);
  date2Date.setHours(0);
  date2Date.setMinutes(0);
  date2Date.setSeconds(0);
  date2Date.setMilliseconds(0);

  const diffTime = date1Date.getTime() - date2Date.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const fillJobVariablesInText = (text, job) => {
  let newText = text;
  const availableHeaders = Object.keys(job || {});
  availableHeaders.forEach((h) => {
    newText = newText.replace(`{{${h}}}`, job[h]);
  });
  return newText;
};

export const createMapsLinkFromJobs = (
  jobs,
  zoom,
  center,
  radius,
  showCircle
) => {
  // Ensure APP_URL is defined and valid
  if (!APP_URL || !APP_URL.startsWith("http")) {
    throw new Error("Invalid APP_URL");
  }

  const jobsWithLocation = jobs.filter((j) => {
    return (
      j["latitude"] &&
      j["longitude"] &&
      !isNaN(Number(j["latitude"])) &&
      !isNaN(Number(j["longitude"]))
    );
  });

  const centerXY =
    center && center.latitude && center.longitude
      ? `${center.latitude}:${center.longitude}`
      : "";

  const url = `${APP_URL}/jobs/map?zoom=${zoom}&center=${centerXY}&radius=${radius}&circle=${
    showCircle ? "true" : ""
  }&m=${encodeURIComponent(
    jobsWithLocation
      .map(
        (j) =>
          `${j["latitude"]}:${j["longitude"]}:${j["atmId"]}-${
            j["propertyAddress"] || ""
          } ${j["propertyStreet"] || ""}${
            j["acreage"] ? ` (${j["acreage"]})` : ""
          }`
      )
      .join(",")
  )}`;

  return url;
};

export const getColumnColor = (job = {}, field) => {
  return "";
};

export const getRowColor = (job) => {
  return "";
};

export const groupEntityTableValues = (
  entityData = [],
  groupBy,
  groupValues
) => {
  let finalEntityData = [];

  const otherData = entityData.filter(
    (ed) => !ed?.hasOwnProperty(groupBy) || !groupValues.includes(ed[groupBy])
  );

  groupValues.forEach((groupValue) => {
    const filteredEntityData = entityData.filter(
      (ed) => ed[groupBy] === groupValue
    );
    filteredEntityData.forEach((d) => {
      delete d?.newGroupStarts;
    });
    if (filteredEntityData.length) {
      filteredEntityData[0] = {
        ...filteredEntityData[0],
        newGroupStarts: true,
      };

      finalEntityData.push(...filteredEntityData);
    }
  });

  if (otherData?.length) {
    otherData.forEach((ot) => {
      delete ot.newGroupStarts;
    });
    otherData[0].newGroupStarts = true;
    otherData.forEach((od) => {
      od[groupBy] = "Others";
    });
  }
  finalEntityData = [...finalEntityData, ...otherData];

  return finalEntityData;
};

export const updateItemsInArray = (originalData, updatedData, key = "_id") => {
  const returnData = [...originalData];

  if (!Array.isArray(updatedData)) {
    updatedData = [updatedData];
  }

  updatedData.forEach((newData) => {
    const existingIndex = returnData.findIndex((d) => d[key] === newData[key]);
    if (existingIndex !== -1) {
      returnData[existingIndex] = newData;
    } else {
      returnData.unshift(newData);
    }
  });

  return returnData;
};

export function removeParamsFromUrl(url = "") {
  // Check if the URL contains any parameters
  if (url?.indexOf("?") !== -1) {
    // Split the URL into base and parameters
    var parts = url.split("?");

    // Get the base URL without parameters
    var baseUrl = parts[0];

    // Return the base URL
    return baseUrl;
  } else {
    // If the URL doesn't have parameters, return the original URL
    return url;
  }
}

export const getAppChoicesFromKey = (appChoices = [], appChoiceKey) => {
  let result = [];
  if (appChoices) {
    if (ALL_ROLES.includes(appChoiceKey)) {
      result =
        appChoices?.find((appChoice) => appChoice?.key === "users")?.values ||
        [];
      return [
        { name: "Unassigned" },
        ...result
          ?.filter((u) => u?.role === appChoiceKey)
          .map((u) => ({
            ...u,
            name: `${u.crew ? `${u.crew}: ` : ``}${u.name} `,
          })),
      ];
    }

    if (appChoiceKey === "users") {
      return (
        appChoices?.find((appChoice) => appChoice?.key === appChoiceKey)
          ?.values || []
      );
    }

    result =
      appChoices
        ?.find((appChoice) => appChoice?.key === appChoiceKey)
        ?.values.map((value) =>
          typeof value === "object" ? value?.county || value?.name : value
        ) || [];

    return result;
  }
};

export const requestFilterInstance = (filters) => {
  return filters.reduce((allFilters, currFilter) => {
    return {
      ...allFilters,
      [currFilter?.key]: currFilter?.children?.length
        ? currFilter.children.flatMap((c) =>
            c?.key === null ? [null, ""] : [c?.key]
          )
        : undefined,
    };
  }, {});
};

export const getDateWithTimezone = (dateString) => {
  try {
    return new Date(dateString.split("-").join("/")).toISOString();
  } catch (e) {}
};

export const getFormattedDate = (date) => {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "-" + mm + "-" + dd;
};

export const getFormattedDateTime = (date) => {
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  var hh = date.getHours();
  var min = date.getMinutes();
  if (hh < 10) {
    hh = "0" + hh;
  }
  if (min < 10) {
    min = "0" + min;
  }
  return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
};

export const getUserFirstName = (name) => {
  if (name) return name.split(" ")[0] || "";

  return "";
};

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const getValidUrl = (link) => {
  if (!link.startsWith("https://") && !link.startsWith("http://")) {
    link = "https://" + link;
  }

  return link;
};

export const escapeRegex = (string) => {
  return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const constructSearchQuery = (field, query) => {
  const safeQuery = escapeRegex(query);
  const regex = { $regex: safeQuery, $options: "i" }; // case-insensitive prefix search

  return regex;
};

export const constructDateRangeQuery = (field, value) => {
  let { start, end } = value;

  // Helper function to adjust the date to the beginning or end of the day
  const adjustDate = (date, startOfDay) => {
    const newDate = new Date(date);
    if (startOfDay) {
      newDate.setHours(0, 0, 0, 0); // Set to start of the day
    } else {
      newDate.setHours(23, 59, 59, 999); // Set to end of the day
    }
    return newDate;
  };

  // If neither start nor end is present, return null
  if (!start && !end) return null;

  // Adjust start and end dates
  start = start ? adjustDate(start, true) : adjustDate(end, true);
  end = end ? adjustDate(end, false) : adjustDate(start, false);

  return {
    [field]: {
      $gte: start,
      $lte: end,
    },
  };
};

export const isValidProsperoUrl = (url) => {
  // This pattern ensures the URL starts with the specified prefix and ends with a 24-character hex string
  const pattern = /^https:\/\/app\.goprospero\.com\/t\/([a-f0-9]{24})$/;
  return pattern.test(url);
};

export const truncateAndAddEllipsis = (text, maxCharacters = 10) => {
  if (text.length <= maxCharacters) {
    return text;
  }

  const truncatedContent = text.substring(0, maxCharacters);
  const lastSpaceIndex = truncatedContent.lastIndexOf(" ");

  // Check if the truncation occurs within a word
  if (lastSpaceIndex !== -1) {
    return truncatedContent.substring(0, lastSpaceIndex) + "...";
  } else {
    return truncatedContent + "...";
  }
};

export const filterEventsRespectiveToDate = (events, date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const filteredEvents = events.filter((event) => {
    const eventDate = event.start;
    return (
      eventDate.getFullYear() === year &&
      eventDate.getMonth() === month &&
      eventDate.getDate() === day
    );
  });

  filteredEvents.sort((a, b) => a.start - b.start);

  return filteredEvents.length > 0 ? filteredEvents[0] : null;
};

// Convert a date to the scrollToTime format (time only)
export function convertToScrollToTime(dateString) {
  // Parse the date string into a Date object
  let date = dateString;

  // Create a new Date object for scrollToTime
  let scrollToTime = new Date();

  // Set the hours and minutes of scrollToTime to match the parsed date
  scrollToTime.setHours(date.getHours());
  scrollToTime.setMinutes(date.getHours());

  // Reset seconds and milliseconds to ensure scrollToTime points to the exact beginning of the minute
  scrollToTime.setSeconds(0);
  scrollToTime.setMilliseconds(0);

  return scrollToTime;
}

export const formatDate = (date) => {
  return moment(date).format(DEFAULT_DATE_FORMAT);
};

export const formatDateTime = (date) => {
  return moment(date).format(DEFAULT_DATE_TIME_FORMAT);
};

export const formatTime = (date) => {
  return moment(date).format("h:mm a");
};

export const formatTime24Hour = (date) => {
  return moment(date).format("HH:mm");
};

export const getHoursAndMinutes = (start, end) => {
  // Determine if start and end are in hh:mm format or date format
  let startHours, startMinutes, endHours, endMinutes;

  if (
    typeof start === "string" &&
    start.includes(":") &&
    !start.includes("T")
  ) {
    [startHours, startMinutes] = start.split(":").map(Number);
  } else {
    const startDate = new Date(start);
    startHours = startDate.getHours();
    startMinutes = startDate.getMinutes();
  }

  if (typeof end === "string" && end.includes(":") && !end.includes("T")) {
    [endHours, endMinutes] = end.split(":").map(Number);
  } else {
    const endDate = new Date(end);
    endHours = endDate.getHours();
    endMinutes = endDate.getMinutes();
  }
  return { startHours, startMinutes, endHours, endMinutes };
};
export const getDateObjectFromInputString = (dateString) => {
  try {
    return new Date(dateString.split("-").join("/"));
  } catch (e) {
    return null;
  }
};

export function timeDifferenceInMinutes(startTime, endTime) {
  // startTIme and endTime are of 24 hr time format
  // Convert time strings to Date objects
  const startDate = new Date(`2000-01-01T${startTime}:00`);
  const endDate = new Date(`2000-01-01T${endTime}:00`);

  // Check if end time is earlier than start time
  if (endDate < startDate) {
    return 0;
  }

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = endDate - startDate;

  // Convert the difference to minutes
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

  return differenceInMinutes;
}
export function hasHttpOrHttps(link = "") {
  return link.startsWith("http://") || link.startsWith("https://");
}
export function adjustTimeWithDuration(
  initialStartTime = "08:00",
  initialEndTime = "08:30",
  duration
) {
  // Convert time strings to Date objects
  const startDate = new Date(`2000-01-01T${initialStartTime}:00`);
  const endDate = new Date(`2000-01-01T${initialEndTime}:00`);

  // Calculate the duration in milliseconds
  const durationInMilliseconds = duration * 60 * 1000;

  // Calculate the new end time
  let newEndTime = new Date(startDate.getTime() + durationInMilliseconds);

  // If the new end time is beyond 24:00, set it to 24:00
  if (newEndTime.getDate() > 1) {
    newEndTime = new Date(`2000-01-01T23:59:00`);
  }

  // Format the times for output
  const formattedStartTime = startDate.toTimeString().slice(0, 5);
  const formattedEndTime = newEndTime.toTimeString().slice(0, 5);
  return { newStartTime: formattedStartTime, newEndTime: formattedEndTime };
}
export const createAppointments = (obj) => {
  const { start, end, date, untillDate, description, holiday, ...remaining } =
    obj;
  const appointments = [];

  const { startHours, startMinutes, endHours, endMinutes } = getHoursAndMinutes(
    start,
    end
  );

  const selectedDate = getDateObjectFromInputString(date);

  const startDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    startHours,
    startMinutes
  );
  const endDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    endHours,
    endMinutes
  );
  appointments.push({
    ...remaining,
    startDateTime: startDate.toISOString(),
    endDateTime: endDate.toISOString(),
    description,
    holiday: holiday || false, // Add holiday flag
  });

  return appointments;
};

export const checkIfUserCanEdit = (user) => {
  const { edit } = user?.specialRoles;
  return edit === "Assigned" || edit === "All";
};

export const getURLForSpecificUser = (baseUrl, user) => {
  return `${baseUrl}?as=${user?._id}`;
};
export const getInitialAssigneValue = (user, role) => {
  if (user.role === role) {
    return [user?._id];
  }
  return [];
};

export const NEW_CONTACT_DEFAULT_FORM_VALUES = (user) => ({
  firstName: "",
  lastName: "",
  phoneNumbers: [],
  emails: [],
  spouseFirstName: "",
  spouseLastName: "",
  address: "",
  status: "New - Unattended",
  comment: "",
  referredBy: null,
  salesperson: getInitialAssigneValue(user, SALES_ROLE),
  financialStrategist: getInitialAssigneValue(user, FINANCIAL_STRATEGIST_ROLE),
  realEstateAnalyst: getInitialAssigneValue(user, REAL_ESTATE_ANALYST_ROLE),
  tags: [],
  leadSource: "direct",
});

export const downloadFileFromString = ({ data, fileName }) => {
  var downloadLink = document.createElement("a");
  var blob = new Blob(["\ufeff", data]);
  var url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const contactStatusColor = (contact) =>
  STATUS.find((s) => s?.options?.includes(contact.status))?.color ?? "primary";

export function getTextColor(bgColor = "#007f7f") {
  // Extract the red, green, and blue components from the hex color
  const r = parseInt(bgColor.slice(1, 3), 16);
  const g = parseInt(bgColor.slice(3, 5), 16);
  const b = parseInt(bgColor.slice(5, 7), 16);

  // Calculate the luminance of the color using the W3C formula
  const luminance =
    0.2126 * (r / 255) ** 2.2 +
    0.7152 * (g / 255) ** 2.2 +
    0.0722 * (b / 255) ** 2.2;

  // Set a threshold for deciding when to use black or white text
  const threshold = 0.3; // Adjust this threshold value as needed

  // If the background color's luminance is greater than the threshold, use black text, otherwise use white
  return luminance > threshold ? "#000000" : "#FFFFFF";
}

export function formatDuration(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  if (minutes > 0) {
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  } else {
    return remainingSeconds.toString().padStart(2, "0");
  }
}

export const formatCurrency = (
  value,
  returnZeroWhenBlank = true,
  round = true
) => {
  if (!value) return returnZeroWhenBlank ? 0 : "";

  if (value == "-") return "-";

  if (typeof value === "string") {
    if (value.endsWith(".") && !round) {
      return value;
    }

    value = Number(value.replace(/[^.\d.-]/g, ""));
  }

  if (isNaN(value) || !isFinite(value)) return "";

  return round ? Math.round(value).toLocaleString() : value.toLocaleString();
};

export function calculateModalPosition({
  x,
  y,
  yOffSet,
  xOffSet,
  modalWidth,
  modalHeight,
}) {
  const { innerWidth, innerHeight } = window;

  x = x + xOffSet + 20;
  y = y - 100;

  let newX = x;
  let newY = y;

  if (newX + modalWidth > innerWidth) {
    newX = newX - modalWidth - xOffSet - 40;
  } else if (newX - modalWidth < 0) {
    newX = newX;
  } else if (newX < 0) {
    newX = 20;
  }

  if (newY + modalHeight > innerHeight) {
    newY = innerHeight - modalHeight - 20;
  } else if (newY < 0) {
    newY = 20;
  }

  return { x: newX, y: newY };
}

// Function to calculate and format the difference between two dates
export const getDateDifferenceInHumanReadableFormat = (date) => {
  // Parse the dates using Moment.js
  const start = moment(date);
  const end = moment();

  // Calculate the difference in milliseconds
  const duration = moment.duration(end.diff(start));

  // Determine the human-readable format based on the duration
  if (duration.asDays() < 1) {
    return "less than a day";
  } else if (duration.asDays() < 2) {
    return "1 day";
  } else {
    return `${Math.floor(duration.asDays())} days`;
  }
};

export const isPreviousMonth = (day, calendarDate) => {
  const currentMonth = calendarDate.getMonth();
  return day.getMonth() < currentMonth;
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export const isWeekend = (date) => {
  const dayOfWeek = date.getDay(); // Sunday is 0, Saturday is 6
  return dayOfWeek === 5 || dayOfWeek === 6;
};

export const isSameDate = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export function getMonthRange(date) {
  // Ensure the input is a Date object
  const inputDate = new Date(date);

  // Start of the month: set to the 1st day of the month at 12:00 AM
  const startOfMonth = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    1,
    0,
    0,
    0,
    0
  );

  // End of the month: set to the last day of the month at 11:59:59 PM
  const endOfMonth = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth() + 1,
    0,
    23,
    59,
    59,
    999
  );

  return {
    startDateTime: startOfMonth,
    endDateTime: endOfMonth,
  };
}

export const mergeAppAndGoogleAppointments = (appointments) => {
  return [
    ...(appointments?.appEvents?.map((appointment) => ({
      ...appointment,
      date: new Date(appointment?.startDateTime),
      start: new Date(appointment?.startDateTime),
      end: new Date(appointment?.endDateTime),
      isAppEvent: true,
    })) || []),
    ...(appointments?.googleCalendarEvents?.map((appointment) => ({
      ...appointment,
      date: new Date(appointment?.startDateTime),
      start: new Date(appointment?.startDateTime),
      end: new Date(appointment?.endDateTime),
      _id: appointment?.id,
      isAppEvent: false,
    })) || []),
  ].sort((a, b) => b.start - a.start);
};

export const getDateXMonthBefore = (inputDate, months = 1) => {
  const date = new Date(inputDate);
  const currentMonth = date.getMonth();
  date.setMonth(currentMonth - months);

  // Handle edge cases where setMonth causes the date to "overflow" into a subsequent month
  // For instance, if input date is March 31, subtracting 1 month should give February 28/29
  if (date.getMonth() !== (((currentMonth - months) % 12) + 12) % 12) {
    date.setDate(0); // Setting date to 0 rolls it back to the last day of the previous month
  }

  return date;
};

export function calculateDateTimeRange(startDT, endDT, rangeType, direction) {
  const startDateTime = new Date(startDT);
  const endDateTime = new Date(endDT);

  const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day

  let newStartDateTime, newEndDateTime;

  switch (rangeType) {
    case "Day":
      if (direction === "Previous") {
        newStartDateTime = new Date(startDateTime.getTime() - oneDay);
        newEndDateTime = new Date(endDateTime.getTime() - oneDay);
      } else if (direction === "Next") {
        newStartDateTime = new Date(startDateTime.getTime() + oneDay);
        newEndDateTime = new Date(endDateTime.getTime() + oneDay);
      }
      break;

    case "Week":
      if (direction === "Previous") {
        newStartDateTime = new Date(startDateTime.getTime() - 7 * oneDay);
        newEndDateTime = new Date(endDateTime.getTime() - 7 * oneDay);
      } else if (direction === "Next") {
        newStartDateTime = new Date(startDateTime.getTime() + 7 * oneDay);
        newEndDateTime = new Date(endDateTime.getTime() + 7 * oneDay);
      }
      break;

    case "Month":
      let newYear = startDateTime.getFullYear();
      let newMonth = startDateTime.getMonth();

      if (direction === "Previous") {
        newMonth--;
        if (newMonth === 0) {
          newMonth = 12;
          newYear--;
        }
      } else if (direction === "Next") {
        newMonth++;
        if (newMonth === 13) {
          newMonth = 1;
          newYear++;
        }
      }

      // Create the first and last dates of the new month
      newStartDateTime = new Date(newYear, newMonth, 1);
      newEndDateTime = new Date(newYear, newMonth + 1, 0); // The last day of the month
      break;

    case "Quarter":
      if (direction === "Previous") {
        newStartDateTime = new Date(
          startDateTime.setMonth(startDateTime.getMonth() - 3)
        );
        newEndDateTime = new Date(
          endDateTime.setMonth(endDateTime.getMonth() - 3)
        );
      } else if (direction === "Next") {
        newStartDateTime = new Date(
          startDateTime.setMonth(startDateTime.getMonth() + 3)
        );
        newEndDateTime = new Date(
          endDateTime.setMonth(endDateTime.getMonth() + 3)
        );
      }
      break;

    case "Year":
      if (direction === "Previous") {
        newStartDateTime = new Date(
          startDateTime.setFullYear(startDateTime.getFullYear() - 1)
        );
        newEndDateTime = new Date(
          endDateTime.setFullYear(endDateTime.getFullYear() - 1)
        );
      } else if (direction === "Next") {
        newStartDateTime = new Date(
          startDateTime.setFullYear(startDateTime.getFullYear() + 1)
        );
        newEndDateTime = new Date(
          endDateTime.setFullYear(endDateTime.getFullYear() + 1)
        );
      }
      break;

    default:
      throw new Error("Invalid range type");
  }

  newStartDateTime.setHours(0, 0, 0, 0);
  newEndDateTime.setHours(23, 59, 59, 999);

  return {
    startDateTime: newStartDateTime,
    endDateTime: newEndDateTime,
  };
}

export const getDashboardLineCartData = (
  data,
  title = "",
  chartInterval = DEFAULT_DATE_FORMAT
) => {
  if (!data) return undefined;

  let dailyLabelFormat = (date) => moment(date).format("MMM D");

  let weeklyLabelFormat = (date, endDate) => {
    let startOfWeek = moment(date);
    let endOfWeek = moment(endDate);
    let formatString =
      startOfWeek.month() === endOfWeek.month() ? "MMM D - D" : "MMM D - MMM D";
    return `${startOfWeek.format("MMM D")} - ${endOfWeek.format(
      formatString.split(" - ")[1]
    )}`;
  };

  let monthlyLabelFormat = (date) => moment(date).format("MMM YYYY");

  let labels = data?.map((d) => {
    if (chartInterval === "daily") return dailyLabelFormat(d?.date);
    else if (chartInterval === "weekly")
      return weeklyLabelFormat(d?.date, d?.dateEnd);
    else if (chartInterval === "monthly") return monthlyLabelFormat(d?.date);
    else return moment(d?.date).format(DEFAULT_DATE_FORMAT);
  });

  return {
    labels,
    datasets: [
      {
        label: title,
        data: data?.map((d) => d.value),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};

export const getAppointmentVideo = (appointment) => {
  let recordings = appointment?.recordings || [];

  let videoUrl = recordings?.find((r) =>
    videoMimeTypes.includes(r?.mimeType)
  )?.driveUrl;

  return videoUrl || null;
};

export const convertZoomMeetToAppAppointment = ({ zoomMeet, users }) => {
  let { startDateTime, endDateTime } = calculateEndDateTime({
    startDateTime: zoomMeet?.start_time,
    duration: zoomMeet?.duration,
  });

  let result = {
    appointmentType: "Zoom Meeting",
    title: zoomMeet?.topic,
    description: zoomMeet?.agenda,
    startDateTime,
    endDateTime,
    allDay: false,
    duration: zoomMeet?.duration,
    zoomMeetId: zoomMeet?.id,
    zoomUserId: zoomMeet?.host_id,
    zoomMeetLink: zoomMeet?.join_url,
    // zoomMeetingsMigrated: "",
    participants: [
      users?.find((u) => u?.zoomUserId === zoomMeet?.host_id) ||
        users?.find((u) => u?.email === "guy@ethica.finance"),
    ],
    inviteUser: false,
    autoStartRecording: false,
    createZoomMeeting: false,
  };

  return result;
};

export function calculateEndDateTime({ startDateTime, duration }) {
  // Convert startDateTime to a Date object
  const start = new Date(startDateTime);

  // Calculate endDateTime by adding duration (in minutes)
  const end = new Date(start.getTime() + duration * 60000);

  // Return the result as an object
  return {
    startDateTime: start.toISOString(),
    endDateTime: end.toISOString(),
  };
}

export function mergeArrays(arrayA, arrayB, key = "_id") {
  const map = new Map();

  // Add all objects from arrayA to the map
  arrayA.forEach((item) => {
    map.set(item?.[key], item);
  });

  // Add all objects from arrayB to the map (overwriting duplicates from arrayA)
  arrayB.forEach((item) => {
    map.set(item?.[key], item);
  });

  // Convert the map values back to an array
  return Array.from(map.values());
}
export function getWeekStartAndEnd(date) {
  const givenDate = new Date(date);
  const startOfWeek = moment(givenDate).startOf("week").toISOString();

  const endOfWeek = moment(givenDate).endOf("week").toISOString();
  return {
    startTime: startOfWeek,
    endTime: endOfWeek,
  };
}

export function getMonthStartAndEnd(date) {
  const givenDate = new Date(date);
  const startOfMonth = moment(givenDate).startOf("month").toISOString();

  const endOfMonth = moment(givenDate).endOf("month").toISOString();
  return {
    startTime: startOfMonth,
    endTime: endOfMonth,
  };
}

export function getCurrentWeekStartAndEnd() {
  const today = new Date();

  return getWeekStartAndEnd(today);
}

export function getDurationInHours(startDateTime, endDateTime) {
  // Parse the ISO date-time strings into Date objects
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);

  // Calculate the difference in milliseconds
  const diffInMs = end - start;

  // Convert milliseconds to hours
  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours;
}

export function getFileType(contentType) {
  if (contentType.startsWith("image/")) {
    return "image";
  } else if (contentType.startsWith("video/")) {
    return "video";
  } else if (contentType.startsWith("audio/")) {
    return "audio";
  } else if (contentType === "application/pdf") {
    return "pdf";
  } else if (
    contentType === "application/vnd.ms-excel" ||
    contentType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return "msexcel";
  } else if (
    contentType === "application/msword" ||
    contentType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return "msword";
  } else {
    return "file";
  }
}

export const fileTypeIcons = {
  image: (size = 40) => (
    <FileEarmarkImageFill size={size} className="text-success" />
  ),
  video: (size = 40) => <CameraReelsFill size={size} className="text-info" />,
  audio: (size = 40) => (
    <FileEarmarkMusicFill size={size} className="text-info" />
  ),
  pdf: (size = 40) => (
    <FileEarmarkPdfFill size={size} className="text-primary" />
  ),
  msexcel: (size = 40) => (
    <FileEarmarkSpreadsheetFill size={size} className="text-primary" />
  ),
  msword: (size = 40) => (
    <FileEarmarkWordFill size={size} className="text-primary" />
  ),
  file: (size = 40) => (
    <FileEarmarkTextFill size={size} className="text-info" />
  ),
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

export const downloadFileFromBase64 = ({ data, fileName, mimeType }) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = mimeType ? `data:${mimeType};base64,${data}` : data;
  downloadLink.target = "_self";
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export function extractFolderId(driveLink) {
  const regex = /[a-zA-Z0-9_-]{25,35}/;
  const match = driveLink.match(regex);
  if (match) {
    return match[0];
  } else {
    return null;
  }
}

export function sortUsersAlphabetically(users = []) {
  return users.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

export const sortUsersByRole = (users, roleToPrioritize) => {
  return users.slice().sort((a, b) => {
    // If a.role is the roleToPrioritize, it should come before b
    if (a.role === roleToPrioritize && b.role !== roleToPrioritize) {
      return -1;
    }
    // If b.role is the roleToPrioritize, it should come before a
    if (b.role === roleToPrioritize && a.role !== roleToPrioritize) {
      return 1;
    }
    // If both have the same role, or neither has the prioritized role, sort by name
    return a.name.localeCompare(b.name);
  });
};

export function toSentenceCase(str) {
  return _.capitalize(_.toLower(str));
}

export const GROUPPED_USER_BASED_ON_ROLE = ({
  users = [],
  priotize = SALES_ROLE,
}) => {
  const rolesToShowAtTop = [priotize, ADMIN_ROLE, MANAGER_ROLE];

  const sortedRole = [
    ...rolesToShowAtTop,
    ...ALL_ROLES.filter((r) => !rolesToShowAtTop.includes(r)),
  ];

  return sortedRole.map((role) => ({
    label: role,
    key: role,
    options: users
      .filter((u) => u.role === role)
      .map((user) => ({
        label: user.name || "",
        value: user._id,
      })),
  }));
};
