import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import useLocalization from "../../hooks/useLocalization";
import { productService } from "../../services/productService";
import AppModal from "../common/AppModal";
import CircularProgressBar from "../common/circular-progress";
import ContractSendForm from "../forms/ContractSendForm";

const CreateDealModal = ({ show, onHide, contact, showProgress, onSubmit }) => {
  const { translate } = useLocalization();
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const getProducts = async () => {
    const pageToFetch = 1;

    setLoadingProducts(true);
    const requestBody = {
      pageSize: 10000,
      pageNumber: pageToFetch,
    };

    const { response, error } = await productService.getProducts(requestBody);

    setLoadingProducts(false);

    if (error) {
      return toast.error(error);
    }

    setProducts(response.results);
  };

  useEffect(() => {
    if (show && products.length === 0) getProducts();
  }, [show]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={translate(`create_new_deal`)}
      showCloseButton={!showProgress}
      modalBodyClassName="m-0 p-2"
    >
      {loadingProducts ? (
        <div className="p-5 text-center">
          <CircularProgressBar size={50} />
        </div>
      ) : (
        <ContractSendForm
          products={products}
          contact={contact}
          showProgress={showProgress}
          onSubmit={onSubmit}
        />
      )}
    </AppModal>
  );
};

export default CreateDealModal;
