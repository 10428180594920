import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Table,
} from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import { snakeCase } from "lodash";
import { isValidProsperoUrl } from "../../helpers/global";
import { Plus, Trash } from "react-bootstrap-icons";
import CustomMultiSelect from "../common/CustomMultiSelect";

// Yup validation schema
const validationSchema = Yup.object().shape({
  description: Yup.string().required("Please provide the contract description"),
  price: Yup.number()
    .positive("Price must be positive")
    .required("Please provide the contract price"),
  templateLink: Yup.string(),
});

const defaultValues = {
  description: "",
  price: "",
  templateLink: "",
  units: [],
};
const AddEditContractForm = ({
  initialValues,
  showProgress,
  productOption,
  editMode,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  return (
    <Formik
      initialValues={
        initialValues
          ? {
              _id: initialValues?._id,
              description: initialValues?.description,
              price: initialValues?.price,
              templateLink: initialValues?.templateLink,
              units: initialValues?.units || [],
            }
          : defaultValues
      }
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setFieldError }) => {
        // Destructure setFieldError from the second argument
        const { templateLink } = values;
        if (templateLink && !isValidProsperoUrl(templateLink)) {
          // Set an error message for templateLink field
          setFieldError(
            "templateLink",
            `A template link should be of the form "https://app.goprospero.com/t/<24_digit_template_id>"`
          );
          return; // Prevent the form from being submitted
        }

        onSubmit(values); // Proceed with the submission if the URL is valid
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form className="p-2">
            <Row>
              {[
                {
                  name: "price",
                  type: "number",
                  label: "Price",
                  colSize: 3,
                },
                {
                  name: "templateLink",
                  type: "text",
                  colSize: 9,
                  label: "Template Link",
                },
                {
                  name: "description",
                  type: "text",
                  label: "Description",
                  colSize: 12,
                  isTextArea: true,
                },
              ].map((field) => (
                <Col key={field.name} xs={12} md={field.colSize || 6}>
                  <FormGroup key={field.name} className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate(snakeCase(field.label))}
                    </FormLabel>
                    <Field
                      name={field.name}
                      type={field.type}
                      className="form-control form-control-sm"
                      rows={2}
                      as={field.isTextArea ? "textarea" : FormControl}
                      size="sm"
                    />
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              ))}
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("products")}
                  </FormLabel>
                  <CustomMultiSelect
                    items={productOption?.map((product) => ({
                      label: product?.description,
                      value: product?._id,
                    }))}
                    selectedItems={values?.units || []}
                    onChange={(p) => setFieldValue("units", p)}
                    placeholder="Add units"
                    isClearable
                    height="30px"
                  />
                  <ErrorMessage
                    name={"units"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="primary"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "add" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditContractForm;
