import { ErrorMessage, Field, Form, Formik } from "formik";
import { snakeCase } from "lodash";
import React from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import { isValidProsperoUrl } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import CustomMultiSelect from "../common/CustomMultiSelect";
import HorizontalProgress from "../common/HorizontalProgress";

// Yup validation schema
const validationSchema = Yup.object().shape({
  description: Yup.string().required("Please provide the product description"),
});

const defaultValues = {
  description: "",
};
const AddEditProductForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
}) => {
  const { translate } = useLocalization();
  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form className="p-2">
            <Row>
              {[
                {
                  name: "description",
                  type: "text",
                  label: "Description",
                  colSize: 12,
                },
              ].map((field) => (
                <Col key={field.name} xs={12} md={field.colSize || 6}>
                  <FormGroup key={field.name} className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate(snakeCase(field.label))}
                    </FormLabel>
                    <Field
                      name={field.name}
                      type={field.type}
                      className="form-control form-control-sm"
                      rows={2}
                      as={field.isTextArea ? "textarea" : FormControl}
                      size="sm"
                    />
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              ))}
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="primary"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "add" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditProductForm;
