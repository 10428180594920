import React from "react";
import { ThemeProvider } from "react-bootstrap";
import { createRoot } from "react-dom/client";
import { LocalizeContextProvider } from "react-locale-language";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import { ContactProvider } from "./context/ContactContext";
import { SearchAndFilterProvider } from "./context/SearchAndFilterContext";
import { UserProvider } from "./context/UserContext";
import { getActiveLanguage, languageContent } from "./helpers/localization";

createRoot(document.getElementById("root")).render(
  <LocalizeContextProvider
    value={languageContent}
    languageCode={getActiveLanguage()}
  >
    <ThemeProvider>
      <ToastContainer autoClose={1500} />
      <BrowserRouter>
        <UserProvider>
          <ContactProvider>
            <SearchAndFilterProvider>
              <App />
            </SearchAndFilterProvider>
          </ContactProvider>
        </UserProvider>
      </BrowserRouter>
    </ThemeProvider>
  </LocalizeContextProvider>
);
