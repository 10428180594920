import React from "react";
import { Table } from "react-bootstrap";
import "./AppTable.scss";

const AppTable = ({ children, className = "smallFont mt-2" }) => {
  return (
    <Table bordered responsive hover className={className}>
      {children}
    </Table>
  );
};

export default AppTable;
