import React, { useEffect } from "react";
import useLocalization from "./hooks/useLocalization";
import AppRoutes from "./routes";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';

const App = () => {
  const { langCode } = useLocalization();

  useEffect(() => {
    document.dir = langCode === "Hebrew" ? "rtl" : "ltr";
  }, [langCode]);

  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;
