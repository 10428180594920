import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/Header";
import { AppChoicesProvider } from "../context/AppChoicesContext";
import { AppointmentProvider } from "../context/AppointmentContext";
import { ContactAndDealPopupProvider } from "../context/ContactAndDealPopupContext";
import { WebSocketProvider } from "../context/WebSocketContext";

const ProtectedRouteLayout = ({ headerVisible = true }) => {
  return (
    <>
      {headerVisible && <Header />}
      <AppChoicesProvider>
        <AppointmentProvider>
          <WebSocketProvider>
            <ContactAndDealPopupProvider>
              <Outlet />
            </ContactAndDealPopupProvider>
          </WebSocketProvider>
        </AppointmentProvider>
      </AppChoicesProvider>
    </>
  );
};

export default ProtectedRouteLayout;
