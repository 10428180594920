import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { mergeAppAndGoogleAppointments } from "../../../helpers/global";
import { availabilityService } from "../../../services/availabilityService";
import { CustomEvent } from "../../common/CustomEvent";
import Loader from "../../common/Loader";
import DraggableModal from "../../common/DraggableModal";
import AppointmentDetails from "../../deals/board-view/AppointmentDetails";

const AppointmentDetailModal = ({ show, onHide, appointment }) => {
  return (
    <DraggableModal
      show={show}
      onHide={onHide}
      width={{
        sm: "90%",
        md: 450,
        lg: 450,
      }}
      height={400}
      title={appointment?.title}
    >
      {appointment && <AppointmentDetails appointment={appointment} />}
    </DraggableModal>
  );
};

const Activity = ({ contact }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [appointmentDetailModalMeta, setAppointmentDetailModalMeta] =
    useState(null);

  const fetchAppointments = async () => {
    try {
      setIsLoading(true);
      const reqBody = {
        filter: {
          contact: contact?._id,
        },
        sort: { startDateTime: -1 },
        returnGoogleEvents: false,
      };

      const { response, error } = await availabilityService.search(reqBody);

      if (error) {
        toast.error(error);
        return;
      }

      const { results } = response;
      const app = mergeAppAndGoogleAppointments(results);

      let pastAppointments = [];
      let upcomingAppointments = [];
      const now = new Date();

      app?.forEach((appointment) => {
        if (new Date(appointment.startDateTime) < now) {
          pastAppointments.push(appointment);
        } else if (new Date(appointment.startDateTime) > now) {
          upcomingAppointments.push(appointment);
        }
      });

      setAppointments({ pastAppointments, upcomingAppointments });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (contact) {
      fetchAppointments();
    }
  }, [contact?._id]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="">
      <div className="bg-primary-light p-1 mb-2 mid fw-bold">
        Upcoming Events{" "}
        {appointments?.upcomingAppointments?.length > 0
          ? `(${appointments?.upcomingAppointments?.length})`
          : ""}
      </div>
      {appointments?.upcomingAppointments?.length > 0 ? (
        appointments?.upcomingAppointments?.map((app) => (
          <div
            key={app?._id}
            className="mb-1 hover"
            onClick={() => setAppointmentDetailModalMeta({ appointment: app })}
          >
            <CustomEvent event={app} showFullDate showLinkToVideo />
          </div>
        ))
      ) : (
        <div className="p-2 d-flex justify-content-center">
          <h6 className="smallFont">No upcoming events!</h6>
        </div>
      )}
      <div className="bg-primary-light p-1 my-2 mid fw-bold">
        Past Events{" "}
        {appointments?.pastAppointments?.length > 0
          ? `(${appointments?.pastAppointments?.length})`
          : ""}
      </div>
      {appointments?.pastAppointments?.length > 0 ? (
        appointments?.pastAppointments?.map((app) => (
          <div
            key={app?._id}
            className="mb-1 hover"
            onClick={() => setAppointmentDetailModalMeta({ appointment: app })}
          >
            <CustomEvent event={app} showFullDate showLinkToVideo />
          </div>
        ))
      ) : (
        <div className="p-2 d-flex justify-content-center">
          <h6 className="smallFont">No past events!</h6>
        </div>
      )}
      <AppointmentDetailModal
        show={Boolean(appointmentDetailModalMeta)}
        onHide={() => setAppointmentDetailModalMeta(null)}
        appointment={appointmentDetailModalMeta?.appointment}
      />
    </div>
  );
};

export default Activity;
