import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [clientId, setClientId] = useState(null); // Store the client ID
  const [message, setMessage] = useState(null);
  const url = process.env.REACT_APP_WEB_SOCKET_URL;

  const socketRef = useRef(null); // Use useRef to store the WebSocket instance

  useEffect(() => {
    const socket = new WebSocket(url);
    socketRef.current = socket; // Assign the WebSocket instance to the ref

    socket.onopen = () => {
      setIsConnected(true);
      console.log("Connected to WebSocket");
    };

    // On receiving a message from the server
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      // Check if the message is assigning a client ID
      if (data.type === "ASSIGN_CLIENT_ID") {
        setClientId(data.clientId); // Set the client ID in state
      } else {
        setMessage(() => data); // Handle other messages
      }
    };

    // On disconnection
    socket.onclose = () => {
      setIsConnected(false);
      setClientId(null);
      console.log("Disconnected from WebSocket");
    };

    // Clean up the connection when the component is unmounted
    return () => {
      socketRef.current.close();
    };
  }, [url]);

  useEffect(() => {
    if (clientId) {
      sessionStorage.setItem("clientId", clientId);
    } else {
      sessionStorage.removeItem("clientId");
    }
  }, [clientId]);

  // Send a message to the server
  const sendMessage = (message) => {
    if (socketRef.current && isConnected) {
      const clientId = sessionStorage.getItem("clientId"); // Retrieve clientId from sessionStorage
      const messageWithClientId = { ...message, clientId }; // Attach clientId to the message
      socketRef.current.send(JSON.stringify(messageWithClientId)); // Send the message as JSON
    }
  };

  const contextObj = {
    isConnected,
    clientId,
    message,
    sendMessage,
  };

  return (
    <WebSocketContext.Provider value={contextObj}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Uncomment if you want to use this in your components
export const useWebSocket = () => {
  const { clientId, message, sendMessage } =
    useContext(WebSocketContext);

  return {
    clientId,
    message,
    sendMessage,
  };
};
