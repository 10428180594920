import React from "react";
import { Button } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";

const DashboardTabs = ({ disabled, tabs = [], selectedTab, onTabSelect }) => {
  const { translate } = useLocalization();
  return (
    <div className="d-flex flex-wrap">
      {tabs?.map((tab) => {
        return (
          <Button
            key={tab?.key}
            className="px-2 py-1 m-1"
            size="sm"
            disabled={disabled}
            variant={
              tab?.key === selectedTab?.key ? "primary text-white" : "dark"
            }
            onClick={() => onTabSelect(tab)}
          >
            <span className="large">{translate(tab.key)}</span>
          </Button>
        );
      })}
    </div>
  );
};

export default DashboardTabs;
