import moment from "moment";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  CloudArrowDownFill,
  PersonPlusFill,
  PlayBtn,
  Trash,
} from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import { Participants } from "../deals/board-view/AppointmentDetails";
import GoogleEventBadge from "./GoogleEventBadge";

const MeetingCard = ({
  fetchingZoomRecording,
  appointment,
  onAppointmentClick,
  onNavigateToDate,
  onViewMeetRecordingClick,
  onAssignContactClick,
  onImportClick,
  onDeleteMeetClick,
}) => {
  const { translate } = useLocalization();
  return (
    <Row
      className="m-0 p-2 border-bottom hover"
      onClick={() => {
        if (onAppointmentClick) {
          onAppointmentClick(appointment);
        }
      }}
    >
      <Col
        md={2}
        className="p-2 d-flex justify-content-center align-items-center appointment-card-date"
        onClick={(e) => {
          e.stopPropagation();
          if (onNavigateToDate) {
            onNavigateToDate(new Date(appointment?.startDateTime));
          }
        }}
      >
        <h6 className="mb-0" style={{ fontWeight: 600, fontSize: "18px" }}>
          {moment(appointment?.startDateTime).format("DD")}
        </h6>
        <h6 className="mb-0 mx-2 mt-1 mid">
          {moment(appointment?.startDateTime).format("MMM YYYY, ddd")}
        </h6>
      </Col>{" "}
      <Col
        md={2}
        className="px-0 d-flex justify-content-center align-items-center"
      >
        <div
          style={{ height: 12, width: 12, borderRadius: "50%" }}
          className="bg-primary"
        />
        <div className="mx-2" />
        <h6 className="mb-0 mid">
          {appointment?.allDay
            ? "All Day"
            : `${moment(appointment?.startDateTime).format("HH:mm")} - ${moment(
                appointment?.endDateTime
              ).format("HH:mm")}`}{" "}
        </h6>
      </Col>{" "}
      <Col md={3} className="px-0 d-flex align-items-center">
        <h6 className="mb-0 mx-1 fw-bold mid">{appointment?.title} </h6>
      </Col>
      <Col md={3} className="px-0">
        <Participants participants={appointment?.participants} />
      </Col>
      <Col
        md={2}
        className="d-flex justify-content-center align-items-center gap-2 px-0 "
      >
        {appointment?.isAppEvent === false && <GoogleEventBadge />}{" "}
        {onAssignContactClick && (
          <Button
            size="sm"
            disabled={fetchingZoomRecording}
            variant="outline-success"
            onClick={() => onAssignContactClick(appointment)}
            className="d-flex justify-content-center align-items-center gap-1 flex-wrap px-2"
          >
            <PersonPlusFill size={15} />
            <h6 className="mb-0 smallFont">{translate("assign")}</h6>
          </Button>
        )}
        {onImportClick && (
          <Button
            size="sm"
            disabled={fetchingZoomRecording}
            variant="outline-info"
            onClick={() => onImportClick(appointment)}
            className="d-flex justify-content-center align-items-center gap-1 flex-wrap px-2"
          >
            <CloudArrowDownFill size={15} />
            <h6 className="mb-0 smallFont">{translate("import")}</h6>
          </Button>
        )}
        {onDeleteMeetClick && (
          <Button
            disabled={fetchingZoomRecording}
            size="sm"
            variant="outline-danger"
            onClick={() => onDeleteMeetClick(appointment)}
            className="d-flex justify-content-center align-items-center gap-1 flex-wrap px-2"
          >
            <Trash size={14} />
          </Button>
        )}
        {onViewMeetRecordingClick && (
          <Button
            disabled={fetchingZoomRecording}
            size="sm"
            variant="outline-dark"
            onClick={() => onViewMeetRecordingClick(appointment)}
            className="d-flex justify-content-center align-items-center gap-1 flex-wrap px-2"
          >
            <PlayBtn size={14} />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default MeetingCard;
