import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Send, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../helpers/api";
import {
  ADMIN_ROLE,
  DEAL_VIEWS,
  DEFAULT_PAGE_SIZE,
} from "../../helpers/constants";
import { getDealsTableColumns } from "../../helpers/dataSheetConstants";
import { updateItemsInArray } from "../../helpers/global";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { contractService } from "../../services/contractService";
import AlertModal from "../common/AlertModal";
import DataTable from "../common/data-table/DataTable";
import { findIfObjectIsInArrayOrNot } from "../contacts/BrowseContacts";
import CreateDealModal from "./CreateDealModal";
import ProsperoContractEditAndSendSidebar from "./ProsperoContractEditSidebar";
import DealsBoardView from "./board-view/DealsBoardView";
import DealsTabAndFilter from "./DealsTabAndFilter";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";
import { isNumber } from "lodash";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { isAdminOrManager } from "../../helpers/session";
import { useWebSocket } from "../../context/WebSocketContext";
import useSelection from "../../hooks/useSelection";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseDeals = ({
  contact: contact, // if contact is provided take deals from this contact ignore fetching
  allowFilter = true,
  allowSort = true,
  editable = true,
  columnToHide = [],
  specificClientMode = false,
  maxTableHeight = `calc(100vh - 135px)`,
  onDealChange,
}) => {
  const abortControllerRef = useRef(null);
  const { message } = useWebSocket();
  const users = useAppChoices("users");

  const products = useAppChoices("products");
  const units = useAppChoices("units");

  const { translate } = useLocalization();
  const { user } = useAuth();
  const { searchBoxOptions } = useSearchAndFilterBox();

  const { openDealPopup, updatedDeal, updatedContact } =
    useContactAndDealPopup();

  const [activeView, setActiveView] = useState(DEAL_VIEWS[0].name);
  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [tableSortOption, setTableSortOption] = useState({
    key: "createdAt",
    order: "desc",
  });
  const [fetchingFirstPageDeals, setFetchingFirstPageDeals] = useState(false);
  const [fetchingMoreDeals, setFetchingMoreDeals] = useState(false);
  const [deleteContractModalMeta, setDeleteContractModalMeta] = useState(null);
  const [boardViewFilters, setBoardViewFilters] = useState({
    status: ["Signed"],
  });

  // board view deal dragging state
  const [updatingProductionStage, setUpdatingProductionStage] = useState(false);

  //original products array
  const [deals, setDeals] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  // new deal
  const [newDealModalMeta, setNewDealModalMeta] = useState(null);
  const [prosperoContractEditMeta, setProsperoContractEditMeta] =
    useState(null);

  // board deals
  const boardDealabortControllerRef = useRef(null);

  const [boardDeals, setBoardDeals] = useState([]);
  const [fetchingBoardDeals, setFetchingBoardDeals] = useState(false);
  const [showFoatingContract, setShowFloatingContract] = useState(false);

  const [tableScrollTop, setTableScrollTop] = useState();

  const {
    selectAllMode,
    setSelectAllMode,
    selectedItems,
    setSelectedItems,
    unselectedItems,
    setUnselectedItems,
    isItemSelected,
    onToggleSelectItem,
    onToggleSelectAll,
    clearSelection,
  } = useSelection(deals);

  const tableColumns = useMemo(
    () =>
      getDealsTableColumns(
        user,
        columnToHide,
        users,
        products,
        onToggleSelectAll,
        onToggleSelectItem,
        isItemSelected,
        selectAllMode,
        translate
      ),
    [
      user,
      users,
      products,
      onToggleSelectAll,
      isItemSelected,
      onToggleSelectItem,
      selectAllMode,
      columnToHide,
    ]
  );

  const scrollToContactRow = (scrollTop) => {
    const containerElement = document.getElementById("table-container");
    const offset = -80; // Adjust this value based on the height of your fixed element

    if (containerElement) {
      containerElement.scroll({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const fetchDeals = async () => {
    if (
      !searchBoxOptions ||
      !toLoadPageInfo ||
      (searchBoxOptions?.query && searchBoxOptions?.query.length < 2)
    ) {
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch, isNotInitialFetch = false } =
      toLoadPageInfo;

    const loadingMoreProducts = pageToFetch > 1;

    if (!isNotInitialFetch) {
      if (loadingMoreProducts) {
        setFetchingMoreDeals(true);
      } else {
        setFetchingFirstPageDeals(true);
      }
    }

    const { query } = searchBoxOptions;
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      query,
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: tableSortOption,
      filter: [
        ...tableFilterValues,
        ...(contact ? [{ key: "contact", value: contact._id }] : []),
      ],
    };

    const { response, error } = await contractService.getContracts(
      requestBody,
      signal
    );
    if (error === ABORT_ERROR) return;

    setFetchingMoreDeals(false);
    setFetchingFirstPageDeals(false);

    if (!isNotInitialFetch) {
      clearSelection();
    }

    if (error) {
      return toast.error(error);
    }

    if (isNumber(toLoadPageInfo?.scrollTop)) {
      scrollToContactRow(toLoadPageInfo?.scrollTop);
    }

    const { pageNumber, totalPages, results = [], resultCount } = response;

    setLoadedPageInfo({ totalPages, pageNumber, resultCount });

    let filteredDealsByContact = contact
      ? results?.filter((d) => d?.contact?._id === contact?._id)
      : results;

    setDeals((prevProducts) =>
      loadingMoreProducts
        ? [...prevProducts, ...filteredDealsByContact]
        : filteredDealsByContact
    );
  };

  const fetchDealsAfterUpdate = (scrollTop) => {
    // fetches contacts and scrolls back to previous position
    setToLoadPageInfo({
      ...loadedPageInfo,
      pageSize: loadedPageInfo?.pageNumber * DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      scrollTop,
      isNotInitialFetch: true,
    });
    fetchBoardDeals();
  };

  const fetchBoardDeals = async () => {
    if (searchBoxOptions?.query && searchBoxOptions?.query.length < 2) {
      return;
    }

    // If an old API call is in progress, abort it
    try {
      if (boardDealabortControllerRef.current) {
        boardDealabortControllerRef.current.abort();
      }

      setFetchingBoardDeals(true);

      const controller = new AbortController();
      const { signal } = controller;

      boardDealabortControllerRef.current = controller;
      let { query } = searchBoxOptions;
      const requestBody = {
        query,
        filter: [
          { key: "unit", value: boardViewFilters?.product },
          { key: "assignedTo", value: boardViewFilters?.assignedTo },
          { key: "status", value: boardViewFilters?.status },
        ],
        attachContactAppointments: true,
      };

      const { response, error } = await contractService.getContracts(
        requestBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      setFetchingBoardDeals(false);

      if (error) {
        return toast.error(error);
      }
      const { results = [] } = response;
      setBoardDeals(results);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageDeals || fetchingMoreDeals) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      scrollTop: undefined,
      isNotInitialFetch: true,
    });
  };

  const onDealClick = useCallback(
    (contract) => {
      if (editable) {
        openDealPopup(contract?._id);
      }
    },
    [editable]
  );

  const onDeleteSelectedContractClick = () => {
    setDeleteContractModalMeta({
      contractToBeDeleted: selectedItems,
      showProgress: false,
    });
  };

  const deleteSelectedDeals = async () => {
    const { contractToBeDeleted } = deleteContractModalMeta;

    setDeleteContractModalMeta((deleteModalMeta) => ({
      ...deleteModalMeta,
      showProgress: true,
    }));

    try {
      const { response, error } = await contractService.deleteSelectedContract({
        ids: contractToBeDeleted?.map((deal) => deal._id),
      });
      if (response) {
        let updatedDeals = [...deals].filter(
          (deal) => !contractToBeDeleted.find((c) => c._id === deal?._id)
        );
        setDeals(updatedDeals);

        if (onDealChange) {
          onDealChange(updatedDeals);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteContractModalMeta(null);
    }
  };

  const onCreateNewDealClick = () => {
    setNewDealModalMeta({});
  };

  const onNewDealSubmit = async ({
    sendImmediately,
    productId,
    quote,
    selectedContact,
    sendContract,
  }) => {
    setNewDealModalMeta({ showProgress: true });

    const { response, error } = await contractService.createContract({
      contactId: selectedContact ? selectedContact?._id : contact._id,
      productId,
      proposalQuote: quote,
      sendImmediately,
      sendContract,
    });

    if (error) {
      setNewDealModalMeta({ showProgress: false });
      return toast.error(error);
    }

    setNewDealModalMeta(null);
    if (response) {
      let updatedDeals = [response, ...deals];
      setDeals(updatedDeals);
      setLoadedPageInfo({
        ...loadedPageInfo,
        resultCount: (loadedPageInfo?.resultCount || 0) + 1,
      });
      if (onDealChange) {
        onDealChange(updatedDeals);
      }
    }

    if (sendImmediately) {
      toast.success("Deal Sent Successfully");
      return;
    }

    setProsperoContractEditMeta({ contract: response });
  };

  const onContractEditAndSendClick = async () => {
    const { contract } = prosperoContractEditMeta;
    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: true,
    });

    const { response, error } = await contractService.sendContractViaEmail({
      contractId: contract._id,
      contactId: contact._id,
    });

    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: false,
    });

    if (error) {
      return toast.error(error);
    }

    toast.success("Deal Sent Successfully");

    setProsperoContractEditMeta(null);
  };

  const onProductionStageDragEnd = async (reqBody) => {
    try {
      setUpdatingProductionStage(true);
      const contractUpdatePayload = reqBody;

      let originalDeals = [...deals];

      let originalBoardDeals = [...boardDeals];

      let updatedDeal = boardDeals?.find(
        (d) => contractUpdatePayload?._id === d?._id
      );

      updatedDeal.units = contractUpdatePayload?.units;

      const updatedDeals = [...updateItemsInArray(deals, updatedDeal)];
      const updatedBoardDeals = [
        ...updateItemsInArray(boardDeals, updatedDeal),
      ];

      setDeals(updatedDeals);
      setBoardDeals(updatedBoardDeals);

      const { response, error } = await contractService.updateContract(
        contractUpdatePayload
      );

      if (error) {
        toast.error(error);
        setBoardDeals(originalBoardDeals);
        setDeals(originalDeals);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingProductionStage(false);
    }
  };

  useDebouncedEffect(
    () => {
      setToLoadPageInfo({ ...initialPageInfo });
    },
    [tableFilterValues, tableSortOption, searchBoxOptions],
    300
  );

  useEffect(() => {
    if (contact && specificClientMode) {
      setDeals(contact?.contracts);
    } else {
      fetchDeals();
      fetchBoardDeals();
    }
  }, [toLoadPageInfo, contact, specificClientMode]);

  useDebouncedEffect(
    () => {
      // fetch board deals
      if (specificClientMode) return;
      fetchBoardDeals();
    },
    [boardViewFilters, specificClientMode],
    200
  );

  useEffect(() => {
    // update deals if popup deal changes
    if (deals?.length <= 0) return;
    if (Boolean(updatedDeal)) {
      let { action, deal } = updatedDeal;
      if (action === "delete") {
        fetchDealsAfterUpdate(tableScrollTop);
      }
      if (action === "update") {
        fetchDealsAfterUpdate(tableScrollTop);
      }
    }
  }, [updatedDeal]);

  useEffect(() => {
    // update deals if contact changes to show in table
    if (Boolean(updatedContact)) {
      let { action, contact } = updatedContact;
      if (action === "delete") {
        let updatedTableDeals = deals?.filter(
          (d) => d?.contact?._id !== contact?._id
        );
        let updatedBoardDeals = boardDeals?.filter(
          (d) => d?.contact?._id !== contact?._id
        );
        setDeals(updatedTableDeals);
        setBoardDeals(updatedBoardDeals);
      }
      if (action === "update") {
        let updatedTableDeals = deals.map((deal) => {
          if (deal?.contact?._id === contact?._id) {
            return { ...deal, contact };
          }
          return deal;
        });
        let updatedBoardDeals = boardDeals.map((deal) => {
          if (deal?.contact?._id === contact?._id) {
            return { ...deal, contact };
          }
          return deal;
        });
        setDeals(updatedTableDeals);
        setBoardDeals(updatedBoardDeals);
      }
    }
  }, [updatedContact]);

  useEffect(() => {
    if (!message) return;

    const { type, payload } = message;

    switch (type) {
      case "CONTACT_UPDATED":
        if (payload?._id) {
          const updateDeals = (dealsList) =>
            dealsList.map((deal) =>
              deal?.contact?._id === payload._id
                ? { ...deal, contact: payload }
                : deal
            );

          setDeals(updateDeals(deals));
          setBoardDeals(updateDeals(boardDeals));
        }
        break;

      case "CONTACT_DELETED":
        if (payload?._id) {
          const filterDeals = (dealsList) =>
            dealsList.filter((deal) => deal?.contact?._id !== payload._id);

          setDeals(filterDeals(deals));
          setBoardDeals(filterDeals(boardDeals));
        }
        break;

      default:
        console.warn("Unknown message type:", type);
        break;
    }
  }, [message]);

  useEffect(() => {
    setActiveView(DEAL_VIEWS[specificClientMode ? 1 : 0].name);
  }, [specificClientMode]);

  useEffect(() => {
    if (!isAdminOrManager(user.role)) {
      setBoardViewFilters((prevFilter) => ({
        ...prevFilter,
        assignedTo: [user._id],
      }));
    }
  }, [user]);

  const totalPageDate = useMemo(() => {
    if (contact) return contact?.contracts?.length;
    else return loadedPageInfo?.resultCount;
  }, [loadedPageInfo, contact]);

  return (
    <>
      <Container
        fluid
        className={`d-flex flex-column ${
          specificClientMode ? "p-0 m-0 h-100" : "px-2 pb-2"
        }`}
      >
        <div className={`flex-grow-1`}>
          {!specificClientMode && (
            <DealsTabAndFilter
              activeView={activeView}
              setActiveView={setActiveView}
              products={units}
              users={users}
              deals={deals}
              boardViewFilters={boardViewFilters}
              setBoardViewFilters={setBoardViewFilters}
              showFoatingContract={showFoatingContract}
              setShowFloatingContract={setShowFloatingContract}
            />
          )}
          {activeView === "table" && (
            <DataTable
              maxTableHeight={maxTableHeight}
              rowKey={"_id"}
              columns={tableColumns}
              data={deals}
              sortOptions={tableSortOption}
              onTableScrollTopChange={setTableScrollTop}
              onSortChange={setTableSortOption}
              filterValues={tableFilterValues}
              onFilterValuesChange={setTableFilterValues}
              onRowClick={onDealClick}
              onBottomReached={loadMoreData}
              loadingMoreData={fetchingMoreDeals}
              bottomOffset={300}
              loadingFirstPageData={fetchingFirstPageDeals}
              allowFilter={allowFilter}
              allowSort={allowSort}
            />
          )}
          {activeView === "board" && (
            <DealsBoardView
              fetchingBoardDeals={fetchingBoardDeals}
              maxHeight={maxTableHeight}
              boardDeals={boardDeals}
              boardViewFilters={boardViewFilters}
              products={units}
              onDealClick={onDealClick}
              updatingProductionStage={updatingProductionStage}
              onProductionStageDragEnd={onProductionStageDragEnd}
              showFoatingContract={showFoatingContract}
            />
          )}
        </div>

        <div
          className="d-flex align-items-center py-1 w-100 bg-white border-top"
          style={{ position: "absolute", bottom: 0, left: 0 }}
        >
          <div className="flex-grow-1 d-flex align-items-center">
            {/* Show selected contact count */}
            {editable && (
              <Button
                size="sm"
                onClick={onCreateNewDealClick}
                variant="success"
                className="align-items-center d-flex text-white py-0 mx-1"
              >
                <Send size={10} className="mx-1" />
                {translate("create_new_deal")}
              </Button>
            )}

            {(selectedItems.length > 0 || selectAllMode) && (
              <>
                <Button
                  className="smallFont py-0 mx-1"
                  variant="dark"
                  size="sm"
                >
                  <span className="text-white smallFont mx-1">
                    {selectAllMode
                      ? totalPageDate - unselectedItems?.length
                      : selectedItems?.length}{" "}
                    / {totalPageDate ?? 0} Selected
                  </span>
                </Button>
                {/* Delete selected contact button */}
                {isAdminOrManager(user?.role) && !selectAllMode && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    className="px-1 py-0 mx-1"
                    onClick={onDeleteSelectedContractClick}
                  >
                    <Trash className="mb-1" />
                  </Button>
                )}
                {/* Edit selected contact button */}
              </>
            )}
          </div>
        </div>
      </Container>
      {selectedItems.length > 0 && (
        <AlertModal
          show={Boolean(deleteContractModalMeta)}
          onHide={() => setDeleteContractModalMeta(false)}
          onDismissClick={() => setDeleteContractModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_contracts")}
          showProgress={deleteContractModalMeta?.showProgress}
          onContinueClick={deleteSelectedDeals}
        />
      )}

      <CreateDealModal
        show={Boolean(newDealModalMeta)}
        showProgress={newDealModalMeta?.showProgress}
        contact={contact}
        onHide={() => setNewDealModalMeta(null)}
        onSubmit={onNewDealSubmit}
      />
      <ProsperoContractEditAndSendSidebar
        contract={prosperoContractEditMeta?.contract}
        show={Boolean(prosperoContractEditMeta)}
        onHide={() => setProsperoContractEditMeta(false)}
        onContractEditAndSendClick={onContractEditAndSendClick}
        showProgress={prosperoContractEditMeta?.showProgress}
      />
    </>
  );
};

export default BrowseDeals;
