import { QueryBuilderAntD } from "@react-querybuilder/antd";
import React from "react";
import { Button } from "react-bootstrap";
import { Plus, X } from "react-bootstrap-icons";
import {
  QueryBuilder as QB,
  formatQuery,
  defaultRuleProcessorMongoDB,
  ValueEditor,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";
import EditableTagsInput from "../EditableTagsInput";
import useLocalization from "../../../hooks/useLocalization";

const ruleProcessor = (rule, options) => {
  // Defer to the default processor for all other operators
  return defaultRuleProcessorMongoDB(rule, options);
};

const RemoveRuleButton = ({ handleOnClick }) => {
  return (
    <X
      style={{ minWidth: 25 }}
      size={25}
      className="hover text-danger"
      onClick={handleOnClick}
    />
  );
};
const AddRuleButton = ({ handleOnClick }) => {
  return (
    <Button
      onClick={handleOnClick}
      variant="success"
      size="sm"
      className="px-1 py-0 text-white d-flex align-items-center"
    >
      <Plus size={15} />
      <span className="tiny">Rule</span>
    </Button>
  );
};
const AddGroupButton = ({ handleOnClick }) => {
  return (
    <Button
      onClick={handleOnClick}
      variant="primary"
      size="sm"
      className="px-1 py-0 text-white d-flex align-items-center"
    >
      <Plus size={15} />
      <span className="tiny">Group</span>
    </Button>
  );
};

const CustomValueEditor = (props) => {
  let { operator, values, value, handleOnChange, disabled } = props;
  if ((operator === "in" || operator === "notIn") && !values.length) {
    value = typeof value === "string" ? [] : value;
    return (
      <EditableTagsInput
        tags={value}
        disabled={disabled}
        onTagsChange={handleOnChange}
        maxCharactersForTag={20}
      />
    );
  }
  return (
    <ValueEditor
      {...props}
      className={!values.length && "form-control form-control-sm"}
    />
  );
};

const QueryBuilder = ({ fields, disabled, query, onChange }) => {
  const onQueryChange = (query) => {
    onChange({
      filter: query,
      filterParsed: formatQuery(query, { format: "mongodb", ruleProcessor }),
    });
  };

  return (
    <>
      <QueryBuilderAntD>
        <QB
          disabled={disabled}
          fields={fields}
          query={query}
          onQueryChange={onQueryChange}
          controlClassnames={{
            queryBuilder: "bg-light",
            combinators: "smallFont",
            addRule: "bg-success smallFont",
            addGroup: "bg-primary smallFont",
            body: " smallFont",
            fields: "smallFont",
            operators: "",
            value: "",
            ruleGroup: "bg-light",
          }}
          controlElements={{
            removeRuleAction: RemoveRuleButton,
            removeGroupAction: RemoveRuleButton,
            addRuleAction: AddRuleButton,
            addGroupAction: AddGroupButton,
            valueEditor: CustomValueEditor,
          }}
        />
      </QueryBuilderAntD>
    </>
  );
};

export default QueryBuilder;
