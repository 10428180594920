import { Col, Row } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import AddEditReferrerForm from "../forms/AddEditReferrerForm";
import BrowseCommissions from "../commissions/BrowseCommissions";

const AddEditReferrerModal = ({
  show,
  initialValues,
  editMode,
  onHide,
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  const onFormSubmit = (referrer) => {
    const referrerToSubmit = {
      ...referrer,
    };

    onSubmit && onSubmit(referrerToSubmit);
  };

  return (
    <AppModal
      fullscreen
      show={show}
      onHide={onHide}
      title={
        editMode ? translate(`update_referrer`) : translate(`add_new_referrer`)
      }
      showCloseButton={!showProgress}
      modalBodyClassName="pt-1"
    >
      <div>
        {" "}
        <h6 className="mb-0 m-1">Referrer</h6>
        <hr className="my-1" />
        <AddEditReferrerForm
          initialValues={initialValues}
          showProgress={showProgress}
          editMode={editMode}
          onSubmit={onFormSubmit}
        />
        {editMode && (
          <>
            <hr className="my-1" />
            <BrowseCommissions referredBy={initialValues} />
          </>
        )}
      </div>
    </AppModal>
  );
};

export default AddEditReferrerModal;
