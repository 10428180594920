import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";

const ActiveTrailDropdown = ({ activeTrails = [], value, onChange }) => {
  return (
    <CustomMultiSelect
      items={activeTrails}
      selectedItems={value}
      isMulti={false}
      height="30px"
      onChange={onChange}
      placeholder="Select"
      closeMenuOnSelect
    />
  );
};

export default ActiveTrailDropdown;
