import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const inboundSourceDefaultsStatusService = {
  getInboundSourceDefaultsStatus: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_INBOUND_SOURCE_DEFAULT_STATUS,
      requestBody,
      method: 'POST',
      signal,
    });

    return { response, error };
  },
  updateInboundSourceDefaultsStatus: async (inbound, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.UPDATE_INBOUND_SOURCE_DEFAULT_STATUS(inbound._id),
      requestBody: inbound,
      method: "PUT",
      signal,
    });
    return { response, error };
  },
};
