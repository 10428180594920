import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  CalendarDate,
  Cash,
  ChatQuote,
  Check,
  FileEarmarkText,
  Trash,
  XLg,
} from "react-bootstrap-icons";
import GoogleMailIcon from "../../assets/GoogleMailIcon.png";
import PhoneCallIcon from "../../assets/PhoneCallIcon.png";
import RujumAppIcon from "../../assets/RujumAppIcon.png";
import WhatsAppIcon from "../../assets/WhatsAppIcon.png";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import AlertModal from "../common/AlertModal";
import AppModal from "../common/AppModal";
import Comments from "../common/Comments";
import FormSection from "../common/FormSection";
import CircularProgressBar from "../common/circular-progress";
import AppointmentModal from "../contacts/contact-details/AppointmentModal";
import DealComissionForm from "../forms/DealCommissionForm";
import ContractDetailForm from "../forms/DealDetailForm";
import DealPaymentForm from "../forms/DealPaymentForm";

import ContentLoader from "react-content-loader";
import { commissionTypes } from "../../helpers/constants";
import { useWebSocket } from "../../context/WebSocketContext";
import { toast } from "react-toastify";

const UpdateDealModal = ({
  show,
  onHide,
  products,
  originalContract,
  contract,
  onDeleteDealClick,
  onContractDetailSubmit,
  newCommentText,
  commentsUpdating,
  deletingComment,
  editingComment,
  setNewCommentText,
  setDeletingComment,
  setEditingComment,
  deleteComment,
  onNewCommentSubmit,
  onEditingCommentSubmit,
  updatingContract,
  onDealChange,
  makePhoneCall,
  sendToRujum,
  fetching = false,
  referrals = [],
}) => {
  if (!Boolean(show)) return null;

  const { translate } = useLocalization();
  const { user } = useAuth();
  const { message } = useWebSocket();
  const [appointmentModalMeta, setAppointmentModalMeta] = useState(null);

  const isContractModified = useMemo(
    () => !_.isEqual(contract, originalContract),
    [contract, originalContract]
  );

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isContractModified) {
        e.preventDefault();
        e.returnValue = ""; // This line is necessary for older browsers
        return ""; // Modern browsers will show the default confirmation dialog
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isContractModified]);

  useEffect(() => {
    if (message && Boolean(show)) {
      let { type, payload } = message;
      switch (type) {
        case "CONTACT_UPDATED":
          if (contract?.contact?._id === payload?._id) {
            toast.info(
              "The contact associated to this contract was updated by someone."
            );
            onDealChange({ contact: payload });
          }
          break;

        case "CONTACT_DELETED":
          if (payload?._id === contract?.contact?._id) {
            toast.info(
              "The contact associated to this contract was deleted by someone."
            );
            onHide();
          }
          break;

        default:
          console.log("Unknown message type");
          break;
      }
    }
  }, [message, show]);

  const DealFooter = useCallback(() => {
    return (
      <div className="">
        <Button
          size="sm"
          className="px-1 py-0 text-white mx-1"
          variant="danger"
          disabled={commentsUpdating || updatingContract || fetching}
          onClick={onHide}
        >
          <span className="py-1">
            {translate("close")} <XLg size={12} />
          </span>
        </Button>
        <Button
          size="sm"
          className="px-1 py-0 text-white mx-1"
          variant={isContractModified ? "success" : "secondary"}
          disabled={
            commentsUpdating ||
            updatingContract ||
            fetching ||
            !isContractModified
          }
          onClick={onContractDetailSubmit}
        >
          {updatingContract ? (
            <span className="mx-1 px-1 text-white">
              <CircularProgressBar size={12} />
              <span className="mx-1 smallFont">{translate("please_wait")}</span>
            </span>
          ) : (
            <span className="py-1">
              {translate("save")} <Check size={15} />
            </span>
          )}
        </Button>
      </div>
    );
  }, [contract, updatingContract, fetching, show, isContractModified]);

  return (
    <AppModal
      show={show}
      fullscreen
      hideHeader
      modalBodyClassName="p-0"
      footerContent={DealFooter}
    >
      {fetching || !Boolean(contract) ? (
        <ContentLoader className="p-2 w-50">
          {" "}
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="15px" />
          <rect x="0" y="20" rx="4" ry="4" width="90%" height="15px" />
          <rect x="0" y="40" rx="4" ry="4" width="80%" height="15px" />
        </ContentLoader>
      ) : (
        <div className="d-flex flex-column">
          <div
            style={{ position: "sticky", top: 0, zIndex: 10000 }}
            className="d-flex justify-content-between align-items-center border-bottom py-1 bg-white"
          >
            <div className="d-flex flex-wrap gap-1 px-2">
              {[
                {
                  label: "call",
                  iconVariant: "success",
                  imgSrc: PhoneCallIcon,
                  onClick: () =>
                    makePhoneCall(contract?.contact?.phoneNumbers?.[0]),
                  disabled: false,
                },
                {
                  label: "whatsapp",
                  url: `https://wa.me/+972${contract?.contact?.phoneNumbers?.[0]}`,
                  iconVariant: "success",
                  imgSrc: WhatsAppIcon,
                },
                {
                  label: "email",
                  url: `mailto:${contract?.contact?.emails?.[0]}`,
                  iconVariant: "primary",
                  imgSrc: GoogleMailIcon,
                },
                {
                  label: "set_appointment",
                  iconVariant: "info",
                  Icon: CalendarDate,
                  onClick: () => setAppointmentModalMeta({}),
                  disabled: false,
                },
                {
                  label: "send_to_rujum",
                  iconVariant: "purple",
                  imgSrc: RujumAppIcon,
                  onClick: () => sendToRujum(contract?.contact),
                  disabled: false,
                },
              ].map(
                ({
                  label,
                  url,
                  iconVariant,
                  Icon,
                  imgSrc,
                  onClick,
                  disabled,
                }) => (
                  <Button
                    className="px-1 py-1 d-flex justify-content-between border-light align-items-center"
                    key={`${label}-${url}`}
                    variant={"light-gray"}
                    href={url}
                    target="_blank"
                    onClick={onClick}
                    disabled={disabled}
                  >
                    {imgSrc ? (
                      <img
                        size={13}
                        src={imgSrc}
                        height={20}
                        width={20}
                        alt="icon"
                      />
                    ) : (
                      <Icon className={`text-${iconVariant}`} />
                    )}

                    <span className="smallFont mx-1">{translate(label)} </span>
                  </Button>
                )
              )}
            </div>

            <div className="d-flex">
              <Button
                className="px-1 py-0 d-flex justify-content-between align-items-center mx-1"
                variant={"danger"}
                size="sm"
                onClick={() => onDeleteDealClick(contract)}
              >
                <Trash size={12} className={`text-white`} />{" "}
                <span className="smallFont mx-1">{translate("delete")} </span>
              </Button>{" "}
              {contract?.prosperoProposalMeta?.link && (
                <Button
                  className="px-1 py-0 d-flex justify-content-between align-items-center mx-1"
                  variant={"info"}
                  href={contract?.prosperoProposalMeta?.link}
                  target={"_blank"}
                >
                  <FileEarmarkText size={12} className={`text-white`} />{" "}
                  <span className="smallFont mx-1">
                    {translate("contract_link")}{" "}
                  </span>
                </Button>
              )}
            </div>
          </div>

          <div className="flex-grow-1 p-1">
            <div className="">
              <ContractDetailForm
                disabled={updatingContract}
                originalContract={originalContract}
                onChange={onDealChange}
                contract={contract}
                products={products}
                user={user}
              />
            </div>
            <div className="my-2 bg-secondary w-100" style={{ height: 2 }} />

            <Row className="m-0 h-100">
              {isAdminOrManager(user?.role) && (
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  className="border-end border-2 border-secondary"
                >
                  <FormSection
                    Icon={ChatQuote}
                    title={translate("payments")}
                    bodyClassName="p-1"
                  >
                    <DealPaymentForm
                      disabled={updatingContract}
                      contract={contract}
                      onChange={onDealChange}
                      onAddNewPayment={() => {
                        let refferedBy =
                          contract?.contact?.referredBy?._id ||
                          contract?.contact?.referredBy;

                        let newCommission;

                        if (refferedBy) {
                          newCommission = {
                            type: commissionTypes[1],
                            assignedTo: null,
                            commission: 0,
                          };
                          onDealChange({
                            commissions: [
                              ...contract?.commissions,
                              newCommission,
                            ],
                          });
                        } /* else {
                        newCommission = {
                          type: commissionTypes[0],
                          assignedTo: null,
                        };
                      } */
                      }}
                    />
                  </FormSection>

                  <FormSection
                    Icon={Cash}
                    title={translate("commissions")}
                    bodyClassName="p-1"
                    className="mt-2"
                  >
                    <DealComissionForm
                      disabled={updatingContract}
                      contract={contract}
                      onChange={onDealChange}
                      referrals={referrals}
                    />
                  </FormSection>
                </Col>
              )}

              <Col xs={12} sm={12} md={5} lg={5} className="">
                <FormSection
                  Icon={ChatQuote}
                  title={translate("comments_no_count")}
                  bodyClassName="p-1"
                >
                  <Comments
                    comments={contract?.comments}
                    text={newCommentText}
                    onTextChange={setNewCommentText}
                    onCommentSubmit={onNewCommentSubmit}
                    commentsUpdating={commentsUpdating}
                    editingComment={editingComment}
                    onEditingCommentUpdate={(text) =>
                      setEditingComment({ ...editingComment, text })
                    }
                    onCommentEditClick={(comment) =>
                      setEditingComment({ ...comment })
                    }
                    onCommentEditCancelClick={() => setEditingComment(null)}
                    onCommentEditSaveClick={onEditingCommentSubmit}
                    onCommentDeleteClick={(comment) =>
                      setDeletingComment({ ...comment })
                    }
                  />
                </FormSection>
              </Col>
            </Row>
          </div>
        </div>
      )}

      {contract?.comments?.length > 0 && (
        <AlertModal
          show={Boolean(deletingComment)}
          onHide={() => setDeletingComment(null)}
          onContinueClick={deleteComment}
          onDismissClick={() => setDeletingComment(null)}
          alertText={translate("are_you_sure_delete_comment")}
          showProgress={commentsUpdating}
        />
      )}
      <AppointmentModal
        show={Boolean(appointmentModalMeta)}
        onHide={() => setAppointmentModalMeta(null)}
        contact={contract?.contact}
        specificContactMode
      />
    </AppModal>
  );
};

export default UpdateDealModal;
