import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import { Button, FormSelect } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import HorizontalProgress from "../common/HorizontalProgress";

const DeleteUserModal = ({
  userDeleteModalMeta,
  users,
  setUserDeleteModalMeta,
  onHide,
  showProgress,
  onContinueClick,
}) => {
  const { translate } = useLocalization();

  const userToAssignOptions = useMemo(() => {
    if (users.length > 0 && userDeleteModalMeta?.user?._id) {
      return (
        users?.filter((user) => user._id !== userDeleteModalMeta?.user?._id) ??
        []
      );
    }
  }, [userDeleteModalMeta, users]);

  const handleContinue = () => {
    onContinueClick(userDeleteModalMeta);
  };

  return (
    <AppModal
      size={"md"}
      show={Boolean(userDeleteModalMeta)}
      onHide={onHide}
      title={translate("heads_up")}
      showCloseButton={!showProgress}
    >
      <div>
        {" "}
        <div className="text-center">
          <ExclamationTriangle className="text-danger" size={30} />
          <h6 className="mt-3">
            {translate("are_you_sure_you_want_to_delete_user")}
          </h6>
        </div>
        <div className="d-flex flex-column justify-content-center flex-wrap align-items-center mt-2">
          <h6 className="mt-2 smallFont">Assign another user</h6>
          <FormSelect
            size="sm"
            className="w-50"
            value={userDeleteModalMeta?.anotherUserToAssign}
            onChange={(e) => {
              let val = e.target.value;
              setUserDeleteModalMeta({
                ...userDeleteModalMeta,
                anotherUserToAssign: val === "unassign" ? null : val,
              });
            }}
          >
            <option value={"unassign"}>Unassign</option>
            {userToAssignOptions?.map(({ _id, name }) => (
              <option key={_id} value={_id}>
                {name}
              </option>
            ))}
          </FormSelect>{" "}
        </div>
        {showProgress && (
          <HorizontalProgress
            text={translate(snakeCase("Please wait")) || "Please wait"}
          />
        )}
        <div className="text-end mt-4">
          <Button
            size="sm"
            className="ms-2 px-2"
            variant={"dark"}
            onClick={onHide}
            disabled={showProgress}
          >
            {translate("cancel")}
          </Button>
          <Button
            size="sm"
            className="ms-2 px-2 text-white"
            variant={"success"}
            onClick={handleContinue}
            disabled={showProgress}
          >
            {translate("continue")}
          </Button>
        </div>
      </div>
    </AppModal>
  );
};

export default DeleteUserModal;
