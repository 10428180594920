import { snakeCase } from "lodash";
import moment from "moment";
import { Badge, Button } from "react-bootstrap";
import {
  Arrow90degLeft,
  Arrow90degRight,
  EnvelopeAt,
  Eye,
  ListTask,
  Pencil,
  PlayCircleFill,
  TelephoneFill,
  TelephoneInboundFill,
  TelephoneOutboundFill,
  Trash,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import UnderlineButton from "../components/common/UnderlineButton";
import {
  ADMIN_ROLE,
  CRM_APP_URL,
  DEAL_STATUSES,
  DEFAULT_DATE_TIME_FORMAT,
  FINANCIAL_STRATEGIST_ROLE,
  GROUPPED_STATUS,
  INBOUND_SOURCE,
  LEAD_SOURCE_OPTIONS,
  LOG_ACTION_MULTI_DELETE,
  LOG_ACTION_MULTI_UPDATE,
  LOG_COLLECTIONS,
  LOG_TYPE,
  REAL_ESTATE_ANALYST_ROLE,
  RUJUM_APP_URL,
  SALES_ROLE,
  STATUS,
  dealStatusToColor,
} from "./constants";
import {
  formatCurrency,
  getAppointmentVideo,
  getTextColor,
  hasHttpOrHttps,
  removeParamsFromUrl,
  toSentenceCase,
  truncateAndAddEllipsis,
} from "./global";
import RujumIcon from "../assets/RujumAppIcon.png";
import TextHighlighter from "../components/common/TextHiglighter";
import { isAdminOrManager } from "./session";
// Function to check if a date is in the past
const isDatePast = (dateToCheck) => {
  if (!dateToCheck) return false;

  const currentDate = new Date();
  const dateToCompare = new Date(dateToCheck);
  return dateToCompare < currentDate;
};

export const getContactTableColumns = ({
  selectAllMode,
  onToggleSelectAll,
  onToggleSelectItem,
  isItemSelected,
  inboundCampaignUrlOptions,
  textToHighlight,
  translate,
  showAllFields,
  users,
}) => {
  const allLabels = [
    {
      key: "view",
      labelRenderer: (field) => (
        <input
          type="checkbox"
          className="hover"
          checked={selectAllMode}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectAll(e.target.checked);
          }}
        />
      ),
      cellRenderer: (contact) => (
        <div
          className={`${
            contact?.duplicates?.filter((d) => d !== contact?._id)?.length >
              0 && "bg-primary-light rounded"
          } `}
          style={{ height: "100%" }}
        >
          <input
            type="checkbox"
            className="hover"
            checked={isItemSelected(contact)}
            onClick={(e) => {
              e.stopPropagation();
              onToggleSelectItem(contact);
            }}
          />
        </div>
      ),
      onCellClick: (contact) => onToggleSelectItem(contact),
      disableSort: true,
      disableSearch: true,
      noInternalCellPadding: true,
      width: 50,
    },
    {
      key: "status",
      label: "Status",
      width: 200,
      cellRenderer: (contact) => {
        const color =
          STATUS.find((s) => s?.options?.includes(contact.status))?.color ??
          "primary";
        return <Badge bg={color}>{translate(snakeCase(contact.status))}</Badge>;
      },
      searchOptions: GROUPPED_STATUS(translate),
      isGroupedDropdown: true,
    },
    {
      key: "followupDate",
      label: "Follow up Date",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      width: 160,
      cellColor: (contact) =>
        isDatePast(contact.followupDate) ? "danger-light" : "white",
    },
    {
      key: "leadSource",
      label: "lead source",
      cellRenderer: (contact) => translate(contact?.leadSource),
      width: 100,
      searchOptions: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
    },
    {
      key: "lastInboundSource",
      label: "Last Inbound Source",
      cellRenderer: (contact) => {
        const latestInbound = contact.inbounds?.sort(
          (i1, i2) => new Date(i2.date) - new Date(i1.date)
        )[0];

        if (latestInbound?.source === "Facebook Leadform")
          return <span>{latestInbound?.sourceDetails?.campaignName}</span>;
        else if (latestInbound?.source === "Website") {
          const urlToShow = truncateAndAddEllipsis(
            removeParamsFromUrl(latestInbound?.sourceDetails?.page_url) ?? "",
            30
          );
          return urlToShow;
        } else
          return (
            translate(snakeCase(latestInbound?.source)) || latestInbound?.source
          );
      },
      width: 200,
      disableSort: true,
      searchOptions: [
        ...inboundCampaignUrlOptions?.map((iS) => ({ label: iS, value: iS })),
        ...INBOUND_SOURCE.map((s) => ({
          label: translate(snakeCase(s)) || s,
          value: s,
        })),
      ],
      isMultiSelect: true,
      hide: !showAllFields,
    },
    {
      key: "lastInboundDate",
      label: "Last Inbound Date",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      valueSelector: (contact) => {
        const latestInbound = contact.inbounds?.sort(
          (i1, i2) => new Date(i2.date) - new Date(i1.date)
        )[0];

        return latestInbound?.date;
      },
      width: 160,
    },
    {
      key: "firstName",
      label: "First Name",
      type: "text",
      width: 120,
      highlight: true,
      highlightText: textToHighlight,
      cellRenderer: (contact) => (
        <div>
          <h6 style={{ fontSize: 16 }} className="mb-0">
            <TextHighlighter
              queries={textToHighlight}
              text={contact.firstName || "-"}
            />
          </h6>
          {contact.spouseFirstName && (
            <h6 style={{ fontSize: 16 }} className="mb-0 mt-1">
              <TextHighlighter
                queries={textToHighlight}
                text={contact.spouseFirstName || "-"}
              />
            </h6>
          )}
        </div>
      ),
    },
    {
      key: "lastName",
      label: "Last Name",
      type: "text",
      width: 120,
      highlight: true,
      highlightText: textToHighlight,
      cellRenderer: (contact) => (
        <div>
          <h6 style={{ fontSize: 16 }} className="mb-0">
            <TextHighlighter
              queries={textToHighlight}
              text={contact.lastName || "-"}
            />
          </h6>
          {contact.spouseLastName && (
            <h6 style={{ fontSize: 16 }} className="mb-0 mt-1">
              <TextHighlighter
                queries={textToHighlight}
                text={contact.spouseLastName || "-"}
              />
            </h6>
          )}
        </div>
      ),
    },

    {
      key: "phoneNumbers",
      label: "Phone Number",
      type: "phone",
      valueSelector: (contact) => contact.phoneNumbers?.[0],
      width: 140,
      disableSort: true,
      highlight: true,
      highlightText: textToHighlight,
    },
    {
      key: "spousePhoneNumbers",
      label: "Spouse Phone Number",
      type: "phone",
      valueSelector: (contact) => contact.spousePhoneNumbers?.[0],
      width: 140,
      disableSort: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "description",
      label: "description",
      type: "text",
      width: 160,
      disableSort: true,
      valueSelector: (contact) => {
        return contact?.description;
      },
      truncate: true,
      highlight: true,
      highlightText: textToHighlight,
    },
    {
      key: "comments",
      label: "Comment",
      type: "text",
      width: 160,
      disableSort: true,
      valueSelector: (contact) => {
        contact.comments.sort(
          (a, b) => new Date(b["date"]) - new Date(a["date"])
        );
        return contact.comments?.[0]?.text;
      },
      truncate: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "tags",
      label: "Tags",
      width: 160,
      disableSort: true,
      cellRenderer: (contact) => (
        <div>
          {contact?.tags?.map((tag) => (
            <Badge className="ms-1" key={tag}>
              {tag}
            </Badge>
          ))}
        </div>
      ),
      hide: !showAllFields,
    },
    {
      key: "emails",
      label: "Emails",
      type: "email",
      valueSelector: (contact) => contact.emails?.[0],
      width: 140,
      disableSort: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "salesperson",
      label: "sales person",
      cellRenderer: (contact) => {
        const { salesperson = [] } = contact;
        return (
          <div className="d-flex gap-1 justify-content-center align-items-center flex-wrap">
            {[...new Map(salesperson?.map((s) => [s?._id, s])).values()].map(
              (s) => {
                let backgroundColor = s?.color || "#007f7f";
                let textColor = getTextColor(backgroundColor);
                return (
                  <div
                    style={{ background: backgroundColor, color: textColor }}
                    className="rounded p-1"
                    key={s?._id}
                  >
                    <h6 className="tiny mb-0">{s?.name}</h6>
                  </div>
                );
              }
            )}
          </div>
        );
      },
      width: 200,
      disableSort: true,
      searchOptions: users
        ?.filter((u) => u?.role === SALES_ROLE)
        ?.map((u) => ({ label: u.name, value: u?._id })),
      hide: !showAllFields,
    },
    {
      key: "financialStrategist",
      label: "financial strategist",
      cellRenderer: (contact) => {
        const { financialStrategist = [] } = contact;
        return (
          <div className="d-flex gap-1 justify-content-center align-items-center flex-wrap">
            {[
              ...new Map(financialStrategist?.map((f) => [f?._id, f])).values(),
            ]?.map((f) => {
              let backgroundColor = f?.color || "#007f7f";
              let textColor = getTextColor(backgroundColor);
              return (
                <div
                  style={{ background: f?.color, color: textColor }}
                  className="rounded p-1"
                  key={f?._id}
                >
                  <h6 className="tiny mb-0">{f?.name}</h6>
                </div>
              );
            })}
          </div>
        );
      },
      width: 200,
      disableSort: true,
      searchOptions: users
        ?.filter((u) => u?.role === FINANCIAL_STRATEGIST_ROLE)
        ?.map((u) => ({ label: u.name, value: u?._id })),
      hide: !showAllFields,
    },
    {
      key: "realEstateAnalyst",
      label: "real Estate Analyst",
      cellRenderer: (contact) => {
        const { realEstateAnalyst = [] } = contact;
        return (
          <div className="d-flex gap-1 justify-content-center align-items-center flex-wrap">
            {[
              ...new Map(realEstateAnalyst?.map((r) => [r?._id, r])).values(),
            ]?.map((r) => {
              let backgroundColor = r?.color || "#007f7f";
              let textColor = getTextColor(backgroundColor);
              return (
                <div
                  style={{ background: r?.color, color: textColor }}
                  className="rounded p-1"
                  key={r?._id}
                >
                  <h6 className="tiny mb-0">{r?.name}</h6>
                </div>
              );
            })}
          </div>
        );
      },
      width: 200,
      disableSort: true,
      searchOptions: users
        ?.filter((u) => u?.role === REAL_ESTATE_ANALYST_ROLE)
        ?.map((u) => ({ label: u.name, value: u?._id })),
      hide: !showAllFields,
    },
    {
      key: "referredBy",
      label: "referrer",
      cellRenderer: (contact) => {
        const { referredBy } = contact;
        return (
          referredBy && (
            <div className="rounded bg-primary p-1">
              <h6 className="tiny text-white mb-0">{referredBy?.name}</h6>
            </div>
          )
        );
      },
      width: 100,
      disableSort: true,
      hide: !showAllFields,
    },
    {
      key: "surveySent",
      label: "survey_sent",
      cellRenderer: (contact) => (
        <h6 style={{ font: 14 }} className="mb-0">
          {contact?.surveySent ? "Yes" : "No"}
        </h6>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "surveyCompleted",
      label: "survey_completed",
      cellRenderer: (contact) => (
        <h6 style={{ font: 14 }} className="mb-0">
          {contact?.surveyCompleted ? "Yes" : "No"}
        </h6>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
  ];

  return allLabels.filter((l) => !l?.hide);
};

export const getEventTableColumns = (
  onEditEventClick,
  onDeleteEventClick,
  users,
  translate
) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "date",
      label: "Event Date",
      type: "date",
      width: 140,
    },
    {
      key: "facebookCampaigns",
      label: "Facebook Campaigns",
      cellRenderer: (event) =>
        event.facebookCampaigns.map((u) => (
          <Badge bg="dark" className="mx-1">
            {u.name}
          </Badge>
        )),
      type: "text",
      width: 200,
    },
    {
      key: "urls",
      label: "Urls",
      cellRenderer: (event) =>
        event.urls.map((u) => (
          <Badge bg="dark" className="mx-1">
            {u}
          </Badge>
        )),
      type: "text",
      width: 200,
    },
    {
      key: "activeTraillistName",
      label: "Active Trail List Name",
      type: "text",
      width: 160,
    },
    {
      key: "leadSource",
      label: "lead source",
      cellRenderer: (automation) => translate(automation?.leadSource),
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (event) => {
        let statusColor =
          STATUS.find((s) => s.options.includes(event.status))?.color ||
          "primary";
        return (
          <div className={`rounded p-1 text-white bg-${statusColor}`}>
            <h6 className="tiny mb-0"> {event.status}</h6>
          </div>
        );
      },
    },
    {
      key: "salespersons",
      label: "Sales person",
      cellRenderer: (event) => {
        const salespersons = users?.filter((user) =>
          event?.salespersons?.includes(user?._id)
        );
        return (
          <div className="d-flex justify-content-center align-items-center flex-wrap gap-1">
            {salespersons?.map((u) => {
              let backgroundColor = u?.color || "#007f7f";
              let textColor = getTextColor(backgroundColor);
              return (
                <div
                  style={{ color: textColor, backgroundColor: backgroundColor }}
                  className="w-auto rounded p-1"
                >
                  <h6 className="tiny mb-0"> {u.name}</h6>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      key: "view",
      cellRenderer: (event) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditEventClick(event)}
          />
          <Trash
            className="text-dark hover-light mx-1"
            onClick={() => onDeleteEventClick(event)}
          />
        </>
      ),
    },
  ];

  return allLabels;
};

export const getAutomationsTableColumns = (
  onEditAutomationClick,
  onDeleteAutomationClick,
  users,
  translate
) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "facebookCampaigns",
      label: "Facebook Campaigns",
      cellRenderer: (automation) =>
        automation.facebookCampaigns.map((u) => (
          <Badge bg="dark" className="mx-1">
            {u.name}
          </Badge>
        )),
      type: "text",
      width: 200,
    },
    {
      key: "urls",
      label: "Urls",
      cellRenderer: (automation) =>
        automation.urls.map((u) => (
          <Badge bg="dark" className="mx-1">
            {u}
          </Badge>
        )),
      type: "text",
      width: 200,
    },
    {
      key: "activeTraillistName",
      label: "Active Trail List Name",
      type: "text",
      width: 160,
    },
    {
      key: "leadSource",
      label: "lead source",
      cellRenderer: (automation) => translate(automation?.leadSource),
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (automation) => {
        let statusColor =
          STATUS.find((s) => s.options.includes(automation.status))?.color ||
          "primary";
        return (
          <div className={`rounded p-1 text-white bg-${statusColor}`}>
            <h6 className="tiny mb-0"> {automation.status}</h6>
          </div>
        );
      },
    },
    {
      key: "salespersons",
      label: "Sales person",
      cellRenderer: (automation) => {
        const salespersons = users?.filter((user) =>
          automation?.salespersons?.includes(user?._id)
        );
        return (
          <div className="d-flex justify-content-center align-items-center flex-wrap gap-1">
            {salespersons?.map((u) => {
              let backgroundColor = u?.color || "#007f7f";
              let textColor = getTextColor(backgroundColor);
              return (
                <div
                  style={{ color: textColor, backgroundColor: backgroundColor }}
                  className="w-auto rounded p-1"
                >
                  <h6 className="tiny mb-0"> {u.name}</h6>
                </div>
              );
            })}
          </div>
        );
      },
    },

    {
      key: "view",
      cellRenderer: (automation) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditAutomationClick(automation)}
          />
          <Trash
            className="text-dark hover-light mx-1"
            onClick={() => onDeleteAutomationClick(automation)}
          />
        </>
      ),
    },
  ];

  return allLabels;
};

export const getProductTableColumns = (
  onEditProductClick,
  onDeleteProductClick,
  onEditProductStageClick
) => {
  const allLabels = [
    {
      key: "description",
      label: "Contract title",
      type: "text",
      width: 160,
    },

    {
      key: "view",
      label: "Action",
      cellRenderer: (product) => (
        <>
          <ListTask
            className="text-dark hover-light mx-2"
            onClick={() => onEditProductStageClick(product)}
          />
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditProductClick(product)}
          />
          <Trash
            className="text-dark hover-light mx-2"
            onClick={() => onDeleteProductClick(product)}
          />
        </>
      ),
    },
  ];

  return allLabels;
};

export const getContractsTableColumns = (
  onEditContractClick,
  onDeleteContractClick,
  products
) => {
  const allLabels = [
    {
      key: "description",
      label: "Name",
      cellRenderer: (contract) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <h6 style={{ width: 200 }} className="mb-0 smallFont truncate">
            {contract?.description}
          </h6>
        </div>
      ),
      width: 200,
    },
    {
      key: "price",
      label: "Price",
      type: "number",
      width: 100,
    },
    {
      key: "templateLink",
      label: "Template Link",
      type: "text",
      width: 200,
    },
    {
      key: "products",
      label: "Products",
      cellRenderer: (contract) => (
        <div className="d-flex align-items-center justify-content-center flex-wrap">
          {contract?.units?.map((unit) => {
            const product = products?.find((pr) => pr?._id === unit);
            return (
              <Badge bg={"dark"} className="mx-1">
                {product?.description}
              </Badge>
            );
          })}
        </div>
      ),
      width: 300,
    },
    {
      key: "view",
      label: "Action",
      width: 100,
      cellRenderer: (contract) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditContractClick(contract)}
          />
          <Trash
            className="text-dark hover-light mx-2"
            onClick={() => onDeleteContractClick(contract)}
          />
        </>
      ),
    },
  ];

  return allLabels;
};
const generateSearchOrderForProductionStage = (products) => {
  let result = [];

  products.forEach((product) => {
    let options = [];
    options = product?.productionStages?.map((pS) => ({
      label: pS?.step,
      value: pS?._id,
    }));

    result.push({
      label: product?.description,
      key: product?.description,
      options,
    });
  });
  return result;
};

export const getDealsTableColumns = (
  user,
  columnToHide = [],
  users,
  products,
  onToggleSelectAll,
  onToggleSelectItem,
  isItemSelected,
  selectAllMode,
  translate
) => {
  const allLabels = [
    {
      key: "view",
      labelRenderer: (field) => (
        <input
          type="checkbox"
          className="hover"
          checked={selectAllMode}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectAll(e.target.checked);
          }}
        />
      ),
      cellRenderer: (contract) => (
        <input
          type="checkbox"
          className="hover"
          checked={isItemSelected(contract)}
          onClick={(e) => {
            e.stopPropagation();
            onToggleSelectItem(contract);
          }}
        />
      ),
      onCellClick: (contact) => onToggleSelectItem(contact),
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (contract) => {
        const { status } = contract;
        const color = dealStatusToColor[status] || "primary";
        return <Badge bg={color}>{translate(snakeCase(status))}</Badge>;
      },
      searchOptions: DEAL_STATUSES?.map((s) => ({ label: s, value: s })),
      width: 40,
    },
    {
      key: "product",
      label: "Contract",
      cellRenderer: (contract) => {
        const { product } = contract;

        if (!product) return "N/A";
        return contract?.prosperoProposalMeta?.link ? (
          <a
            href={contract?.prosperoProposalMeta?.link}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {product?.description}{" "}
          </a>
        ) : (
          <span className="truncate">{product?.description}</span>
        );
      },
      disableSort: true,
      searchOptions: products.map((product) => ({
        label: product?.description,
        value: product?._id,
      })),
      width: 80,
    },
    {
      key: "client",
      label: "Client",
      width: 80,
      cellRenderer: (contract) => {
        const { contact } = contract;
        if (!contact) return "N/A";
        return <span>{`${contact?.firstName} ${contact?.lastName}`} </span>;
      },
      disableSort: true,
    },
    {
      key: "proposalQuote",
      label: "Deal",
      type: "number",
      hide: !isAdminOrManager(user?.role),
      width: 60,
      valueSelector: (deal) => formatCurrency(deal?.proposalQuote),
      disableSearch: true,
    },
    {
      key: "payment",
      label: "Payment",
      hide: !isAdminOrManager(user?.role),
      width: 80,
      cellRenderer: (contract) => {
        const { payments } = contract;
        return (
          <span dir="ltr">
            {formatCurrency(payments?.reduce((a, c) => a + c.amount, 0))}
          </span>
        );
      },
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "assignedTo",
      label: "Assigned To",
      cellRenderer: (contract) => {
        return contract?.assignedTo?.map((user) => (
          <Badge className="me-1 px-1 py-1">{user?.name}</Badge>
        ));
      },
      searchOptions: users.map((user) => ({
        label: user.name,
        value: user._id,
      })),
      disableSort: true,
    },
    {
      key: "tags",
      label: "Tags",
      width: 160,
      disableSort: true,
      cellRenderer: (contract) => (
        <div>
          {contract?.tags?.map((tag) => (
            <Badge className="ms-1" key={tag}>
              {tag}
            </Badge>
          ))}
        </div>
      ),
    },
    {
      key: "signedOn",
      label: "Contract Signed On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
    {
      key: "createdAt",
      label: "Contract Sent On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
  ].filter((f) => !f?.hide);

  return allLabels.filter((label) => !columnToHide.includes(label?.key));
};

export const getCommissionsTableColumns = ({
  columnToHide,
  users,
  products,
  onToggleSelectAll,
  onToggleSelectItem,
  isItemSelected,
  selectAllMode,
  translate,
  referredBy = null,
}) => {
  const referredByMode = Boolean(referredBy);
  const allLabels = [
    {
      key: "view",
      labelRenderer: (field) => (
        <input
          type="checkbox"
          className="hover"
          checked={selectAllMode}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectAll(e.target.checked);
          }}
        />
      ),
      cellRenderer: (contract) => (
        <input
          type="checkbox"
          className="hover"
          checked={isItemSelected(contract)}
          onClick={(e) => {
            e.stopPropagation();
            onToggleSelectItem(contract);
          }}
        />
      ),
      onCellClick: (contract) => onToggleSelectItem(contract),
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (contract) => {
        const { status } = contract;
        const color = dealStatusToColor[status] || "primary";
        return <Badge bg={color}>{translate(snakeCase(status))}</Badge>;
      },
      searchOptions: DEAL_STATUSES?.map((s) => ({ label: s, value: s })),
      width: 40,
    },
    {
      key: "product",
      label: "Contract",
      cellRenderer: (contract) => {
        const { product } = contract;

        if (!product) return "N/A";
        return contract?.prosperoProposalMeta?.link ? (
          <a
            href={contract?.prosperoProposalMeta?.link}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {product?.description}{" "}
          </a>
        ) : (
          <span className="truncate">{product?.description}</span>
        );
      },
      disableSort: true,
      searchOptions: products.map((product) => ({
        label: product?.description,
        value: product?._id,
      })),
      width: 80,
    },
    {
      key: "client",
      label: "Client",
      width: 80,
      cellRenderer: (contract) => {
        const { contact } = contract;

        return contact ? (
          <a
            href={
              process.env.PUBLIC_URL +
              `/contacts?contact=${contact?._id}&view=contact`
            }
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {`${contact?.firstName} ${contact?.lastName}`}
          </a>
        ) : (
          <span className="truncate">N/A</span>
        );
      },
      disableSort: true,
    },
    {
      key: "referredBy",
      label: "Referred by",
      width: 60,
      hide: referredByMode,
      valueSelector: (deal) => deal?.contact?.referredBy?.name,
    },
    {
      key: "proposalQuote",
      label: "Deal",
      type: "number",
      width: 60,
      valueSelector: (deal) => formatCurrency(deal?.proposalQuote),
      disableSearch: true,
    },
    {
      key: "payment",
      label: "Payment",
      width: 80,
      cellRenderer: (contract) => {
        const { payments } = contract;
        return (
          <span dir="ltr">
            {formatCurrency(payments?.reduce((a, c) => a + c.amount, 0))}
          </span>
        );
      },
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "referredPaidAmount",
      label: "Referrer paid amount",
      width: 80,
      cellRenderer: (contract) => {
        const { commissions } = contract;

        return (
          <span dir="ltr">
            {formatCurrency(
              commissions?.reduce(
                (a, c) =>
                  a +
                  (c?.type === "Referral" && c?.commissionPaidDate
                    ? c.commission
                    : 0),
                0
              )
            )}
          </span>
        );
      },
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "assignedTo",
      label: "Assigned To",
      cellRenderer: (contact) => {
        return contact?.assignedTo?.map((user) => (
          <Badge className="me-1 px-1 py-1">{user?.name}</Badge>
        ));
      },
      searchOptions: users.map((user) => ({
        label: user.name,
        value: user._id,
      })),
      disableSort: true,
    },
    // {
    //   key: "productionStage",
    //   label: "Production Stage",
    //   cellRenderer: (contract) => {
    //     return <span>{contract?.productionStage?.step}</span>;
    //   },
    //   searchOptions: generateSearchOrderForProductionStage(products),
    //   disableSort: true,
    // },
    {
      key: "signedOn",
      label: "Contract Signed On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
    {
      key: "createdAt",
      label: "Contract Sent On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
  ];

  return allLabels.filter(
    (label) => !label?.hide && !columnToHide.includes(label?.key)
  );
};

export const getLogTableColumns = ({
  user,
  translate,
  users,
  onActionClick,
}) => {
  const logTypeToColor = {
    multiUpdate: "primary",
  };
  const allLabels = [
    {
      key: "type",
      label: "Type",
      cellRenderer: (log) => {
        const { type } = log;
        const color = logTypeToColor[type] || "primary";
        return <Badge bg={color}>{translate(snakeCase(type)) || type}</Badge>;
      },
      searchOptions: LOG_TYPE?.map((s) => ({ label: s, value: s })),
      width: 40,
    },
    {
      key: "user",
      label: "affected_documents",
      disableSearch: true,
      disableSort: true,
      cellRenderer: (log) => {
        const { atomicActions } = log;

        const collectionCounts = atomicActions.reduce((acc, action) => {
          if (LOG_COLLECTIONS.includes(action.collection)) {
            acc[action.collection] = (acc[action.collection] || 0) + 1;
          }
          return acc;
        }, {});

        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            {Object.keys(collectionCounts).map((c) => (
              <h6 key={c} className="mb-0 smallFont">
                {collectionCounts[c]} {c}
              </h6>
            ))}
          </div>
        );
      },
    },
    {
      key: "createdAt",
      label: "Date time",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
    {
      key: "user",
      label: "User",
      cellRenderer: (log) => {
        const user = users.find((u) => u._id === log.user);
        let backgroundColor = user?.color || "#007f7f";
        let textColor = getTextColor(backgroundColor);
        return (
          <div
            style={{
              color: textColor,
              backgroundColor: backgroundColor,
              width: "fit-content",
            }}
            className="mx-auto rounded p-1"
          >
            <h6 className="tiny mb-0"> {user?.name}</h6>
          </div>
        );
      },
    },
    {
      key: "action",
      label: "Action",
      disableSearch: true,
      disableSort: true,
      cellRenderer: (log) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          {log.undone ? (
            <Button
              className="d-flex align-items-center gap-2 justify-content-center"
              size="sm"
              variant="outline-primary"
              disabled={!log.undone}
              onClick={(e) => {
                e.stopPropagation();
                onActionClick({
                  logId: log._id,
                  action: "redo",
                  log,
                });
              }}
            >
              <Arrow90degRight className="" size={10} />{" "}
              <h6 className="mb-0 tiny">Redo</h6>
            </Button>
          ) : (
            <Button
              className="d-flex align-items-center gap-2 justify-content-center"
              size="sm"
              variant="outline-primary"
              disabled={log.undone}
              onClick={(e) => {
                e.stopPropagation();
                onActionClick({
                  logId: log._id,
                  action: "undo",
                  log,
                });
              }}
            >
              <Arrow90degLeft className="" size={10} />{" "}
              <h6 className="mb-0 tiny">Undo</h6>
            </Button>
          )}
        </div>
      ),
      width: 160,
    },
  ];

  return allLabels.filter((label) => !label?.hide);
};
export const getUserTableColumns = (
  translate,
  onUserDeleteClick,
  sendPasswordResetLink,
  refreshUser
) => {
  const allLabels = [
    {
      key: "color",
      label: "Color",
      cellRenderer: (user) => (
        <div
          style={{
            background: user?.color ?? "#ffffff",
            height: 20,
            width: 30,
            border: 5,
          }}
        />
      ),
      width: 40,
    },
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "email",
      label: "Email",
      type: "text",
      width: 100,
    },
    {
      key: "emails",
      label: "Secondary Emails",
      cellRenderer: (user) => user?.secondaryEmails?.join(", "),
      width: 100,
    },
    {
      key: "phone",
      label: "Phone Number",
      type: "text",
      width: 100,
    },
    {
      key: "role",
      label: "Role",
      cellRenderer: (user) => (
        <Badge
          bg={
            user?.role === ADMIN_ROLE
              ? "dark"
              : user?.role === SALES_ROLE
              ? "success"
              : user?.role === FINANCIAL_STRATEGIST_ROLE
              ? "info"
              : user?.role === REAL_ESTATE_ANALYST_ROLE
              ? "purple"
              : "primary"
          }
          className="mx-1"
        >
          {user.role}
        </Badge>
      ),
      type: "text",
      width: 100,
    },
    {
      key: "role",
      label: "Permissions",
      cellRenderer: (user) =>
        isAdminOrManager(user?.role) ? (
          "-"
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column gap-1">
            <Badge
              bg={"dark"}
              className="d-flex justify-content-between align-items-center gap-1"
            >
              <div
                className="bg-white text-dark d-flex align-items-center justify-content-center tiny px-1 rounded"
                style={{ height: 12, width: "fit-content" }}
              >
                CRM
              </div>{" "}
              <h6 className="mb-0 tiny"> {user?.specialRoles?.crm}</h6>
            </Badge>{" "}
            <Badge bg={"primary"} className="d-flex align-items-center gap-1">
              <div
                className="bg-white text-dark d-flex align-items-center justify-content-center tiny px-1 rounded"
                style={{ height: 14, width: "fit-content" }}
              >
                <img height={12} src={RujumIcon} alt="Rujum" className="mx-1" />{" "}
              </div>{" "}
              <h6 className="mb-0 tiny"> {user?.specialRoles?.app}</h6>
            </Badge>
          </div>
        ),
      type: "text",
      width: 150,
    },

    {
      key: "zoom",
      label: "Zoom",
      cellRenderer: (user) =>
        user?.zoomUserId ? (
          <Badge bg={"success"} className="mx-1">
            {translate("connected")}
          </Badge>
        ) : (
          <UnderlineButton
            onClick={() => refreshUser(user)}
            variant="dark"
            text={translate("connect")}
          />
        ),
      type: "text",
      width: 80,
    },
    {
      key: "view",
      label: "Action",
      cellRenderer: (user) => (
        <div className="d-flex justify-content-center align-items-center">
          <Link
            onClick={(e) => e.stopPropagation()}
            title="View CRM"
            to={`${CRM_APP_URL}/contacts?as=${user._id}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className="mx-1 bg-dark text-white d-flex align-items-center justify-content-center tiny px-1 rounded"
              style={{ height: 12, width: "fit-content" }}
            >
              CRM
            </div>{" "}
          </Link>
          <Link
            onClick={(e) => e.stopPropagation()}
            title="View Rujum"
            to={`${RUJUM_APP_URL}/admin/manage-users/customer?as=${user.email}`}
            target="_blank"
            className="mx-1"
            style={{ textDecoration: "none" }}
          >
            <img height={15} src={RujumIcon} alt="Rujum" />{" "}
          </Link>
          <Trash
            className="text-dark hover hover-light mx-1"
            size={12}
            onClick={(e) => {
              onUserDeleteClick(user);
              e.stopPropagation();
            }}
          />
          <EnvelopeAt
            className="text-dark hover-light mx-1"
            size={12}
            onClick={(e) => {
              sendPasswordResetLink(user);
              e.stopPropagation();
            }}
          />{" "}
        </div>
      ),
      width: 160,
    },
  ];
  return allLabels;
};

export const getReferrerTableColumns = (onReferrerDeleteClick) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "email",
      label: "Email",
      type: "text",
      width: 100,
    },
    {
      key: "phone",
      label: "Phone Number",
      type: "text",
      width: 100,
    },
    {
      key: "notes",
      label: "Notes",
      type: "text",
      width: 160,
    },
    {
      key: "view",
      label: "Action",
      cellRenderer: (user) => (
        <div className="d-flex justify-content-center align-items-center">
          <Trash
            className="text-dark hover hover-light mx-1"
            size={12}
            onClick={(e) => {
              onReferrerDeleteClick(user);
              e.stopPropagation();
            }}
          />
        </div>
      ),
      width: 160,
    },
  ];
  return allLabels;
};

export const getInboundTableColumns = ({
  onDetailsClick,
  onCampaignClick,
  columnToHide = [],
}) => {
  const allLabels = [
    {
      key: "contact",
      label: "Contact",
      valueSelector: (inbound) =>
        inbound.contact.firstName + " " + inbound.contact.lastName,
      width: 70,
    },
    {
      key: "email",
      label: "Email",
      valueSelector: (inbound) => inbound.contact.emails[0],
      width: 70,
    },
    {
      key: "source",
      label: "Source",
      width: 70,
    },
    {
      key: "campaign",
      label: "Campaign/url",
      cellRenderer: (inbound) => {
        return (
          <>
            {inbound?.source === "Website" ? (
              <a
                href={
                  hasHttpOrHttps(inbound?.sourceDetails?.page_url)
                    ? inbound?.sourceDetails?.page_url
                    : `//${inbound?.sourceDetails?.page_url}`
                }
                target="_blank"
              >
                {truncateAndAddEllipsis(
                  removeParamsFromUrl(inbound?.sourceDetails?.page_url) ?? "",
                  30
                )}
              </a>
            ) : inbound?.source === "Manually Added" ? (
              <span className="">
                {inbound?.sourceDetails?.added_by || "-"}
              </span>
            ) : (
              <span
                className="a text-info"
                onClick={() => onCampaignClick(inbound)}
              >
                {inbound?.sourceDetails?.campaignName || "-"}
              </span>
            )}
          </>
        );
      },
      width: 100,
    },
    {
      key: "date",
      label: "Date",
      cellRenderer: (inbound) =>
        moment(inbound?.date).format(DEFAULT_DATE_TIME_FORMAT),
      width: 100,
    },
    {
      key: "details",
      label: "Details",
      cellRenderer: (inbound) => {
        if (
          !inbound?.sourceDetails ||
          !Object.keys(inbound?.sourceDetails).length
        ) {
          return "-";
        }

        return (
          <Button
            className="px-1 py-0"
            variant="info"
            size="sm"
            onClick={() => onDetailsClick(inbound)}
          >
            <span className="smallFont">View</span>
          </Button>
        );
      },
      width: 100,
    },
  ];
  return allLabels?.filter((f) => !columnToHide.includes(f?.key));
};

export const getPhoneCallTableColumns = (onPlayClick, columnToHide = []) => {
  const allLabels = [
    {
      key: "play",
      label: "Play",
      width: 50,
      cellRenderer: (call) => (
        <PlayCircleFill
          size={18}
          className="text-primary"
          onClick={() => onPlayClick(call)}
        />
      ),
    },
    {
      key: "createdAt",
      label: "time of the call",
      cellRenderer: (call) =>
        moment(call.callDateTime || call.createdAt).format(
          DEFAULT_DATE_TIME_FORMAT
        ),
      width: 100,
    },
    {
      key: "callType",
      label: "Call type",
      cellRenderer: (call) => {
        let { callType = null, duration = 0, status } = call;

        let isMissedCall = status ? status === "unanswered" : duration === 0;
        if (callType?.toLowerCase().includes("outgoing")) {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <TelephoneOutboundFill className="text-info" />{" "}
              <span className="mx-2">
                Outgoing {isMissedCall && "- Unanswered"}{" "}
              </span>
            </div>
          );
        }

        if (callType?.toLowerCase().includes("incoming")) {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <TelephoneInboundFill className="text-primary" />{" "}
              <span className="mx-2">
                Incoming {isMissedCall && "- Unanswered"}
              </span>
            </div>
          );
        }

        return (
          <div className="d-flex justify-content-center align-items-center">
            <TelephoneFill className="text-primary" />{" "}
            <span className="mx-2">{callType}</span>
          </div>
        );
      },
      width: 100,
    },
    {
      key: "receiverNumber",
      label: "receiver",
      cellRenderer: (call) => call?.receiver?.name || call?.receiverNumber,
      width: 100,
    },
    {
      key: "caller",
      label: "caller",
      cellRenderer: (call) => call?.caller?.name || call?.callerNumber,
      width: 100,
    },
    {
      key: "duration",
      label: "call length",
      width: 100,
      cellRenderer: (call) => {
        let duration = moment.duration(Number(call?.duration || 0) * 1000);

        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return `${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
      },
    },
  ];
  return allLabels?.filter((f) => !columnToHide.includes(f?.key));
};

export const getAppointmetnDefaultTypeColumns = (
  onEditAppointmentTypeClick
) => {
  const allLabels = [
    {
      key: "key",
      label: "Type",
      type: "text",
      width: 100,
    },
    {
      key: "title",
      label: "Title",
      type: "text",
      width: 100,
    },
    {
      key: "description",
      label: "Description",
      type: "text",
      width: 140,
    },
    {
      key: "duration",
      label: "Duration",
      type: "text",
      width: 60,
    },

    {
      key: "view",
      label: "Action",
      cellRenderer: (appointmentType) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditAppointmentTypeClick(appointmentType)}
          />
        </>
      ),
    },
  ];
  return allLabels;
};

export const getTagsColumns = (onEditTagClick, onDeleteTagClick) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },

    {
      key: "view",
      label: "Action",
      cellRenderer: (tag) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditTagClick(tag)}
          />

          <Trash
            className="text-dark hover mx-1"
            onClick={() => onDeleteTagClick(tag)}
          />
        </>
      ),
    },
  ];
  return allLabels;
};

export const getDefaultStatusColumns = (onEditDefaultStatusClick) => {
  const allLabels = [
    {
      key: "inboundSource",
      label: "Inbound Source",
      type: "text",
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      type: "text",
      width: 100,
    },
    {
      key: "exceptions",
      label: "Exception",

      cellRenderer: (status) => (
        <div className="" style={{ textAlign: "left" }}>
          {status?.exceptions?.map((eP, index) => (
            <h6 key={index} className="smallFont mb-0">
              - {eP.exception}
            </h6>
          ))}
        </div>
      ),
    },

    {
      key: "view",
      label: "Action",
      cellRenderer: (status) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditDefaultStatusClick(status)}
          />
        </>
      ),
    },
  ];
  return allLabels;
};

export const getDashboardSalesWonColumns = (users) => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (sale) =>
        `${sale?.contact?.firstName || ""} ${sale?.contact?.lastName || ""}`,
      width: 100,
    },
    {
      key: "product",
      label: "product",
      valueSelector: (sale) => sale?.product?.description,
      width: 100,
    },
    {
      key: "proposalQuote",
      label: "deal value",
      cellRenderer: (sale) => formatCurrency(sale?.proposalQuote),
      width: 140,
    },
    {
      key: "signedOn",
      label: "deal date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      type: "date",
      width: 60,
    },

    {
      key: "assignedTo",
      label: "Sales person",
      cellRenderer: (meeting) =>
        meeting?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};
export const getDashboardNewMeetingColumns = (users) => {
  const allLabels = [
    {
      key: "appointmentType",
      label: "meeting type",
      type: "text",
      width: 100,
    },
    {
      key: "title",
      label: "meeting title",
      cellRenderer: (meeting) => {
        const videoUrl = getAppointmentVideo(meeting);
        return (
          <div className="d-flex gap-2 justify-content-between">
            <h6 className="mb-0 smallFont truncate" style={{ maxWidth: 200 }}>
              {meeting?.title}
            </h6>
            {videoUrl && (
              <PlayCircleFill
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(videoUrl, "_blank");
                }}
              />
            )}
          </div>
        );
      },
      width: 100,
    },
    {
      key: "createdAt",
      label: "meeting setting date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      type: "date",
      width: 140,
    },
    {
      key: "meetingScheduleDate",
      label: "meeting schedule date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      valueSelector: (meeting) => meeting?.startDateTime,
      type: "date",
      width: 60,
    },

    {
      key: "meetingParticipantsUsers",
      label: "meeting participants users",
      cellRenderer: (meeting) =>
        meeting?.participants?.map((u) => (
          <Badge className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
    {
      key: "meetingParticipantsClients",
      label: "meeting participants clients",
      valueSelector: (meeting) => meeting?.invitees?.join(", "),
      width: 60,
    },
    {
      key: "description",
      label: "meeting description",
      truncate: true,
      type: "text",
      width: 60,
    },
  ];
  return allLabels;
};
export const getDashboardIncomeColumns = (users) => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (income) =>
        `${income?.contract?.contact?.firstName || ""} ${
          income?.contract?.contact?.lastName || ""
        }`,
      width: 100,
    },
    {
      key: "amount",
      label: "value",
      cellRenderer: (income) => formatCurrency(income?.amount),
      width: 100,
    },
    {
      key: "date",
      label: "date",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      width: 160,
    },
    {
      key: "product",
      label: "product",
      valueSelector: (income) => income?.contract?.product?.description,
      width: 100,
    },
    {
      key: "salesperson",
      label: "assigned to",
      cellRenderer: (income) =>
        income?.contract?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};
export const getDashboardNewClientColumns = () => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (client) =>
        `${client?.firstName || ""} ${client?.lastName || ""}`,
      width: 100,
    },
    {
      key: "product",
      label: "product",
      valueSelector: (client) => client?.contract?.product?.description,
      width: 100,
    },
    {
      key: "dealValue",
      label: "deal value",
      cellRenderer: (client) => formatCurrency(client?.contract?.proposalQuote),
      width: 140,
    },
    {
      key: "dealDate",
      label: "deal date",
      valueSelector: (client) => client?.contract?.signedOn,
      type: "date",
      width: 60,
    },
    {
      key: "salesperson",
      label: "assigned to",
      cellRenderer: (client) =>
        client?.contract?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};
export const getDashboardTotalCommissionsFields = () => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (commission) =>
        `${commission?.contract?.contact?.firstName || ""} ${
          commission?.contract?.contact?.lastName || ""
        }`,
      width: 100,
    },
    {
      key: "product",
      label: "product",
      cellRenderer: (commission) =>
        `${commission?.contract?.product?.description}`,
      width: 100,
    },
    {
      key: "dealValue",
      label: "deal value",
      cellRenderer: (commission) =>
        formatCurrency(commission?.contract?.proposalQuote),
      width: 140,
    },
    {
      key: "commissionValue",
      label: "commission value",
      valueSelector: (commission) => commission?.commission,
      width: 60,
    },
    {
      key: "commissionPaymentData",
      label: "commission payment data",
      valueSelector: (commission) => commission?.commissionPaidDate,
      type: "date",
      width: 60,
    },

    {
      key: "salesperson",
      label: "assigned to",
      cellRenderer: (commission) =>
        commission?.contract?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};

export const getEventMarketing = (
  onEditEventClick,
  onDeleteEventClick,
  users
) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "date",
      label: "Event Date",
      type: "date",
      width: 140,
    },

    {
      key: "activeTraillistName",
      label: "Active Trail List Name",
      type: "text",
      width: 160,
    },

    {
      key: "registred_to_webinar_from_facebook",
      label: "registred to webinar from facebook",

      type: "text",
      width: 200,
    },
    {
      key: "total_registred_to_webinar",
      label: "registred to webinar total",
      type: "text",
      width: 200,
    },

    {
      key: "view",
      cellRenderer: (event) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditEventClick(event)}
          />
          <Trash
            className="text-dark hover-light mx-1"
            onClick={() => onDeleteEventClick(event)}
          />
        </>
      ),
    },
  ];

  return allLabels;
};

const linkForView = (contact) => {
  if (contact["_id"]) return `/contacts/${contact["_id"]}`;
  return null;
};
