import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as Yup from "yup";
import { WEBSITE } from "../../helpers/regex";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";
import ActiveTrailDropdown from "../automation-events/common/ActiveTrailDropdown";
import SalespersonDropdown from "../automation-events/common/SalespersonDropdown";
import StatusDropdown from "../automation-events/common/StatusDropdown";
import EditableTagsInput from "../common/EditableTagsInput";
import HorizontalProgress from "../common/HorizontalProgress";
import LeadSourceDropdown from "../automation-events/common/LeadSourceDropdown";
import FacebookCampaignDropdown from "../automation-events/common/FacebookCampaignDropdown";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide the event name"),
  date: Yup.date().required("Please provide the event date"),
  facebookCampaignIds: Yup.array().of(Yup.string()),
  salespersons: Yup.array().of(Yup.string()),
  activeTraillistId: Yup.string().required(
    "Please select an active trail list"
  ),
  urls: Yup.array().of(Yup.string().matches(WEBSITE, "Invalid website")),
  status: Yup.string().required("Status is required"),
});

const defaultValues = {
  name: "",
  date: "",
  facebookCampaignIds: [],
  activeTraillistId: "",
  urls: [],
  status: "",
  salespersons: [],
};

const AddEditEventForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
  facebookCampaignOptions = [],
  activeTrailListOptions = [],
}) => {
  const { translate } = useLocalization();
  const users = useAppChoices("users");
  const animatedComponents = makeAnimated();

  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        // Custom validation logic
        const hasValidCampaignsOrUrls =
          values.facebookCampaignIds?.length > 0 ||
          values.urls?.filter((url) => url !== "").length > 0;

        setSubmitting(false);
        if (!hasValidCampaignsOrUrls) {
          setFieldError(
            "urls",
            "You must provide at least one Facebook campaign or one URL."
          );
          return;
        }

        onSubmit(values);
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form className="p-2">
            <Row>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("name")}
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("event_date")}
                  </FormLabel>
                  <Field
                    name="date"
                    type="date"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("active_trail_list")}
                  </FormLabel>
                  <ActiveTrailDropdown
                    activeTrails={activeTrailListOptions.map((aT) => ({
                      label: aT.name,
                      value: aT.id,
                    }))}
                    value={String(values.activeTraillistId)}
                    onChange={(aT) => setFieldValue("activeTraillistId", aT)}
                  />

                  <ErrorMessage
                    name="activeTraillistId"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("status")}
                  </FormLabel>{" "}
                  <StatusDropdown
                    value={values.status}
                    onChange={(status) => setFieldValue("status", status)}
                  />
                  <ErrorMessage
                    name="status"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("lead_source")}
                  </FormLabel>
                  <LeadSourceDropdown
                    value={values.leadSource}
                    onChange={(leadSource) =>
                      setFieldValue("leadSource", leadSource)
                    }
                  />
                  {errors["leadSource"] && (
                    <div className="text-danger smallFont">
                      {errors["leadSource"]}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("websites")}
                  </FormLabel>

                  <EditableTagsInput
                    tags={values.urls}
                    onTagsChange={(newUrls) => setFieldValue("urls", newUrls)}
                    errors={errors.urls}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("facebook_campaign")}
                  </FormLabel>
                  <FacebookCampaignDropdown
                    campaigns={facebookCampaignOptions.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                    value={values.facebookCampaignIds}
                    onChange={(selectedOptions) =>
                      setFieldValue("facebookCampaignIds", selectedOptions)
                    }
                  />
                  <ErrorMessage
                    name="facebookCampaignIds"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("salesperson")}
                  </FormLabel>{" "}
                  <SalespersonDropdown
                    value={values.salespersons}
                    onChange={(selectedOptions) =>
                      setFieldValue(`salespersons`, selectedOptions)
                    }
                  />
                  <ErrorMessage
                    name="salespersons"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "save" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditEventForm;
