import { ErrorMessage, Form, Formik } from "formik";
import { snakeCase } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import * as Yup from "yup";
import {
  DEAL_STATUSES,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT_NON_MOMENT,
} from "../../helpers/constants";
import { formatCurrency } from "../../helpers/global";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";
import CurrencyInput from "../common/CurrencyInput";
import CustomMultiSelect from "../common/CustomMultiSelect";
import TimePicker from "../common/TimePicker";
import ProductionStageProgess from "../deals/ProductionStageProgress";
import ViewContactButton from "../deals/ViewContactButton";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";

// Yup validation schema
const validationSchema = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  product: Yup.string().required("Product is required"),
  proposalQuote: Yup.string().required("Deal value is required"),
});

const DealDetailForm = ({
  disabled,
  originalContract,
  contract,
  products,
  onChange,
}) => {
  const { translate, isRTL } = useLocalization();
  const { user } = useAuth();
  const findPaymentDue = (contract) => {
    let dealValue = contract?.proposalQuote;
    let totalPayment = contract?.payments?.reduce((a, b) => a + b.amount, 0);
    return dealValue - totalPayment;
  };
  const users = useAppChoices("users");
  const tags = useAppChoices("tags");
  const productOptions = useAppChoices("units");

  let initialValues = useMemo(
    () => ({
      product: contract?.product?._id || contract?.product,
      units:
        contract?.units?.map((u) => ({
          ...u,
          unit: u?.unit?._id || u?.unit,
        })) || [],
      status: contract?.status,
      proposalQuote: contract?.proposalQuote,
      assignedTo: contract?.assignedTo?.map((u) => u?._id || u) || [],
      isFloating: Boolean(contract?.isFloating),
      signedOn: contract?.signedOn,
      tags: contract?.tags || [],
    }),
    [contract]
  );

  const getLastProductionStage = (unitId) => {
    const unit = productOptions?.find((p) => p._id === unitId);
    return unit?.productionStages?.[unit?.productionStages?.length - 1]?._id;
  };

  return (
    <Formik
      initialValues={initialValues || {}}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, setFieldValue }) => {
        useEffect(() => {
          onChange(values);
        }, [values]);
        return (
          <Form noValidate className="">
            <Row className="m-0 p-0 align-items-center">
              {isAdminOrManager(user.role) && (
                <>
                  {" "}
                  <Col xs={4} md={2} lg={2}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-2">
                        {translate("contract")}
                      </FormLabel>
                      <CustomMultiSelect
                        height="30px"
                        isMulti={false}
                        items={products?.map((product) => ({
                          label: product?.description,
                          value: product?._id,
                        }))}
                        selectedItems={values?.product}
                        onChange={(product) => {
                          setFieldValue("product", product);
                        }}
                        placeholder="Select contract"
                        disabled={disabled}
                        closeMenuOnSelect
                      />

                      <ErrorMessage
                        name={"product"}
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>{" "}
                  <Col xs={8} md={4} lg={4}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-2">
                        {translate("products")}
                      </FormLabel>

                      <CustomMultiSelect
                        height="30px"
                        items={productOptions?.map((u) => ({
                          label: u?.description,
                          value: u?._id,
                        }))}
                        selectedItems={
                          values?.units?.map((u) => u?.unit?._id || u?.unit) ||
                          []
                        }
                        onChange={(value) => {
                          let updatedUnits = value.map((u) => {
                            let existingUnit = values.units.find(
                              (x) => x?.unit === u
                            );
                            let existingOldUnit = originalContract?.units?.find(
                              (x) => x?.unit?._id === u
                            );
                            let dealWonId = productOptions
                              ?.find((p) => p._id === u)
                              ?.productionStages?.find(
                                (ps) => ps?.step === "Deal won"
                              )?._id;

                            return (
                              existingUnit ||
                              existingOldUnit || {
                                unit: u,
                                productionStage:
                                  values?.status === "Signed"
                                    ? dealWonId
                                    : null,
                              }
                            );
                          });
                          setFieldValue("units", updatedUnits);
                        }}
                        isClearable
                        placeholder="Select products"
                        disabled={disabled}
                      />

                      <ErrorMessage
                        name={"assignedTo"}
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4} md={2} lg={2} className="">
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-2">
                        {translate("status")}
                      </FormLabel>
                      <CustomMultiSelect
                        height="30px"
                        isMulti={false}
                        items={DEAL_STATUSES?.map((status) => ({
                          label: translate(snakeCase(status)),
                          value: status,
                        }))}
                        selectedItems={values?.status}
                        onChange={(status) => {
                          let updatedUnits = values.units.map((u) => {
                            let dealWonId = productOptions
                              ?.find((p) => p._id === u?.unit)
                              ?.productionStages?.find(
                                (ps) => ps?.step === "Deal won"
                              )?._id;

                            return {
                              ...u,
                              productionStage:
                                status === "Signed"
                                  ? u?.productionStage?._id ||
                                    u?.productionStage ||
                                    dealWonId
                                  : u?.productionStage,
                            };
                          });
                          setFieldValue("units", updatedUnits);
                          setFieldValue("status", status);
                        }}
                        closeMenuOnSelect
                        disabled={disabled}
                      />

                      <ErrorMessage
                        name={"status"}
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>{" "}
                </>
              )}
              {contract?.contact?._id && (
                <Col xs={8} md={4} lg={4}>
                  <ViewContactButton
                    contact={contract?.contact}
                    onContactChange={(contact) => {
                      onChange({ contact });
                    }}
                  />
                </Col>
              )}
              {/* Production stages */}{" "}
              <Col
                xs={12}
                className={`p-0 ${
                  !isAdminOrManager(user.role) &&
                  "border-top border-2 border-secondary border-bottom"
                }}`}
              >
                <h6 className="mid mb-0 mx-2 mt-1 mb-2">
                  {translate("production_stage")}
                </h6>
                {values?.units?.length === 0 ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: 50 }}
                  >
                    {" "}
                    <h6 className="mb-0 mid">No any product selected</h6>
                  </div>
                ) : (
                  values?.units?.map((product, index) => {
                    const currentProduct = productOptions?.find(
                      (pO) => pO?._id === product?.unit
                    );

                    const currentProductProductionStages =
                      currentProduct?.productionStages;

                    const selectedProductionStage =
                      product?.productionStage?._id || product?.productionStage;

                    return (
                      <div
                        key={`${currentProduct?._id}-${index}`}
                        className="border rounded mx-2 my-1"
                      >
                        {currentProductProductionStages?.length === 0 ? (
                          <div
                            className={`px-2 py-1 d-flex align-items-center justify-content-center `}
                            style={{ height: "80px" }}
                          >
                            {translate("this_product_has_no_producation_stage")}
                          </div>
                        ) : (
                          <ProductionStageProgess
                            label={currentProduct?.description}
                            className={"my-2 px-2"}
                            productionStages={currentProductProductionStages}
                            currentProductionStage={selectedProductionStage}
                            onProductionStageChange={(newProductionStageId) => {
                              const updatedUnits = values.units?.map((u) => {
                                const unitLastStageId = getLastProductionStage(
                                  u.unit
                                );
                                const isLastStage = (stageId) =>
                                  unitLastStageId === stageId;

                                if (u.unit === product.unit) {
                                  return {
                                    ...u,
                                    productionStage: newProductionStageId,
                                    isLastStage:
                                      isLastStage(newProductionStageId),
                                  };
                                }

                                return {
                                  ...u,
                                  isLastStage: isLastStage(
                                    u.productionStage?._id ?? u.productionStage
                                  ),
                                };
                              });

                              if (updatedUnits.every((u) => u.isLastStage)) {
                                setFieldValue("status", "Deal Finished");
                              }

                              setFieldValue(
                                "units",
                                updatedUnits.map((u) => {
                                  const { isLastStage, ...rest } = u;
                                  return rest;
                                })
                              );
                            }}
                            productionStageHistory={
                              contract?.productionStageHistory
                            }
                          />
                        )}
                      </div>
                    );
                  })
                )}
              </Col>
              {isAdminOrManager(user.role) && (
                <>
                  <Col xs={6} md={1} lg={1}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("deal_value")}
                      </FormLabel>
                      <CurrencyInput
                        disabled={disabled}
                        value={values?.proposalQuote}
                        onChange={(value) =>
                          setFieldValue(`proposalQuote`, value)
                        }
                      />

                      <ErrorMessage
                        name={"proposalQuote"}
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>{" "}
                  <Col xs={6} md={1} lg={1}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("payment_due")}
                      </FormLabel>
                      <h6
                        style={{ background: "#f4f499" }}
                        className="smallFont rounded fw-bold py-2 px-3"
                        dir="ltr"
                      >
                        {formatCurrency(findPaymentDue(contract))}
                      </h6>
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={3} lg={3}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("assigned_to")}
                      </FormLabel>

                      <CustomMultiSelect
                        items={users}
                        selectedItems={values?.assignedTo}
                        onChange={(users) => {
                          setFieldValue("assignedTo", users);
                        }}
                      />

                      <ErrorMessage
                        name={"assignedTo"}
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={2} lg={2}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("tags")}
                      </FormLabel>

                      <CustomMultiSelect
                        items={tags.map((tag) => ({
                          label: tag.name,
                          value: tag.name,
                        }))}
                        selectedItems={values?.tags}
                        onChange={(tag) => {
                          setFieldValue("tags", tag);
                        }}
                      />

                      <ErrorMessage
                        name={"tags"}
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={2} lg={2}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("contract_signed_on")}
                      </FormLabel>
                      <DatePicker
                        className={`${
                          isRTL ? "px-4" : ""
                        }   mb-0 text-dark px-2 py-1 form-control form-control-sm`}
                        selected={
                          values?.signedOn ? new Date(values?.signedOn) : null
                        }
                        dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                        onChange={(e) => {
                          setFieldValue(`signedOn`, e?.toISOString() || null);
                        }}
                        showYearDropdown
                        showMonthDropdown
                        showTimeInput
                        timeIntervals={15}
                        dropdownMode="scroll"
                        timeFormat="HH:mm" // 24-hour format
                        clearButtonClassName="text-dark"
                        portalId="root"
                        popperClassName="datepickerPopperClassName"
                      />
                    </FormGroup>
                  </Col>{" "}
                  <Col xs={6} md={2} lg={2}>
                    {" "}
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("contract_sent_on")}
                      </FormLabel>
                      <h6 className="smallFont fw-bold form-control form-control-sm">
                        {contract?.createdAt
                          ? moment(contract?.createdAt).format(
                              DEFAULT_DATE_TIME_FORMAT
                            )
                          : "-"}
                      </h6>
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={1} lg={1}>
                    {" "}
                    <div className="mt-1">
                      <h6 className="mid mb-2">{translate("floating")}</h6>
                      <Switch
                        onChange={(checked) =>
                          setFieldValue("isFloating", checked)
                        }
                        checked={values?.isFloating}
                        height={15}
                        width={40}
                        offHandleColor="#d1e6cc"
                        onHandleColor="#d1e6cc"
                      />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DealDetailForm;

const CustomInput = ({}) => {
  return (
    <div>
      <TimePicker />
    </div>
  );
};
