import { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../helpers/api";
import { DEFAULT_PAGE_SIZE, REFERER_QS } from "../../helpers/constants";
import { getReferrerTableColumns } from "../../helpers/dataSheetConstants";
import { updateItemsInArray } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import { referralService } from "../../services/referralServices";
import FloatingButton from "../common/FloatingButton";
import DataTable from "../common/data-table/DataTable";
import SearchBox from "../common/searchbox";
import AddEditReferrerModal from "./AddEditReferrerModal";
import DeleteReferrerModal from "./DeleteReferrerModal";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { useLocation, useNavigate } from "react-router-dom";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const Referrers = () => {
  const abortControllerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { translate } = useLocalization();
  const { searchBoxOptions, setIsSearchBoxDisabled, isSearchBoxDisabled } =
    useSearchAndFilterBox();

  const [fetchingFirstPageReferrers, setFetchingFirstPageReferrers] =
    useState(false);
  const [fetchingMoreReferrers, setFetchingMoreReferrers] = useState(false);

  //original referrers array
  const [referrers, setReferrers] = useState([]);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [referrerAddEditModalMeta, setReferrerAddEditModalMeta] =
    useState(null);
  const [referrerDeleteModalMeta, setReferrerDeleteModalMeta] = useState(null);

  const onAddNewReferrerClick = () => {
    setReferrerAddEditModalMeta({});
  };

  const onEditReferrerClick = (referrer) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("referrer", referrer?._id);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const onReferrerDeleteClick = async (referrer) => {
    setReferrerDeleteModalMeta({ referrer, anotherReferrerToAssign: null });
  };

  const tableColumns = useMemo(
    () => getReferrerTableColumns(onReferrerDeleteClick),
    [onReferrerDeleteClick]
  );

  const closeReferrer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("referrer");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    setReferrerAddEditModalMeta(null);
  };

  const fetchReferrers = async () => {
    if (!searchBoxOptions || !toLoadPageInfo) return;

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const { query } = searchBoxOptions;

    const pageToFetch = toLoadPageInfo?.pageNumber;
    const loadingMoreEvents = pageToFetch > 1;

    if (loadingMoreEvents) {
      setFetchingMoreReferrers(true);
    } else {
      setFetchingFirstPageReferrers(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      query,
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
    };

    const { response, error } = await referralService.getReferrals(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    setFetchingMoreReferrers(false);
    setFetchingFirstPageReferrers(false);

    if (error) {
      return toast.error(error);
    }

    const { pageNumber, totalPages, results = [] } = response;
    setLoadedPageInfo({ totalPages, pageNumber });
    setReferrers((prevEvents) =>
      loadingMoreEvents ? [...prevEvents, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageReferrers || fetchingMoreReferrers)
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    if (searchBoxOptions) setToLoadPageInfo({ ...initialPageInfo });
  }, [searchBoxOptions]);

  useEffect(() => {
    fetchReferrers();
  }, [toLoadPageInfo]);

  const onReferrerChange = (referrer) => {
    setReferrers((prevReferrers) => {
      const updatedReferrers = updateItemsInArray(prevReferrers, [referrer]);
      return updatedReferrers;
    });
  };

  const onReferrerSubmit = async (referrer) => {
    const { editMode, editingReferrer } = referrerAddEditModalMeta;
    setReferrerAddEditModalMeta((meta) => ({ ...meta, showProgress: true }));
    let updatedReferrer = {
      _id: editingReferrer?._id,
      name: referrer?.name,
      email: referrer?.email,
      phone: referrer?.phone,
      notes: referrer?.notes,
    };
    const { response, error } = editMode
      ? await referralService.updateReferral(
          editingReferrer?._id,
          updatedReferrer
        )
      : await referralService.createReferral(referrer);
    setReferrerAddEditModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setReferrerAddEditModalMeta(null);
    onReferrerChange(response);
    toast.success(
      editMode ? "Successfully updated referrer" : "Successfully added referrer"
    );
  };

  const deleteReferral = async (deleteInfo) => {
    const { referrer, anotherReferrerToAssign } = deleteInfo;
    try {
      setReferrerDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));
      const { error } = await referralService.deleteReferral(referrer);
      setReferrerDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));
      if (error) {
        return toast.error(error);
      }

      setReferrers((referrers) =>
        referrers.filter((p) => p._id !== referrer._id)
      );
      toast.success("Successfully deleted referrer");
    } catch (error) {
      console.log(error);
    } finally {
      setReferrerDeleteModalMeta(null);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referrerId = searchParams.get(REFERER_QS);
    const foundReferrer = referrerId
      ? referrers?.find((r) => r._id === referrerId)
      : null;
    if (referrerId && foundReferrer) {
      setReferrerAddEditModalMeta({
        initialValues: {
          ...foundReferrer,
        },
        editMode: true,
        editingReferrer: foundReferrer,
      });
    } else {
      setReferrerAddEditModalMeta(null);
    }
  }, [location.search, referrers]);

  return (
    <>
      <Container fluid className={"px-2 py-3"}>
        <div className="border rounded p-2 bg-white">
          <h6 className="mb-3 xlarge fw-bold">
            {translate("referrers")} ({referrers.length}):
          </h6>
          <SearchBox
            containerClass="bg-white rounded me-2 pb-3"
            disabled={isSearchBoxDisabled}
          />

          <DataTable
            maxTableHeight={`calc(100vh - 195px)`}
            rowKey={"_id"}
            columns={tableColumns}
            data={referrers}
            onBottomReached={loadMoreData}
            loadingMoreData={fetchingMoreReferrers}
            onRowClick={onEditReferrerClick}
            loadingFirstPageData={fetchingFirstPageReferrers}
            allowFilter={false}
            allowSort={false}
            striped
          />
        </div>
      </Container>
      <FloatingButton
        className="text-white"
        Icon={PlusCircleFill}
        variant="success"
        text={translate("add_new_referrer")}
        onClick={onAddNewReferrerClick}
      />
      <AddEditReferrerModal
        show={Boolean(referrerAddEditModalMeta)}
        initialValues={referrerAddEditModalMeta?.initialValues}
        editMode={referrerAddEditModalMeta?.editMode}
        onHide={closeReferrer}
        showProgress={referrerAddEditModalMeta?.showProgress}
        onSubmit={onReferrerSubmit}
      />
      <DeleteReferrerModal
        referrers={referrers}
        referrerDeleteModalMeta={referrerDeleteModalMeta}
        setReferrerDeleteModalMeta={setReferrerDeleteModalMeta}
        onHide={() => setReferrerDeleteModalMeta(false)}
        onContinueClick={deleteReferral}
        showProgress={referrerDeleteModalMeta?.showProgress}
      />
    </>
  );
};

export default Referrers;
