import React from "react";
import { Button, Modal } from "react-bootstrap";

import { Send } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";

const ProsperoContractEditAndSendSidebar = ({
  onHide,
  show,
  contract,
  onContractEditAndSendClick,
  showProgress,
}) => {
  const { translate } = useLocalization();

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      backdrop="static"
      className="w-100 "
      dialogClassName="h-100 my-0 vw-90"
      contentClassName="d-flex flex-column h-100 justify-self-center "
    >
      <Modal.Header closeButton className="py-2 px-2">
        <h6 className="large mb-0 fw-bold">
          {contract
            ? `${contract.contact.firstName} ${contract.contact.lastName} - ${contract.product.description}`
            : "N/A"}
        </h6>
      </Modal.Header>
      <Modal.Body className="overflow-auto flex-grow-1 ">
        {show && (
          <div className="d-flex flex-column h-100">
            <div className="flex-grow-1">
              <iframe
                src="https://app.goprospero.com/dashboard"
                title="Prospero Dashboard"
                width="100%"
                height="100%" // Adjust the height as needed
                style={{ border: "none" }} // Add any additional styling here
              >
                {/* Fallback content in case iframes are not supported */}
                <p>Your browser does not support iframes.</p>
              </iframe>
            </div>
            {showProgress && (
              <HorizontalProgress text={translate("please_wait")} />
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="p-1">
        <div className="text-end">
          <Button
            disabled={showProgress}
            variant="dark"
            size="sm"
            className="px-3"
            onClick={onContractEditAndSendClick}
          >
            <Send className="mx-2" />
            {translate("send")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ProsperoContractEditAndSendSidebar;
