import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { FiletypeCsv, Plus } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import {
  TIME_LOG_TYPE_WORK_LOG,
  USER_RECORD_FILE_TYPES,
} from "../../../helpers/constants";
import { downloadFileFromString } from "../../../helpers/global";
import { isAdminOrManager } from "../../../helpers/session";
import useAppChoices from "../../../hooks/useAppChoices";
import useAuth from "../../../hooks/useAuth";
import useLocalization from "../../../hooks/useLocalization";
import { userRecordServices } from "../../../services/userRecordServices";
import { userService } from "../../../services/userService";
import AlertModal from "../../common/AlertModal";
import AppModal from "../../common/AppModal";
import CircularProgressBar from "../../common/circular-progress";
import Comments from "../../common/Comments";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import DateRangePicker from "../../common/DateRangePicker";
import GoogleDriveManager from "../../common/google-drive-manager/GoogleDriveManager";
import EmployeeTiming from "./timing/EmployeeTiming";
import UserFileManager from "./user-file-manager/UserFileManager";

const defaultDateRange = {
  startDate: moment().startOf("month").toDate(),
  endDate: moment().endOf("month").toDate(),
  key: "selection",
  rangeType: "Month",
};

const UserStatCard = ({ userStats, fetchingUserStats }) => {
  const { translate } = useLocalization();
  return (
    <Alert variant="success" className="p-1">
      <h6 className="xlarge ">{translate("total_hours_worked_this_month")}</h6>
      {fetchingUserStats ? (
        <CircularProgressBar size={18} />
      ) : (
        <h6 className="mb-0 fw-bold xxxlarge ">
          {userStats?.totalHoursWorkedThisMonth}
        </h6>
      )}
    </Alert>
  );
};

const TabsAndFilter = ({
  selectedTab,
  tabs,
  onTabChange,
  selecteduser,
  setSelecteduser,
  onCreateNewLogClick,
}) => {
  const { translate } = useLocalization();
  const users = useAppChoices("users");
  let { user } = useAuth();
  return (
    <div className="d-flex justify-content-between align-items-center pb-2">
      <div className="d-flex align-items-center gap-1">
        {tabs?.map(({ label, key }) => (
          <Button
            key={key}
            size="sm"
            variant={key === selectedTab ? "primary" : "dark"}
            className="d-flex align-items-center"
            onClick={() => onTabChange(key)}
          >
            <h6 className="mb-0 smallFont">{label}</h6>
          </Button>
        ))}
      </div>
      <div className="d-flex gap-2 align-items-center">
        <Button
          size="sm"
          variant={"success"}
          className="d-flex align-items-center text-white"
          onClick={onCreateNewLogClick}
        >
          <Plus /> <h6 className="mb-0 smallFont">{translate("new_log")}</h6>
        </Button>
        {isAdminOrManager(user?.role) && (
          <div style={{ minWidth: 200, maxWidth: 350 }}>
            {" "}
            <CustomMultiSelect
              isMulti={false}
              selectedItems={selecteduser}
              items={users?.map((u) => ({ label: u?.name, value: u?._id }))}
              onChange={setSelecteduser}
              placeholder="Select user"
              height="25px"
              closeMenuOnSelect
            />
          </div>
        )}
      </div>
    </div>
  );
};

const tabs = [
  { label: "Timing", key: "Timing" },
  { label: "Files", key: "Files" },
];

const EmployeeRecordModal = ({ show, onHide }) => {
  const users = useAppChoices("users");

  const { user } = useAuth();
  const { translate, isRTL } = useLocalization();

  const [selecteduser, setSelecteduser] = useState("");
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.key);

  // user stats
  const [userStats, setUserStats] = useState(0);
  const [fetchingUserStats, setFetchingUserStats] = useState(true);
  // user files
  const [userFiles, setUserFiles] = useState([]);
  const [fetchingUserFiles, setFetchingUserFiles] = useState(false);

  // user records/comments
  const [userRecords, setUserRecords] = useState({});

  // user comment states
  const [newCommentText, setNewCommentText] = useState("");
  const [commentsUpdating, setCommentsUpdating] = useState(false);
  const [editingComment, setEditingComment] = useState(null);
  const [deletingComment, setDeletingComment] = useState(null);

  const [timeRangeForCSV, setTimeRangeForCSV] = useState(defaultDateRange);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [addEditRecordMeta, setAddEditRecordMeta] = useState(null);

  const onCreateNewLogClick = () => {
    setAddEditRecordMeta({
      mode: "add",
      data: { from: "", to: "", type: TIME_LOG_TYPE_WORK_LOG },
    });
  };

  const activeUser = useMemo(
    () => (isAdminOrManager(user?.role) ? selecteduser : user?._id),
    [user, selecteduser]
  );

  const fetchUserRecords = async (userId) => {
    try {
      let { response, error } = await userRecordServices.getUserRecords(userId);

      if (error) {
        toast.error(error);
        return;
      }
      setUserRecords(response);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteComment = async () => {
    const { _id } = deletingComment;

    setCommentsUpdating(true);

    const { response: updatedContact, error } =
      await userRecordServices.updateUserRecordComments(activeUser, {
        commentUpdates: {
          action: "delete",
          commentId: _id,
        },
      });

    setCommentsUpdating(false);
    if (error) {
      return toast.error(error);
    }

    setDeletingComment(null);
    toast.success(`Comment deleted successfully!`);
    let { comments = [] } = updatedContact;
    setUserRecords({ ...userRecords, comments });
  };

  const onEditingCommentSubmit = async () => {
    try {
      const { _id, text } = editingComment;
      if (!text.trim()) {
        return toast.error("Comment cannot be empty");
      }

      setCommentsUpdating(true);
      const { response, error } =
        await userRecordServices.updateUserRecordComments(activeUser, {
          commentUpdates: {
            action: "update",
            text: text,
            commentId: _id,
          },
        });

      setCommentsUpdating(false);
      if (error) {
        return toast.error(error);
      }

      setEditingComment(null);
      toast.success(`Comment updated successfully!`);
      let { comments = [] } = response;
      setUserRecords({ ...userRecords, comments });
    } catch (error) {
      console.log(error);
    }
  };

  const onNewCommentSubmit = async () => {
    if (!newCommentText.trim()) {
      return toast.error("Comment cannot be empty");
    }
    setCommentsUpdating(true);

    const { response, error } =
      await userRecordServices.updateUserRecordComments(activeUser, {
        commentUpdates: { action: "add", text: newCommentText },
      });

    setCommentsUpdating(false);
    if (error) {
      return toast.error(error);
    }
    let { comments = [] } = response;
    setUserRecords({ ...userRecords, comments });
    setNewCommentText("");
  };

  const fetchUserFiles = async ({ userId, fileType = [] }) => {
    try {
      setFetchingUserFiles(true);
      let reqBody = {
        filter: {
          users: [userId],
          fileType,
        },
      };
      const { response, error } = await userService.searchUserFiles(reqBody);

      if (error) {
        toast.error(error);
        return;
      }
      if (response) {
        setUserFiles(response?.results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingUserFiles(false);
    }
  };

  const fetchUserStats = async ({ userId }) => {
    try {
      setFetchingUserStats(true);
      let reqBody = {
        users: [userId],
      };
      const { response, error } = await userService.getUserStats(reqBody);

      if (error) {
        toast.error(error);
        return;
      }
      if (response) {
        setUserStats(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingUserStats(false);
    }
  };

  const downloadRecordCSV = async () => {
    toast.info("File will be downloaded shortly...");
    setDownloadingCSV(true);
    let { startDate, endDate } = timeRangeForCSV;
    let reqbody = {
      filter: { from: startDate, to: endDate, users: [activeUser] },
    };
    const { response, error } = await userRecordServices.downloadUserLogCSV(
      reqbody
    );
    setDownloadingCSV(false);

    if (error) {
      toast.error(error);
      return;
    }

    const { csv } = response;
    downloadFileFromString({ data: csv, fileName: "record.csv" });
  };

  useEffect(() => {
    if (activeUser) {
      if (isAdminOrManager(user?.role)) {
        fetchUserRecords(activeUser);
      }
      fetchUserFiles({
        userId: activeUser,
        fileType: USER_RECORD_FILE_TYPES,
      });
      fetchUserStats({
        userId: activeUser,
      });
    }
  }, [activeUser]);

  useEffect(() => {
    if (users?.length && !selecteduser) {
      setSelecteduser(users?.find((u) => !isAdminOrManager(u?.role))?._id);
    }
  }, [users]);

  return (
    <AppModal
      fullscreen
      show={show}
      onHide={onHide}
      title={"User records"}
      modalBodyClassName="py-2"
    >
      <Row className="px-0 py-0">
        <Col sm={12} md={9}>
          <TabsAndFilter
            selectedTab={selectedTab}
            tabs={tabs}
            onTabChange={setSelectedTab}
            selecteduser={selecteduser}
            setSelecteduser={setSelecteduser}
            onCreateNewLogClick={onCreateNewLogClick}
          />
          {selectedTab === "Timing" && (
            <EmployeeTiming
              selectedUser={activeUser}
              onUpdateTiming={async (selectedUser) =>
                await fetchUserStats({ userId: selectedUser })
              }
              addEditRecordMeta={addEditRecordMeta}
              setAddEditRecordMeta={setAddEditRecordMeta}
            />
          )}

          {selectedTab === "Files" && <UserFileManager userId={activeUser} />}
        </Col>

        <Col sm={12} md={3} className="py-0">
          {" "}
          <div className="d-flex justify-content-end">
            <DateRangePicker
              value={timeRangeForCSV}
              onChange={(ranges) => {
                setTimeRangeForCSV(ranges?.selection);
              }}
              closeOnSelect={false}
              className="m-1"
              isRTL={isRTL}
              customView={({ openCalendar }) => (
                <Button
                  size="sm"
                  variant={"dark"}
                  className="d-flex align-items-center"
                  onClick={openCalendar}
                >
                  <FiletypeCsv size={12} className="mx-1" />
                  <span className="smallFont">Export</span>
                </Button>
              )}
              FooterContent={({ setShowPicker }) => (
                <div className="w-100 d-flex justify-content-center m-1">
                  <Button
                    size="sm"
                    variant="dark"
                    className="w-75 m-1"
                    onClick={() => {
                      downloadRecordCSV();
                      setShowPicker(false);
                    }}
                  >
                    Download
                  </Button>
                </div>
              )}
            />
          </div>
          <h6 className="large fw-bold">User stats</h6>
          <UserStatCard
            userStats={userStats}
            fetchingUserStats={fetchingUserStats}
          />
          {isAdminOrManager(user?.role) && (
            <>
              {" "}
              <h6 className="large fw-bold">Comments</h6>
              <Comments
                fullHeight={false}
                comments={userRecords.comments}
                text={newCommentText}
                onTextChange={setNewCommentText}
                onCommentSubmit={onNewCommentSubmit}
                commentsUpdating={commentsUpdating}
                editable={true}
                editingComment={editingComment}
                onEditingCommentUpdate={(text) =>
                  setEditingComment({ ...editingComment, text })
                }
                onCommentEditClick={(comment) =>
                  setEditingComment({ ...comment })
                }
                onCommentEditCancelClick={() => setEditingComment(null)}
                onCommentEditSaveClick={onEditingCommentSubmit}
                onCommentDeleteClick={(comment) =>
                  setDeletingComment({ ...comment })
                }
              />
              <AlertModal
                show={Boolean(deletingComment)}
                onHide={() => setDeletingComment(null)}
                onContinueClick={deleteComment}
                onDismissClick={() => setDeletingComment(null)}
                alertText={translate("are_you_sure_delete_comment")}
                showProgress={commentsUpdating}
              />
            </>
          )}
        </Col>
      </Row>
    </AppModal>
  );
};

export default EmployeeRecordModal;
