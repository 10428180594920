import React, { useEffect, useState } from "react";
import useContacts from "../../hooks/useContacts";
import useLocalization from "../../hooks/useLocalization";
import Loader from "../common/Loader";
import MeetingCard from "../common/MeetingCard";
import SelectContactModal from "../common/SelectContactModal";
import { availabilityService } from "../../services/availabilityService";
import { toast } from "react-toastify";
import AlertModal from "../common/AlertModal";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE } from "../../helpers/constants";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import { convertZoomMeetToAppAppointment } from "../../helpers/global";
import useAppChoices from "../../hooks/useAppChoices";

const getZoomMeetRecordingUrl = (response) => {
  const recordingPlayPasscode = response?.recording_play_passcode;
  const recordingFiles = response?.recording_files;
  const videoUrl = recordingFiles?.find((r) => r?.file_type === "MP4");

  return videoUrl ? `${videoUrl}?psw=${recordingPlayPasscode}` : null;
};

const UntrackedZoomMeeting = ({
  searchQuery,
  checkedParticipants,
  onImportUntrackedZoomMeet,
  contacts,
  loadingContacts,
  setContactQuery,
}) => {
  const { translate } = useLocalization();
  const { user } = useAuth();
  const users = useAppChoices("users");

  // Import untracked Zoom meetings
  const [fetchingUntrackedZoomMeetings, setFetchingUntrackedZoomMeetings] =
    useState(true);
  const [originalUntrackedZoomMeets, setOriginalUntrackedZoomMeets] = useState(
    []
  );
  const [untrackedZoomMeets, setUntrackedZoomMeets] = useState([]);
  const [
    selectContactModalForUntrackedZoomMeet,
    setSelectContactModalForUntrackedZoomMeet,
  ] = useState(null);
  const [deleteUntrackedZoomMeetMeta, setDeleteUntrackedZoomMeetMeta] =
    useState(null);

  const [fetchingZoomRecording, setFetchingZoomRecording] = useState(false);

  const onImportClick = (zoomMeet) => {
    setSelectContactModalForUntrackedZoomMeet({ zoomMeet });
  };

  const onDeleteUntrackedZoomMeetClick = (zoomMeet) => {
    setDeleteUntrackedZoomMeetMeta({ zoomMeet });
  };

  const deleteUntrackedZoomMeet = async () => {
    const { zoomMeet } = deleteUntrackedZoomMeetMeta;

    setDeleteUntrackedZoomMeetMeta((prevMeta) => ({
      ...prevMeta,
      showProgress: true,
    }));

    const { response, error } =
      await availabilityService.deleteUntrackedZoomMeet(zoomMeet?.zoomMeetId);
    if (error) return toast.error(error);

    toast.success("Meeting deleted successfully");
    const remainingUntrackedZoomMeets = originalUntrackedZoomMeets?.filter(
      (m) => String(m?.zoomMeetId) !== String(response?.zoomMeetId)
    );
    setOriginalUntrackedZoomMeets(remainingUntrackedZoomMeets);
    setDeleteUntrackedZoomMeetMeta(null);
  };

  const onViewMeetRecordingClick = async (zoomMeet) => {
    setFetchingZoomRecording(true);

    const reqBody = { zoomMeetId: zoomMeet?.zoomMeetId };

    const { response, error } = await availabilityService.getZoomMeetRecordings(
      reqBody
    );
    if (error) return toast.error(error);

    const joinUrl = getZoomMeetRecordingUrl(response);
    if (joinUrl) {
      window.open(joinUrl, "_blank");
    } else {
      toast.info("No recordings found");
    }
    setFetchingZoomRecording(false);
  };

  const getUntrackedZoomMeets = async () => {
    if (user.role !== ADMIN_ROLE) return;

    setFetchingUntrackedZoomMeetings(true);

    const { response, error } =
      await availabilityService.searchUntrackedZoomMeetings();
    if (error) return toast.error(error);

    const results = response?.map((zM) =>
      convertZoomMeetToAppAppointment({ zoomMeet: zM, users })
    );
    setOriginalUntrackedZoomMeets(results);
    setFetchingUntrackedZoomMeetings(false);
  };

  const handleImportZoomMeeting = async () => {
    try {
      const { zoomMeet, contact } = selectContactModalForUntrackedZoomMeet;

      const newAppointment = {
        ...zoomMeet,
        participants: zoomMeet?.participants?.map((p) => p?._id),
        contact: contact?._id,
        invitees: contact?.emails,
      };

      setSelectContactModalForUntrackedZoomMeet((prevMeta) => ({
        ...prevMeta,
        showProgress: true,
      }));

      const { response, error } = await availabilityService.create(
        newAppointment
      );
      if (error) return toast.error(error);

      onImportUntrackedZoomMeet({
        ...response,
        start: new Date(response?.startDateTime),
        end: new Date(response?.endDateTime),
        isAppEvent: true,
      });

      const remainingUntrackedZoomMeets = originalUntrackedZoomMeets?.filter(
        (m) => String(m?.zoomMeetId) !== String(response?.zoomMeetId)
      );
      setOriginalUntrackedZoomMeets(remainingUntrackedZoomMeets);
      setSelectContactModalForUntrackedZoomMeet(null);
      toast.success("Meeting imported successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  useDebouncedEffect(
    () => {
      const searchedResults = originalUntrackedZoomMeets.filter((meet) => {
        const { title = "", description = "", participants = [] } = meet;
        return participants.some((participant) => {
          return (
            (title
              .toLowerCase()
              .trim()
              .includes(searchQuery.toLowerCase().trim()) ||
              description
                .toLowerCase()
                .trim()
                .includes(searchQuery.toLowerCase().trim())) &&
            checkedParticipants.includes(participant?._id)
          );
        });
      });
      setUntrackedZoomMeets(searchedResults);
    },
    [originalUntrackedZoomMeets, searchQuery, checkedParticipants],
    300
  );

  useEffect(() => {
    getUntrackedZoomMeets();
  }, []);

  return (
    <div className="overflow-auto h-100">
      {fetchingUntrackedZoomMeetings ? (
        <div className="px-2">
          <Loader />
        </div>
      ) : untrackedZoomMeets?.length === 0 ? (
        <div className="p-2">
          <h6 className="mid">0 results found</h6>
        </div>
      ) : (
        untrackedZoomMeets?.map((zoomMeeting) => (
          <MeetingCard
            fetchingZoomRecording={fetchingZoomRecording}
            key={zoomMeeting?.uuid}
            appointment={zoomMeeting}
            onImportClick={onImportClick}
            onViewMeetRecordingClick={onViewMeetRecordingClick}
            onDeleteMeetClick={onDeleteUntrackedZoomMeetClick}
          />
        ))
      )}
      <SelectContactModal
        show={selectContactModalForUntrackedZoomMeet}
        onHide={() => setSelectContactModalForUntrackedZoomMeet(null)}
        contacts={contacts}
        showProgress={
          loadingContacts ||
          selectContactModalForUntrackedZoomMeet?.showProgress
        }
        setContactQuery={setContactQuery}
        onContactSelect={(contact) =>
          setSelectContactModalForUntrackedZoomMeet({
            ...selectContactModalForUntrackedZoomMeet,
            contact,
          })
        }
        selectedContact={selectContactModalForUntrackedZoomMeet?.contact}
        showFooter
        onContactSelectClick={handleImportZoomMeeting}
      />
      <AlertModal
        show={deleteUntrackedZoomMeetMeta}
        onHide={() => setDeleteUntrackedZoomMeetMeta(null)}
        alertText={translate("are_you_sure_delete_untracked_zoom_meet")}
        onDismissClick={() => setDeleteUntrackedZoomMeetMeta(null)}
        onContinueClick={deleteUntrackedZoomMeet}
        showProgress={deleteUntrackedZoomMeetMeta?.showProgress}
      />
    </div>
  );
};

export default UntrackedZoomMeeting;
