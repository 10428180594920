import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import { GROUPPED_STATUS } from "../../../helpers/constants";
import useLocalization from "../../../hooks/useLocalization";

const StatusDropdown = ({ value, onChange }) => {
  let { translate } = useLocalization();
  return (
    <CustomMultiSelect
      items={GROUPPED_STATUS(translate)}
      onChange={onChange}
      selectedItems={value}
      isMulti={false}
      isGroupped
      placeholder="Select"
      fieldColors={GROUPPED_STATUS(translate)
        .flatMap((o) => o?.options)
        ?.find((option) => option?.value === value)}
      closeMenuOnSelect
      height="30px"
      isClearable
    />
  );
};

export default StatusDropdown;
