import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ABORT_ERROR } from "../helpers/api";
import {
  getMonthRange,
  mergeAppAndGoogleAppointments,
  mergeArrays,
} from "../helpers/global";
import useDebouncedEffect from "../hooks/useDebouncedEffect";
import { availabilityService } from "../services/availabilityService";
import useAuth from "../hooks/useAuth";
import { toast } from "react-toastify";

export const AppointmentContext = createContext();

export const AppointmentProvider = ({ children }) => {
  const { user } = useAuth();
  const [latestActiveContacts, setLatestActiveContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [toFetchAppointmentInfo, setToFetchAppointmentInfo] = useState(
    getMonthRange(new Date())
  );
  const [fetchedAppointmentInfo, setFetchedAppointmentInfo] = useState({
    fetchedMonths: [],
  });

  const [checkedParticipants, setCheckedParticipants] = useState(() => {
    const saved = localStorage.getItem("checkedParticipants");
    return saved ? JSON.parse(saved) : [user._id];
  });

  const [showHoliday, setShowHoliday] = useState(() => {
    const saved = localStorage.getItem("showHoliday");
    return saved ? JSON.parse(saved) : false;
  });

  const abortControllerRef = useRef(null);

  const fetchAppointments = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;
    abortControllerRef.current = controller;

    setIsLoading(true);

    try {
      const reqBody = {
        filter: {
          ...toFetchAppointmentInfo,
        },
      };

      const { response, error } = await availabilityService.search(
        reqBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      if (error) {
        toast.error(error);
        return;
      }

      const { results } = response;

      const mergedResponse = mergeAppAndGoogleAppointments(results);

      const updatedAppointments = mergeArrays(
        appointments,
        mergedResponse,
        "_id"
      );

      setAppointments([...updatedAppointments]);

      setFetchedAppointmentInfo((prevFetchedInfo) => ({
        fetchedMonths: [
          ...prevFetchedInfo.fetchedMonths,
          toFetchAppointmentInfo,
        ],
      }));
    } catch (e) {
      console.log(e);
    } finally {
      if (controller === abortControllerRef.current) {
        setIsLoading(false);
        abortControllerRef.current = null;
      }
    }
  };

  useDebouncedEffect(
    () => {
      if (toFetchAppointmentInfo) {
        fetchAppointments();
      }
    },
    [toFetchAppointmentInfo],
    200
  );

  useEffect(() => {
    localStorage.setItem(
      "checkedParticipants",
      JSON.stringify(checkedParticipants)
    );
  }, [checkedParticipants]);

  useEffect(() => {
    localStorage.setItem("showHoliday", JSON.stringify(showHoliday));
  }, [showHoliday]);

  const getLatestActiveContacts = async () => {
    try {
      const reqBody = {
        pageNumber: 1,
        pageSize: 5,
        withContacts: true,
        sort: { createdAt: -1 },
        filter: {
          createdBy: user._id,
        },
        returnGoogleEvents: false,
      };

      const { response, error } = await availabilityService.search(reqBody);
      let appointments = response?.results?.appEvents || [];

      let contactMap = new Map();

      // Iterate through the appointments to populate the map with unique contacts
      appointments.forEach((a) => {
        if (a?.contact?._id) {
          contactMap.set(a.contact._id, a.contact);
        }
      });

      // Convert the map back to an array of unique contact objects
      let uniqueContacts = Array.from(contactMap.values());

      setLatestActiveContacts(uniqueContacts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestActiveContacts();
  }, [fetchedAppointmentInfo, appointments]);

  return (
    <AppointmentContext.Provider
      value={{
        appointments,
        setAppointments,
        isLoading,
        toFetchAppointmentInfo,
        setToFetchAppointmentInfo,
        fetchedAppointmentInfo,
        setFetchedAppointmentInfo,
        checkedParticipants,
        setCheckedParticipants,
        showHoliday,
        setShowHoliday,
        latestActiveContacts,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};

export const useAppointment = () => {
  const {
    appointments,
    setAppointments,
    isLoading,
    toFetchAppointmentInfo,
    setToFetchAppointmentInfo,
    fetchedAppointmentInfo,
    setFetchedAppointmentInfo,
    checkedParticipants,
    setCheckedParticipants,
    showHoliday,
    setShowHoliday,
    latestActiveContacts,
  } = useContext(AppointmentContext);
  return {
    appointments,
    setAppointments,
    isLoading,
    toFetchAppointmentInfo,
    setToFetchAppointmentInfo,
    fetchedAppointmentInfo,
    setFetchedAppointmentInfo,
    checkedParticipants,
    setCheckedParticipants,
    showHoliday,
    setShowHoliday,
    latestActiveContacts,
  };
};
