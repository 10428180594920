import { useEffect, useMemo, useRef, useState } from "react";

import moment from "moment";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../helpers/api";
import { calculateDateTimeRange } from "../../helpers/global";
import useAppChoices from "../../hooks/useAppChoices";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { dashboardService } from "../../services/dashboardService";
import DashboardTabs from "./DashboardTabs";
import UserAndDateRangePicker from "../common/UserAndDateRangePicker";
import FinancialStatBoard from "./components/FinancialStatBoard";
import GeneralStatBoard from "./components/GeneralStatBoard";
import MarketingStatBoard from "./components/MarketingStatBoard";
import OperationStatBoard from "./components/OperationStatBoard";
import SalesStatBoard from "./components/SalesStatBoard";
import { useLocation, useNavigate } from "react-router-dom";

const defaultDateRange = {
  startDate: moment().startOf("month").toDate(),
  endDate: moment().endOf("month").toDate(),
  key: "selection",
  rangeType: "Month",
};

let dashboardTabs = [
  {
    key: "general_stat_board",
  },
  {
    key: "marketing_stat_board",
  },
  {
    key: "sales_stat_board",
  },
  {
    key: "finance_stat_board",
  },
  {
    key: "operation_stat_board",
  },
];

const Dashboard = () => {
  const abortControllerRef = useRef(null);
  const { isRTL } = useLocalization();
  const users = useAppChoices("users");
  const [selectedTab, setSelectedTab] = useState(dashboardTabs[0]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [rangeType, setRangeType] = useState();
  const [dashboardData, setDashboardData] = useState(null);
  const [fetchingDashboardData, setFetchingDashboardData] = useState(true);
  const { openDealPopup, updatedDeal } = useContactAndDealPopup();
  const [navigatingDashboardData, setNavigatingDashboardData] = useState(false);
  const [isNavigation, setIsNavigation] = useState(false);

  const onDealClick = (deal) => {
    openDealPopup(deal?._id);
  };

  let onTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  function handleOnDateChange(ranges) {
    let rangeType = ranges?.selection?.rangeType?.split(" ").pop() || "Week";
    setRangeType(
      rangeType === "Today"
        ? "Day"
        : rangeType === "Time"
        ? "All Time"
        : rangeType
    );
    setDateRange(ranges?.selection);
  }

  const fetchDashboardStats = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;
    abortControllerRef.current = controller;

    if (isNavigation) {
      setNavigatingDashboardData(true);
    } else {
      setFetchingDashboardData(true);
    }

    try {
      let reqBody = {
        usersFilter: selectedUser?.length > 0 ? selectedUser : null,
        dateFilter: dateRange?.startDate ? dateRange : null,
      };
      let { response, error } = await dashboardService.getStats(
        reqBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      if (response) {
        setDashboardData(response);
      }

      if (error) {
        toast.error(error);
      }
      setFetchingDashboardData(false);
    } catch (error) {
      console.log(error);
    } finally {
      if (controller === abortControllerRef.current) {
        setFetchingDashboardData(false);
        setNavigatingDashboardData(false);
        setIsNavigation(false);
        abortControllerRef.current = null;
      }
    }
  };

  const handleNavigateDate = (rangeType, direction) => {
    const { startDate, endDate } = dateRange;
    const { startDateTime, endDateTime } = calculateDateTimeRange(
      startDate,
      endDate,
      rangeType,
      direction
    );
    setDateRange({
      startDate: startDateTime,
      endDate: endDateTime,
      key: "selection",
    });
    setIsNavigation(true);
  };

  const dateRangeLabel = useMemo(() => {
    const startDate = moment(dateRange?.startDate);
    const endDate = moment(dateRange?.endDate);

    switch (rangeType) {
      case "Day":
        return startDate.format("DD MMM, YYYY");
      case "Month":
        return startDate.format("MMMM YYYY");
      case "Quarter":
        return `${startDate.format("MMM, YYYY")} - ${endDate.format(
          "MMM, YYYY"
        )}`;
      case "Year":
        return startDate.format("YYYY");
      case "Week":
        return `${startDate.format("DD MMM, YYYY")} - ${endDate.format(
          "DD MMM, YYYY"
        )}`;
      default:
        return `${startDate.format("DD MMM, YYYY")} - ${endDate.format(
          "DD MMM, YYYY"
        )}`;
    }
  }, [dateRange, rangeType]);

  useDebouncedEffect(
    () => {
      fetchDashboardStats();
    },
    [selectedUser, dateRange],
    200
  );

  useEffect(() => {
    if (Boolean(updatedDeal)) {
      fetchDashboardStats();
    }
  }, [updatedDeal]);

  useEffect(() => {
    setDateRange(defaultDateRange);
    setRangeType(defaultDateRange?.rangeType);
  }, [defaultDateRange]);

  return (
    <div className="p-2 d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <DashboardTabs
          disabled={fetchingDashboardData}
          tabs={dashboardTabs}
          selectedTab={selectedTab}
          onTabSelect={onTabSelect}
        />{" "}
        <UserAndDateRangePicker
          dateRange={dateRange}
          onDateRangeChange={handleOnDateChange}
          selectedUser={selectedUser}
          onUserChange={(users) => setSelectedUser(users)}
          users={users}
          isRTL={isRTL}
        />
      </div>

      <div className="flex-grow-1">
        {selectedTab?.key === dashboardTabs[0]?.key && (
          <GeneralStatBoard
            dateRange={dateRange}
            selectedUser={selectedUser}
            data={dashboardData?.general}
            fetching={fetchingDashboardData}
            navigating={navigatingDashboardData}
            onDealClick={onDealClick}
            rangeType={rangeType}
            dateRangeLabel={dateRangeLabel}
            handleNavigateDate={handleNavigateDate}
          />
        )}
        {selectedTab?.key === dashboardTabs[1]?.key && (
          <MarketingStatBoard
            fetching={fetchingDashboardData}
            navigating={navigatingDashboardData}
          />
        )}
        {selectedTab?.key === dashboardTabs[2]?.key && (
          <SalesStatBoard
            fetching={fetchingDashboardData}
            navigating={navigatingDashboardData}
            data={dashboardData?.sales}
            onDealClick={onDealClick}
            rangeType={rangeType}
            dateRangeLabel={dateRangeLabel}
            handleNavigateDate={handleNavigateDate}
          />
        )}
        {selectedTab?.key === dashboardTabs[3]?.key && (
          <FinancialStatBoard
            fetching={fetchingDashboardData}
            navigating={navigatingDashboardData}
          />
        )}
        {selectedTab?.key === dashboardTabs[4]?.key && (
          <OperationStatBoard
            fetching={fetchingDashboardData}
            navigating={navigatingDashboardData}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
