import React, { useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AppConfiguration from "../components/app-configuration/AppConfiguration";
import Login from "../components/auth/Login";
import ResetPassword from "../components/auth/ResetPassword";
import AutomationAndEvents from "../components/automation-events";
import NotFound from "../components/common/NotFound";
import BrowseContacts from "../components/contacts/BrowseContacts";
import CustomerAppointment from "../components/customer-appointment/CustomerAppointment";
import Dashboard from "../components/dashboard/Dashboard";
import BrowseDeals from "../components/deals/BrowseDeals";
import BrowseProducts from "../components/products/BrowseProducts";
import Users from "../components/users/Users";
import { ADMIN_ROLE } from "../helpers/constants";
import useAuth from "../hooks/useAuth";
import ProtectedRouteLayout from "../layouts/ProtectedRouteLayout";
import PublicRouteLayout from "../layouts/PublicRouteLayout";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import ReferContact from "../components/refer-contact/ReferContact";
import BrowseCommissions from "../components/commissions/BrowseCommissions";
import { isAdmin, isAdminOrManager } from "../helpers/session";
import Referrers from "../components/referrers/Referrers";
import BrowseLogs from "../components/history-log/BrowseLogs";

const AppRoutes = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const role = useMemo(() => user?.role, [user]);
  if (pathname === "/") {
    return <Navigate to="/auth/login" />;
  }

  return (
    <>
      <Routes>
        <Route element={<PublicRoute checkLogin={false} />}>
          <Route element={<PublicRouteLayout logoInHeader />}>
            <Route path="refer-contact" element={<ReferContact />} />
          </Route>
        </Route>
        <Route element={<PublicRoute />}>
          <Route element={<PublicRouteLayout />}>
            <Route path="auth">
              <Route path="login" element={<Login />} />
              <Route path="create-password/:key" element={<ResetPassword />} />
            </Route>
          </Route>
        </Route>
        {/* Protected Routes */}

        <Route element={<ProtectedRoute />}>
          <Route
            element={
              <ProtectedRouteLayout
                headerVisible={pathname !== "/calendar-embed"}
              />
            }
          >
            <Route path="calendar-embed" element={<CustomerAppointment />} />
            <Route path="contacts" element={<BrowseContacts />} />
            <Route path="deals" element={<BrowseDeals />} />{" "}
            {isAdminOrManager(role) && (
              <>
                {" "}
                <Route path="logs" element={<BrowseLogs />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="products" element={<BrowseProducts />} />{" "}
                <Route
                  path="automation-events"
                  element={<AutomationAndEvents />}
                />
                <Route path="commissions" element={<BrowseCommissions />} />
                <Route
                  path="app-configuration"
                  element={<AppConfiguration />}
                />
                <Route path="referrers" element={<Referrers />} />
                {isAdmin(role) && <Route path="users" element={<Users />} />}
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
