import React from "react";
import useAppChoices from "../../hooks/useAppChoices";
import UserFilter from "./UserFilter";
import { FormCheck, FormControl, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";

const AppointmentSidebar = ({
  fullWidthCalendar,
  collapseSidebar,
  className,
  checkedParticipants,
  setCheckedParticipants,
  disabled = false,
  showHoliday,
  setShowHoliday,
  searchQuery,
  setSearchQuery,
  hideHolidayCheckbox,
}) => {
  const users = useAppChoices("users");
  const { isRTL } = useLocalization();
  const { user } = useAuth();
  return (
    <div className={className}>
      <div className="d-flex gap-2 align-items-center p-1 border-bottom">
        <InputGroup size="sm" className="">
          {isRTL && (
            <InputGroup.Text>
              <Search size={12} />
            </InputGroup.Text>
          )}
          <FormControl
            size="sm"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter event title or description.."
          />
          {!isRTL && (
            <InputGroup.Text>
              <Search size={12} />
            </InputGroup.Text>
          )}
        </InputGroup>
        {fullWidthCalendar && collapseSidebar()}
      </div>{" "}
      <UserFilter
        users={users}
        onUserChange={setCheckedParticipants}
        selectedUsers={checkedParticipants}
        disabled={disabled}
        disableDnD={!isAdminOrManager(user?.role)}
      />
      {!hideHolidayCheckbox && (
        <>
          <hr className="my-2" />
          <div
            className="hover w-100 px-1 d-flex align-items-center border"
            style={{ margin: 2 }}
            onClick={() => setShowHoliday(!showHoliday)}
          >
            <FormCheck
              checked={showHoliday}
              onChange={(e) => setShowHoliday(e.target.checked)}
              className=""
              style={{
                fontSize: 16,
              }}
              id={"show-holiday"}
            />
            <h6 className="mb-0 smallFont mx-1 truncate">Show holiday</h6>
          </div>
        </>
      )}
    </div>
  );
};

export default AppointmentSidebar;
