import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";

const ContainerWithScroll = ({
  onContainerScrollTopChange,
  loadingMoreData,
  bottomOffset = 10, //in px
  onBottomReached,
  disabled,
  height,
  children,
}) => {
  const containerRef = useRef(null); // Create a ref for the table

  useEffect(() => {
    const debouncedHandleScroll = debounce(() => {
      if (loadingMoreData) return;
      const element = containerRef.current;
      if (element) {
        const scrollTop = element.scrollTop;
        const distanceFromBottom =
          element.scrollHeight - scrollTop - element.clientHeight;

        if (distanceFromBottom <= bottomOffset) {
          onBottomReached && onBottomReached();
        }
        if (onContainerScrollTopChange) {
          onContainerScrollTopChange(scrollTop);
        }
      }
    }, 100); // 100ms is the debounce period

    const element = containerRef.current;
    if (element) {
      element.addEventListener("scroll", debouncedHandleScroll);

      // Cleanup
      return () => {
        element.removeEventListener("scroll", debouncedHandleScroll);
        debouncedHandleScroll.cancel(); // Cancel the debounced call if component unmounts
      };
    }
  }, [bottomOffset, onBottomReached]);

  return (
    <div
      id="container-with-scroll"
      ref={containerRef}
      className={`position-relative w-100 ${
        disabled ? "overflow-hidden" : "overflow-auto"
      }`}
      style={{ maxHeight: height }} // Ensure this container has a max-height or fixed height
    >
      {children}
    </div>
  );
};

export default ContainerWithScroll;
