import React, { useEffect, useState } from "react";
import BrowseEvents from "./events/BrowseEvents";
import BrowseAutomations from "./automation/BrowseAutomations";
import { inboundSourceDefaultsStatusService } from "../../services/inboundSourceDefaultsStatusService";
import { Spinner } from "react-bootstrap";
import InboundSourceDefaultStatus from "./inbound-source-status/InboundSourceDefaultStatus";
import { miscService } from "../../services/miscService";
import Loader from "../common/Loader";
import { toast } from "react-toastify";

const AutomationAndEvents = () => {
  const [defaultStatus, setDefaultStatus] = useState([]);
  const [fetchingDefaultTypes, setFetchingDefaultTypes] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [activeTrailLists, setActiveTrailLists] = useState([]);
  const [fetchingActiveTrailAndCampaigns, setFetchingActiveTrailAndCampaigns] =
    useState(false);

  const fetchDefaultStatus = async () => {
    try {
      setFetchingDefaultTypes(true);
      const { response, error } =
        await inboundSourceDefaultsStatusService.getInboundSourceDefaultsStatus();
      if (error) {
        return toast.error(error);
      }
      setDefaultStatus(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingDefaultTypes(false);
    }
  };

  const getActiveTrailsAndCampaigns = async () => {
    setFetchingActiveTrailAndCampaigns(true);

    const { response: activeTrailLists, error: activeTrailError } =
      await miscService.getActiveTrailLists();

    const { response: campaignsLists, error: campaignError } =
      await miscService.getFacebookCampaigns();

    setFetchingActiveTrailAndCampaigns(false);

    if (activeTrailError || campaignError) {
      return toast.error(activeTrailError || campaignError);
    }

    setCampaigns(campaignsLists);
    setActiveTrailLists(activeTrailLists);
  };

  useEffect(() => {
    fetchDefaultStatus();
    getActiveTrailsAndCampaigns();
  }, []);

  return fetchingDefaultTypes || fetchingActiveTrailAndCampaigns ? (
    <div className="px-3">
      <Loader />
    </div>
  ) : (
    <div className="pb-5">
      <BrowseAutomations
        campaigns={campaigns}
        activeTrailLists={activeTrailLists}
      />
      <BrowseEvents campaigns={campaigns} activeTrailLists={activeTrailLists} />
      <InboundSourceDefaultStatus
        defaultStatus={defaultStatus}
        setDefaultStatus={setDefaultStatus}
        campaigns={campaigns}
        activeTrailLists={activeTrailLists}
      />
    </div>
  );
};

export default AutomationAndEvents;
