import { defaultOperators, toFullOption } from "react-querybuilder";
import {
  FINANCIAL_STRATEGIST_ROLE,
  INBOUND_SOURCE,
  REAL_ESTATE_ANALYST_ROLE,
  SALES_ROLE,
  STATUS,
} from "./constants";
import { snakeCase } from "lodash";

const statusOptions = (translate) =>
  STATUS.map(({ label, options }) => ({
    label,
    options: options.map((s) => ({ name: s, label: translate(snakeCase(s)) })),
  }));

const inboundSourceOptions = (translate) =>
  INBOUND_SOURCE.map((source) => ({
    name: source,
    label: translate(snakeCase(source)),
  }));

export const validator = (r) => !!r.value;

const personOperators = (op) =>
  op.name === "in" || op.name === "notIn" || op.name === "null";

export const filterFields = (
  allTags,
  users,
  translate,
  inboundCampaignUrlOptions
) => {
  let localizedDefaultOperators = defaultOperators.map((operator) => ({
    ...operator,
    label: translate(snakeCase(operator.label)),
  }));
  return [
    {
      name: "firstName",
      label: translate("first_name"),
      placeholder: "Enter first name",
    },
    {
      name: "lastName",
      label: translate("last_name"),
      placeholder: "Enter last name",
    },
    {
      name: "status",
      label: translate("status"),
      valueEditorType: "multiselect",
      values: statusOptions(translate),
      defaultValue: "New - Unattended",
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "comments.name",
      label: translate("commented_by"),
      valueEditorType: "multiselect",
      values: users.map((user) => ({ name: user.name, label: user.name })),
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn" || op.name === "null"
      ),
    },
    {
      name: "salesperson",
      label: translate("sales_person"),
      valueEditorType: "multiselect",
      values: users
        .filter((user) => user.role === SALES_ROLE)
        .map((user) => ({ name: user._id, label: user.name })),
      operators: localizedDefaultOperators.filter(personOperators),
    },
    {
      name: "financialStrategist",
      label: translate("financial_strategist"),
      valueEditorType: "multiselect",
      values: users
        .filter((user) => user.role === FINANCIAL_STRATEGIST_ROLE)
        .map((user) => ({ name: user._id, label: user.name })),
      operators: localizedDefaultOperators.filter(personOperators),
    },
    {
      name: "realEstateAnalyst",
      label: translate("real_estate_analyst"),
      valueEditorType: "multiselect",
      values: users
        .filter((user) => user.role === REAL_ESTATE_ANALYST_ROLE)
        .map((user) => ({ name: user._id, label: user.name })),
      operators: localizedDefaultOperators.filter(personOperators),
    },
    {
      name: "followupDate",
      label: translate("follow_up_date"),
      inputType: "date",
    },
    {
      name: "phoneNumbers",
      label: translate("phone_numbers"),
      inputType: "text",
    },
    {
      name: "emails",
      label: translate("emails"),
    },
    {
      name: "tags",
      label: translate("tags"),
      values: allTags?.map((tag) => ({ name: tag.name, label: tag.name })),
      valueEditorType: "multiselect",
      defaultValue: allTags?.[0]?.name,
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "createdAt",
      label: translate("added_on"),
      inputType: "date",
    },
    {
      name: "lastInBoundDate",
      label: translate("last_inbound_date"),
      inputType: "date",
    },
    {
      name: "lastInboundSourceType",
      label: translate("last_inbound_source_type"),
      valueEditorType: "multiselect",
      values: inboundSourceOptions(translate),
      defaultValue: INBOUND_SOURCE[0],
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "lastInboundSourceCampaignUrl",
      label: translate("last_inbound_source_campaign_url"),
      valueEditorType: "multiselect",
      values: inboundCampaignUrlOptions.map((data) => ({
        name: data,
        label: data,
      })),
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "spouseFirstName",
      label: translate("spouse_first_name"),
      placeholder: "Enter spouse first name",
      validator,
    },
    {
      name: "spouseLastName",
      label: translate("spouse_last_name"),
      placeholder: "Enter spouse last name",
      validator,
    },
  ].map(toFullOption);
};
