import { snakeCase } from "lodash";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Button, FormCheck, Table } from "react-bootstrap";
import { DEFAULT_DATE_TIME_FORMAT, STATUS } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import { contactService } from "../../services/contactService";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import { toSentenceCase } from "../../helpers/global";

const fieldsToMerge = (translate) => [
  {
    groupTitle: "Single Value",
    hideGroupHeading: true,
    fields: [
      {
        key: "status",
        label: "status",
        valueAccessor: (contact) => {
          const color =
            STATUS.find((s) => s?.options?.includes(contact?.status))?.color ??
            "primary";
          return (
            <Badge bg={color}>{translate(snakeCase(contact?.status))}</Badge>
          );
        },
      },
      { key: "firstName", label: "firstName" },
      { key: "lastName", label: "lastName" },
      { key: "spouseFirstName", label: "spouseFirstName" },
      { key: "spouseLastName", label: "spouseLastName" },
      { key: "address", label: "address" },
      {
        key: "referredBy",
        label: "Referrer",
        valueAccessor: (contact, users, referrals) =>
          referrals?.find(
            (u) =>
              u?._id === contact?.referredBy?._id ||
              u?._id === contact?.referredBy
          )?.name,
      },
      {
        key: "followupDate",
        label: "followupDate",
        valueAccessor: (contact) =>
          contact?.followupDate
            ? moment(contact?.followupDate).format(DEFAULT_DATE_TIME_FORMAT)
            : "-",
      },
      {
        key: "leadSource",
        label: "lead source",
        valueAccessor: (contact) => toSentenceCase(contact?.leadSource),
      },
      { key: "description", label: "description" },
      {
        key: "surveySent",
        label: "surveySent",
        valueAccessor: (contact) => (contact?.surveySent ? "Yes" : "No"),
      },
      {
        key: "surveyCompleted",
        label: "surveyCompleted",
        valueAccessor: (contact) => (contact?.surveyCompleted ? "Yes" : "No"),
      },
    ],
  },
  {
    groupTitle: "Fields with multiple values",
    groupDescription: "You can choose to keep both the values for these fields",
    fields: [
      {
        key: "salesperson",
        label: "salesperson",
        valueAccessor: (contact, users) =>
          contact?.salesperson
            ?.map(
              (s) => users?.find((u) => u?._id === s?._id || u?._id === s)?.name
            )
            ?.join(", "),
        allowBoth: true,
      },
      {
        key: "financialStrategist",
        label: "financialStrategist",
        valueAccessor: (contact, users) =>
          contact?.financialStrategist
            ?.map(
              (s) => users?.find((u) => u?._id === s?._id || u?._id === s)?.name
            )
            ?.join(", "),
        allowBoth: true,
      },
      {
        key: "realEstateAnalyst",
        label: "realEstateAnalyst",
        valueAccessor: (contact, users) =>
          contact?.realEstateAnalyst
            ?.map(
              (s) => users?.find((u) => u?._id === s?._id || u?._id === s)?.name
            )
            ?.join(", "),
        allowBoth: true,
      },
      {
        key: "phoneNumbers",
        label: "phoneNumbers",
        valueAccessor: (contact) => (
          <div>
            {contact?.phoneNumbers?.length
              ? contact?.phoneNumbers?.join(",")
              : "-"}
          </div>
        ),
        allowBoth: true,
      },
      {
        key: "spousePhoneNumbers",
        label: "spousePhoneNumbers",
        valueAccessor: (contact) => (
          <div>
            {contact?.spousePhoneNumbers?.length
              ? contact?.spousePhoneNumbers?.join(",")
              : "-"}
          </div>
        ),
        allowBoth: true,
      },
      {
        key: "emails",
        label: "emails",
        valueAccessor: (contact) => (
          <div>
            {contact?.emails?.length ? contact?.emails?.join(",") : "-"}
          </div>
        ),
        allowBoth: true,
      },
      {
        key: "tags",
        label: "tags",
        valueAccessor: (contact) => (
          <div>
            {contact?.tags?.length
              ? contact?.tags?.map((tag) => (
                  <Badge className="ms-1" key={tag}>
                    {tag}
                  </Badge>
                ))
              : "-"}
          </div>
        ),
        allowBoth: true,
      },
      {
        label: "comments_no_count",
        key: "comments",
        valueAccessor: (contact) =>
          contact?.comments?.length > 0 || contact?.comment
            ? `${contact?.comments?.length || 1} comments`
            : "-",
        allowBoth: true,
      },
      {
        label: "inbounds_no_count",
        key: "inbounds",
        valueAccessor: (contact) =>
          contact?.inbounds?.length > 0
            ? `${contact?.inbounds?.length} inbounds`
            : "-",
        allowBoth: true,
      },
      {
        label: "phoneCalls",
        key: "phoneCalls",
        valueAccessor: (contact) =>
          contact?.phoneCalls?.length > 0
            ? `${contact?.phoneCalls?.length} phone calls`
            : "-",
        allowBoth: true,
      },
      {
        label: "contracts",
        key: "contracts",
        valueAccessor: (contact) =>
          contact?.contracts?.length > 0
            ? `${contact?.contracts?.length} contracts`
            : "-",
        allowBoth: true,
      },
    ],
  },
];
const onViewContactClick = (contactId) => {
  const contactUrl = `/contacts?contact=${contactId}&view=contact`;
  window.open(contactUrl, "_blank");
};
const ConfirmMergeContactModal = ({
  show,
  onHide,
  baseContact,
  duplicateContacts,
  users,
  showProgress,
  onMergeContactClick,
  referrals,
  newContactMerge,
}) => {
  const { translate } = useLocalization();

  const [mergeInfo, setMergeInfo] = useState(null);

  const contactFieldsToMerge = useMemo(() => fieldsToMerge(translate), []);
  const [isFetchingContacts, setIsFetchingContacts] = useState(false);
  const [mergingContacts, setMergingContacts] = useState([]);
  const fetchContacts = async () => {
    setIsFetchingContacts(true);

    const requestBody = {
      pageSize: 20,
      pageNumber: 1,
      filter: [
        {
          key: "_id",
          value: newContactMerge
            ? duplicateContacts
            : duplicateContacts?.filter((d) => d !== baseContact?._id),
        },
      ],
    };

    const { response, error } = await contactService.getContacts(requestBody);

    if (error) {
      setIsFetchingContacts(false);
      toast.error(error);
      return;
    }

    const { results } = response;
    const contactsToMerge = [
      {
        title: "Base Contact",
        key: "base",
        baseContact: true,
        contact: baseContact,
      },
      ...results?.map((r, i) => ({
        title: `Merging Contact ${i + 1}`,
        key: i,
        baseContact: false,
        contact: r,
      })),
    ];
    setMergingContacts(contactsToMerge);
    const initialValues = {};

    const SINGLE_FIELD_DEFAULT_VALUE = ["base"];
    const MULTIPLE_FIELD_DEFAULT_VALUE = contactsToMerge?.map((cM) => cM?.key);

    contactFieldsToMerge
      ?.flatMap((group) => group?.fields)
      ?.forEach((field) => {
        initialValues[field.key] = field?.allowBoth
          ? MULTIPLE_FIELD_DEFAULT_VALUE
          : SINGLE_FIELD_DEFAULT_VALUE;
      });
    setMergeInfo(initialValues);
    setIsFetchingContacts(false);
  };

  const handleMerge = (key, allowBoth, mergeKey, checked) => {
    if (!allowBoth) {
      setMergeInfo({
        ...mergeInfo,
        [key]: [mergeKey],
      });
    } else {
      const currentValue = mergeInfo?.[key] || [];
      let newValue = [];

      if (checked) {
        newValue = [...currentValue, mergeKey];
      } else {
        newValue = currentValue?.filter((cV) => cV !== mergeKey);
      }

      setMergeInfo({
        ...mergeInfo,
        [key]: newValue,
      });
    }
  };

  const onMergeClick = () => {
    let mergeConfig = {
      mergeInfo,
      baseContactId: baseContact?._id,
      mergeContactIds: duplicateContacts?.filter((d) => d !== baseContact?._id),
      newFormData: newContactMerge ? baseContact : null,
    };
    onMergeContactClick(mergeConfig);
  };

  const Footer = useCallback(() => {
    return (
      <>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            className="px-1 py-0 mx-1"
            variant="danger"
            size="sm"
            onClick={onHide}
            disabled={showProgress}
          >
            {translate("cancel")}
          </Button>
          <Button
            className="px-1 py-0 mx-1"
            variant="success text-white"
            size="sm"
            onClick={onMergeClick}
            disabled={showProgress}
          >
            {translate("merge")}
          </Button>
        </div>
      </>
    );
  }, [show, mergeInfo, baseContact, duplicateContacts, showProgress]);

  useEffect(() => {
    fetchContacts();
  }, [duplicateContacts, baseContact]);

  const isChecked = ({ allowBoth, fieldKey, mergeInfo, mergeKey }) => {
    let val = mergeInfo?.[fieldKey] ?? [];

    return val?.includes(mergeKey);
  };

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={translate(`merge_contact`)}
      showCloseButton={true}
      modalBodyClassName="p-0"
      footerContent={Footer}
    >
      {isFetchingContacts ? (
        <Loader />
      ) : (
        <div className="p-2">
          <h6 className="fw-bold large mb-2">{translate("merge_message")}</h6>

          <div style={{ height: "calc(100vh - 200px)" }}>
            {" "}
            <Table bordered hover className="mid">
              <thead className="sticky-header">
                <tr>
                  <th className="bg-dark text-white">Field</th>

                  {mergingContacts?.map(({ contact, baseContact, title }) => {
                    return (
                      <th key={contact?._id} className="bg-dark">
                        <a
                          className="mb-0 text-white smallFont hover"
                          onClick={() => onViewContactClick(contact?._id)}
                        >
                          {title}
                        </a>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {contactFieldsToMerge?.map(
                  (
                    { groupTitle, groupDescription, fields, hideGroupHeading },
                    groupIndex
                  ) => {
                    return (
                      <Fragment key={groupIndex}>
                        {!hideGroupHeading && (
                          <tr>
                            {" "}
                            <td
                              colSpan={mergingContacts.length + 1}
                              align="left"
                              className="bg-primary-light"
                            >
                              {groupTitle && (
                                <h6 className="large fw-bold mb-1">
                                  {translate(
                                    snakeCase(groupTitle || "") || groupTitle
                                  )}
                                </h6>
                              )}
                              {groupDescription && (
                                <h6 className="smallFont mb-0">
                                  {translate(
                                    snakeCase(groupDescription || "") ||
                                      groupDescription
                                  )}
                                </h6>
                              )}
                            </td>
                          </tr>
                        )}
                        {fields?.map(
                          (
                            {
                              key,
                              label,
                              allowBoth = false,
                              valueAccessor,
                              takeFromExisting,
                              hide = false,
                            },
                            fieldIndex
                          ) => {
                            return !hide ? (
                              <tr key={fieldIndex}>
                                <td className="fw-semibold ">
                                  {translate(snakeCase(label || "") || label)}{" "}
                                </td>
                                {mergingContacts?.map(
                                  (
                                    { contact, key: mergeKey, baseContact },
                                    index
                                  ) => {
                                    let data = valueAccessor
                                      ? valueAccessor(contact, users, referrals)
                                      : contact?.[key] || "-";
                                    return (
                                      <td>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <span>{data}</span>
                                          <FormCheck
                                            checked={isChecked({
                                              allowBoth,
                                              fieldKey: key,
                                              mergeKey,
                                              mergeInfo,
                                            })}
                                            disabled={takeFromExisting}
                                            onChange={(e) =>
                                              handleMerge(
                                                key,
                                                allowBoth,
                                                mergeKey,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                    );
                                  }
                                )}
                              </tr>
                            ) : null;
                          }
                        )}
                      </Fragment>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </AppModal>
  );
};

export default ConfirmMergeContactModal;
