import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import useAppChoices from "../../../hooks/useAppChoices";
import { SALES_ROLE } from "../../../helpers/constants";

const SalespersonDropdown = ({ value, onChange }) => {
  let users = useAppChoices("users");
  return (
    <CustomMultiSelect
      items={users
        ?.filter((user) => user.role === SALES_ROLE)
        .map((option) => ({
          value: option._id,
          label: option.name,
        }))}
      selectedItems={value}
      isMulti
      height="30px"
      onChange={onChange}
      placeholder="Select"
    />
  );
};

export default SalespersonDropdown;
